.main {
    position: relative;
    height: fit-content;
}

.nav {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.26px;
    text-align: left;
    color: #0F0B05;
}

.nav::after {
    content: url(/public/Assets/Icons/downIcon.svg);
    margin-left: -0.25rem;
    height: 100%;
}

.dropdown {
    position: absolute;
    width: max-content;
    margin-top: .5rem;
    background: var(--app-white);
    border-radius: 0.5rem;
    list-style: none;
    box-shadow: 0 2px 12px 0px rgba(0, 0, 0, .15), 0 1px 3px rgba(0, 0, 0, .3);
    right: 0;
    padding: .2rem;
    z-index: 2;
    transition: all .8s ease-in-out;
}

.dropdown li {
    color: var(--app-black);
    font-family: var(--source-sans);
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0.75rem 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
}

.linkTag {
    color: var(--app-black);
    font-family: var(--source-sans);
    font-size: 0.875rem;
    font-weight: 400;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
}

.dropdown li>img {
    width: 1.5rem;
    height: 1.5rem;
}

@media (max-width:768px) and (max-height: 775px){
    .dropdownService{
        height: 30.75vh;
        overflow: auto;
    }
}