.search{
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0.5rem 1.25rem;
  box-sizing: border-box;
}


.searchBar {
  background-color: #25292C;
  display: flex;
  border: 1px solid black;
  width: 674px;
  height: 52px;
  border-radius: 30px;
  align-items: center;

}

.searchBar input,button{
  border: 0;
  background-color: transparent;
}

.searchBar button{
  position: absolute;
  padding: 15px;
  border-radius: 30px;
  background: var(--app-coral-pink);
  color: #ffffff;
}

.searchBar input{
  padding-right: 20px;
  color: #E1E9ED;
  font-size: 12px;
  margin-left: 50px;
}

.searchContainer {
display: flex;
align-items: center;
width: 60%;
margin: 0 auto;
background-color: #1e1e1e;
border-radius: 30px;
overflow: hidden;
}

.searchIcon {
margin-left: 15px;
color: white;
font-size: 1.5rem;
}

.searchInput {
flex: 1;
padding: 0.75rem 1rem;
padding-left: 18px; /* Adjust to make space for the icon */
font-size: 1rem;
color: white;
background-color: transparent;
border: none;
outline: none;
}

.searchButton {
padding: 0.75rem 1.5rem;
background:var(--app-coral-pink);
color: white;
border: none;
border-radius: 30px;
cursor: pointer;
font-size: 1rem;
transition: background-color 0.3s ease;
margin: 12px;
}

.searchButton:hover {
background-color: #218838;
}

/* Responsive adjustments */
@media (max-width: 768px) {
.searchContainer {
  width: 90%;
  flex-direction: row;
  border-radius: 1.8rem;
}

.searchInput {
  width: calc(50%);
  color: #E1E9ED;
  font-family: Fraunces;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.73px;
}

.searchButton {
  width: 30%;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  border-radius: 1.8rem;
  overflow: hidden;
}


}

@media (max-width: 1024px) {
  .searchBar {
      width: 100%;
  }
}


@media (max-width: 576px) {

  .search{
    width: 100%;
    padding:0.75rem 1rem ;
    font-size: 0.75rem;
  }

  .searchContainer input{
    font-size: 10px;
  }

  .searchContainer button{
    font-size: 10px;
  }
  
}