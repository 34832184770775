.formControl {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
}

.label {
  color: #3d3d3d;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-transform: capitalize;
}

.primaryInput {
  width: 100%;
  padding: 0.625rem 0.875rem;
  border-radius: 0.5rem;
  border: 1px solid #d5d5d5;
  background: #fafafa;
  font-size: 0.875rem;
  font-weight: 400;
  height: 2.5rem;
  color: var(--app-black);
}

.primaryInput::placeholder {
  color: #797979;
}
