.profileContainer {
  background: white;
}

.headerDetails {
  padding: 2rem 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--app-primary-dark);
  font-family: var(--inter);
}

.headerDetails h1 {
  margin: 0;
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
}

.headerDetails h2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 60px;
}

.mainSection {
  display: flex;
  gap: 2rem;
}

.sidebar {
  flex: 1;
}

.formSection {
  width: 80%;
}

.formSection h1,
p {
  padding: 0;
  margin: 0;
  text-align: left;
  color: #2e3646;
  font-family: var(--inter);
}

.formSection h1 {
  font-family: Butler;
  font-size: 24px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--app-dark);
}

/* .formSection p {
  font-size: 20px;
  font-weight: 400;
  line-height: 60px;
} */

.userDetails {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1.375rem;
}

.image {
  height: 6.9rem;
  width: 6.9rem;
  background: #5f6d7e;
  border-radius: 50%;
}

.showImage {
  height: 6.9rem;
  width: 6.9rem;
  border: 2px solid #5f6d7e;
  border-radius: 50%;
  padding: 0.5rem;
}

.showImage img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: fill;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.description h3 {
  font-family: Fraunces;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.001em;
  text-align: left;
  color: var(--app-dark);
}

.description p {
  font-family: Fraunces;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.001em;
  text-align: left;
  color: #5d6568;
}

.photoUpload {
  display: none;
}

.photoUploadButton {
  font-family: "Fraunces";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.001em;
  text-align: left;
  color: var(--app-claret);
  cursor: pointer;
  margin-top: 1rem;
}

.signupForm {
  margin-top: 1.5rem;
  width: 100%;
  padding: 2rem 0;
  border-radius: 8px;
}

.signupForm h2 {
  margin-bottom: 1rem;
}

.signupForm p {
  /* margin-bottom: 2rem; */
  color: #666;
}

.formGroup {
  margin-bottom: 1.5rem;
}

.formGroup label {
  display: block;
  margin-bottom: 0.5rem;
}

.formGroup input,
.contactNumber select {
  width: 20rem;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.formGroup1 {
  margin-bottom: 1.5rem;
  display: flex;
  gap: 24px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.inputContainer label {
  font-family: var(--inter);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #5f6d7e;
}

.nameInput {
  width: 24.5rem;
}

.nameInput input {
  width: 100%;
}

.lastName {
  width: 20rem;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contactNumber {
  display: flex;
  gap: 0.75rem;
}

.contactNumber select {
  flex: 1;
  background-color: #f8f8f8;
}

.contactNumber input {
  flex: 2;
}

.editField {
  display: flex;
  border: 1px solid #ddd;
}

.editField input {
  border: none;
}

.editField button {
  color: black;
  font-family: var(--inter);
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
}

.options {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.options input[type="checkbox"] {
  margin-right: 0.5rem;
}

.options label {
  margin-bottom: 0.5rem;
}

.title {
  height: 2.625rem;
  border-bottom: 1px solid var(--app-grey);
  font-family: "Butler";
  font-size: 1.25rem;
  font-weight: 700;
}

.referralTitle {
  font-size: 1.25rem;
  font-weight: 600;
  font-family: "Butler";
  margin-bottom: 0.5rem;
}

.referralContainer {
  height: 20rem;
  margin: 1rem 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.referralContainer > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.375rem;
  position: absolute;
}

.innerContainer {
  height: 80%;
  width: 80%;
  position: absolute;
  background-color: #f7f0da;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  padding: 1.5rem;
  font-family: "Butler";
  justify-content: space-between;
}

.innerTitle {
  font-size: 1.375rem;
  font-weight: 500;
  font-family: "Fraunces";
  margin-bottom: 1rem;
}

.linkContainer {
  display: flex;
  background-color: var(--app-white);
  border-radius: 1.5rem;
  padding: 0.5rem 0.75rem;
  align-items: center;
  width: fit-content;
  gap: 2rem;
}

.link {
  font-size: 0.875rem;
  color: var(--app-primary-dark);
  font-weight: 600;
  max-width: 18rem;
}

.frontImage {
  position: absolute;
  right: -45px;
  top: -10%;
  width: 22rem;
  height: 22rem;
}

.frontImage > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.copied {
  position: absolute;
  left: 50%;
  top: 55%;
  font-weight: 500;
  font-size: 0.875rem;
}

@media screen and (max-width: 1300px) {
  .frontImage {
    right: -45px;
    top: 10%;
    width: 18rem;
    height: 18rem;
  }
}

@media screen and (max-width: 1200px) {
  .frontImage {
    right: -30px;
    top: 25%;
    width: 15rem;
    height: 15rem;
  }
  .link {
    max-width: 12rem;
  }
}

@media screen and (max-width: 1024px) {
  .frontImage {
    right: -30px;
    top: 25%;
    width: 15rem;
    height: 15rem;
  }
  .link {
    max-width: 10rem;
  }
}

/* Media Queries for Tablet (max-width: 768px) */
@media (max-width: 768px) {
  .headerDetails {
    padding: 1rem 2rem;
  }

  .headerDetails h1 {
    font-size: 32px;
    line-height: 48px;
  }

  .headerDetails h2 {
    font-size: 18px;
    line-height: 48px;
  }

  .mainSection {
    flex-direction: column;
    padding: 1rem 2rem;
  }

  .sidebar {
    margin-bottom: 1rem;
  }

  .formSection {
    width: 100%;
    padding: 1rem;
  }

  .formGroup input,
  .contactNumber select,
  .contactNumber input {
    width: 100%;
    /* Ensuring full width for tablet */
  }
}

/* Media Queries for Mobile (max-width: 576px) */
@media (max-width: 576px) {
  .headerDetails {
    padding: 1rem;
  }

  .headerDetails h1 {
    font-size: 28px;
    line-height: 40px;
  }

  .headerDetails h2 {
    font-size: 16px;
    line-height: 40px;
  }

  .mainSection {
    padding: 1rem;
  }

  .formSection {
    padding: 0.75rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .userDetails {
    align-items: center;
    gap: 0.75rem;
  }

  .image {
    height: 5rem;
    width: 5rem;
  }

  .description p {
    text-align: center;
  }

  .formGroup input,
  .contactNumber select,
  .contactNumber input {
    width: 100%;
    /* Ensuring full width for mobile */
  }

  .frontImage {
    position: absolute;
    top: 75%;
    right: 0.5rem;
    width: 6rem;
    height: 6rem;
  }

  .innerContainer {
    width: 95%;
  }

  .signupForm {
    margin-top: 0;
    padding: 1rem 0;
  }

  .copied {
    left: 68%;
    top: 70%;
    font-size: 0.75rem;
  }
}

@media (max-width:320px) {
  .link {
    max-width: 8rem;
  }

  .innerContainer {
    width: 98%;
  }
}
