.container {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 12.5rem;
    margin-bottom: 1rem;
}

.title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
}

.title h3 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 700;
    line-height: 22.19px;
    text-align: left;
    color: #ffffff;
}

.dropdownIcon {
    transition: transform 0.3s ease;
}

.itemContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease;
}

.itemContainer p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;

    color: var(--app-white);
}

.show {
    max-height: 20rem;
}

@media (min-width: 1024px) {
    .dropdownIcon {
        display: none;
    }

    .itemContainer {
        display: flex;
        max-height: none;
        overflow: visible;
    }

    .title {
        cursor: default;
    }

}

@media(max-width:768px) {
    .container {
        width: 100%;
    }
}