.productDetails {
    background: var(--app-white);
    padding: 12px 16px; /* Reduced padding for a more compact look */
    border-radius: 8px; /* Slightly smaller border radius */
}

.productHeaders {
    display: flex;
    justify-content: space-between;
}

.productHeaders h2 {
    font-family: Butler;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: left;    
    color: var(--app-dark);
    margin: 0; /* Remove margin */
}

.productHeaders p {
    font-family: Fraunces;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
    
    color: #666666;
    margin: 0; /* Remove margin */
}

.detailSection {
    background: var(--app-light-grey);
    padding: 1rem; /* Reduced padding for compactness */
}

.detailSection h2 {
    font-family: Butler;
    font-size: 24px; /* Smaller font size */
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: var(--app-dark);
    padding-bottom: 0.75rem; /* Reduced padding-bottom */
    border-bottom: 1px solid #D0D4D7;
    margin-bottom: 1.5rem; /* Reduced margin-bottom */
}

.cartDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cartDetails p {
    color: var(--app-claret);
    font-family: Fraunces;
    font-size: 14px; /* Smaller font size */
    font-weight: 600;
    line-height: 16px;
    margin: 0; /* Remove margin */
}

.buttonContainer {
    display: flex;
    gap: 0.5rem; /* Reduced gap between buttons */
}

.price {
    font-family: Butler;
    font-size: 16px; /* Smaller font size */
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: var(--app-dark);
}

.counterBtn {
    padding: 2px 6px !important;
}

.counterBtn button {
    width: 30px !important;
    height: 30px !important;
}


@media (max-width: 768px) {
    .productDetails {
        padding: 12px; /* Further reduced padding */
    }

    .productHeaders {
        gap: 4px; /* Reduced gap */
    }

    .productHeaders h2 {
        font-size: 16px; /* Adjust font size */
    }

    .productHeaders p {
        font-size: 12px; /* Adjust font size */
    }

    .cartDetails {
        gap: 8px; /* Further reduced gap */
    }

    .buttonContainer {
        gap: 0.5rem; /* Further reduced gap */
    }

    .price {
        font-size: 16px; /* Adjust font size */
        text-align: right;
    }
}

@media (max-width: 580px) {
    .productDetails {
        padding: 8px 5px;
        border-radius: 6px; /* Slightly smaller border radius */
    }

    .productHeaders {
        gap: 2px; /* Minimal gap between title and subtitle */
    }

    .productHeaders h2 {
        font-size: 14px; /* Smaller font size */
        line-height: 18px; /* Adjusted line height */
    }

    .productHeaders p {
        font-size: 10px; /* Smaller font size */
        line-height: 14px; /* Adjusted line height */
    }

    .price {
        font-size: 14px; /* Smaller font size */
        line-height: 20px; /* Adjusted line height */
        text-align: left; /* Keep the price aligned to the left */
    }

    .cartDetails {
        flex-direction: column; /* Stack details vertically */
        align-items: flex-start;
        gap: 6px; /* Reduced gap between elements */
    }

    .cartDetails p {
        font-size: 10px; /* Smaller font size */
        line-height: 14px; /* Adjusted line height */
    }

    .buttonContainer {
        width: 100%; /* Make button container take full width */
        gap: 0.375rem; /* Further reduced gap between buttons */
        justify-content: space-between;
    }

    .buttonContainer button {
        flex: 1; /* Make buttons equally take up space */
    }

    .cartBtn{
        padding:4px 10px ;
    }
}

