.mainContainer{
    border: 1px solid #DFDFDF;
    border-radius: 1.5rem;
    padding: 0.5rem 0.75rem;
    font-family: Fraunces;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    width: fit-content;
}

.active{
    border: 1px solid #000000;
    border-radius: 1.5rem;
}