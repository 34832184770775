.title {
  font-size: 2rem;
  margin: 2rem 0rem;
  font-weight: 700;
  font-family: "Butler";
}

.main {
  padding: 0 5rem;
  margin-bottom: 2rem;
}

.name {
  margin: 0.5rem 0;
}

.container {
  flex: 1;
  padding-left: 1.625rem;
}

.menuIcon {
  display: none;
}

.headerContainer {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .menuIcon img{
    height: 1.5rem;
    width: 1.5rem;
  }

  .headerContainer {
    gap: 1rem;
  }

  .sidebar {
    display: none;
  }

  .sideForSmallScreen {
    display: flex;
    position: fixed;
    z-index: 2;
  }

  .title {
    font-size: 1.375rem;
    margin: 0.5rem 0;
  }

  .menuIcon {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    align-items: center;
  }

  
  .container {
    flex: 1;
    padding-left: 0;
    width: 100%;
  }

}

@media screen and (max-width: 820px) {
  .main {
    padding: 0 1rem;
    margin-bottom: 2rem;
  }
}


@media screen and (max-width: 576px) {
  .main {
    padding: 0 1rem;
    margin-bottom: 2rem;
  }
 
}
