.nav {
  display: flex;
  padding: 23px 32px;
  justify-content: space-around;
  gap: 2rem;
  background: var(--app-dark);
  flex-wrap: wrap;
}

.headerText {
  display: flex;
}

.headerText h2,
.trending h2,
.searchResult h2 {
  font-family: Butler;
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.headerText h2 {
  color: var(--app-white);
  font-size: 24px;
  font-weight: 500;
  border-right: 2px solid #9f9f9f;
  padding-top: 8px;
  padding-right: 24px;
  padding-bottom: 0px;
}

.headerText p {
  font-family: "Fraunces";
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding-top: 14px;
  padding-bottom: 10px;
  padding-left: 20px;
}

.searchComponent {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.sortBtn {
  background: var(--app-white);
  padding: 0 0.75rem;
  border: 1px solid var(--app-grey);
  border-radius: 6.25rem;
  display: flex;
  align-items: center;
  height: 3.5rem;
}

.sortBtn select {
  color: var(--app-claret);
  font-family: Fraunces;
  font-size: 14px;
  font-weight: 400;
  border: none;
  padding: 0.25rem;
  outline: none;
}

.search {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 30rem;
  height: 3.5rem;
}

.searchContainer {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--app-white);
  border: 1px solid var(--app-grey);
  border-radius: 30px;
  overflow: hidden;
}

.searchIcon {
  margin-left: 15px;
  color: white;
  font-size: 1.5rem;
}

.searchInput {
  flex: 1;
  font-size: 1rem;
  color: var(--app-grey);
  font-family: Fraunces;
  background-color: transparent;
  border: none;
  outline: none;
}

.searchButton {
  padding: 0.375rem 1.25rem;
  background: var(--app-coral-pink);
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin: 0.375rem;
}

.cardGrid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 0 1rem;
}

.container {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  justify-content: center;
}

.searchResult {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.paginationControls {
  width: 30%;
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  align-items: center
}

.icon {
  font-size: 1.25rem;
}

/* Responsive adjustments */

@media (max-width: 600px) {
  .searchContainer {
    max-width: 100%;
    flex-direction: row;
    border-radius: 1.8rem;
  }

  .searchInput {
    width: calc(50%);
  }

  .searchButton {
    width: 30%;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    border-radius: 1.8rem;
    overflow: hidden;
  }
}

.keywords {
  display: flex;
  margin-top: 32px;
  justify-content: center;
}

.keywords span {
  padding: 8px 16px;
  margin-right: 10px;
  background-color: #e9ecef; /* Lighter gray background */
  color: #25292c;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 14px;
}

@media screen and (max-width: 1480px) {
  
}

@media (max-width: 1024px) {
  .searchBar {
    width: 100%;
    height: 2rem;
  }
}

@media (max-width: 768px) {
  .keywords {
    flex-wrap: wrap;
    justify-content: center;
  }

  .keywords span {
    margin-bottom: 10px;
  }

  .sortBtn {
    font-size: 0.75rem;
    height: 2rem !important;
  }

  .searchComponent {
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .search {
    width: 100%;
    font-size: 0.75rem;
    height: 2rem !important;
  }

  .sortBtn {
    font-size: 0.75rem;
    height: 2rem !important;
  }

  .searchComponent {
    align-items: center;
    justify-content: center;
  }

  .searchContainer input {
    font-size: 8px;
  }

  .searchContainer button {
    font-size: 8px;
  }

  .keywords span {
    font-size: 12px;
  }
}

