.join{
    margin: 4rem var(--container-margin);

}

.join h2 {
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    margin-bottom: 0.5rem;
}

.join p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.contactUs{
    margin: 2rem var(--container-margin);
}

@media (min-width:768px) {
    .explore {
        width: 11rem;
        margin: 1.5rem auto 0;
    }

    
.join p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}

}

.explore {
    margin-top: 1.25rem;
}