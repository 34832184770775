.container {
  background-color: rgba(145, 25, 41,0.9);
  text-align: center;
  width: inherit;
  height: fit-content;
  padding: 80px;
  position: relative;
  box-sizing: border-box;
}

.topBottomMargin {
  background: url('/public/Assets/Images/RBBorderTopDown.svg') repeat-x;
  height: 3rem;
  width: 100%;
  left: 0%;
  position: absolute;
  background-size: contain;
  top: 0;
}

.bottomMargin {
  background: url('/public/Assets/Images/RBBorderTopDown.svg') repeat-x;
  height: 3rem;
  width: 100%;
  left: 0%;
  position: absolute;
  bottom: 0;
  background-size: contain;
}

.leftRightMargin {
  background: url('/public/Assets/Images/RBborderLeftRight.svg') repeat-y;
  width: 1.5rem;
  height: calc(100% - 6rem); /* Subtract the height of top and bottom margins */
  position: absolute;
  left: 1rem;
  top: 3rem;
  background-size: auto;
}

.rightMargin {
  background: url('/public/Assets/Images/RBborderLeftRight.svg') repeat-y;
  width: 1.5rem;
  height: calc(100% - 6rem);
  position: absolute;
  right: 0;
  top: 3rem;
  background-size: auto;
}

.secondaryContainer {
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
}

.heading {
  font-family: Bricktown;
  margin: 0;
  margin-bottom: 1.25rem;
  padding: 0;
  font-size: 2.25rem;
  font-weight: 900;
  color: #F9F1D6F5;
}

.description {
  max-width: 38.45rem;
  font-size: 1rem;
  font-weight: 400;
  font-family: Fraunces;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color:#F7F0DAE9;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.25rem;
}

.icons {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;
  flex-wrap: wrap;
}

.iconItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconItem img {
  width: 1.5rem;
  height: 1.5rem;
}

.iconItem p {
  font-size: 1rem;
  color: #F53939;
}

.books {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 3rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.book img {
  width: 25%;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.envelopeContainer h2{
  color: #F7F0DAE9;
  font-family: Fraunces;
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;

}

.envelopeIcon{
  color: #F7F0DA;
  width: 14.17px;
  margin: 0.5rem auto;
}

.openLetter {
  background: #F9F1D6;
  border-radius: 0.75rem;
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
}

.openLetter h2 {
  font-family: Butler;
  font-size: 2rem;
  font-weight: 600;
  line-height: 3.45rem;
  text-align: center;
  padding: 20px;
}

.openLetter .bookContainer {
  display: flex;
  align-items: end;
  justify-content: center;
  gap: 1.25rem;
  padding: 1.5rem;
}

.bookContainer .middleBook {
  height: 15.5rem;
  width: 10.5rem;
}

.middleBook img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bookContainer .secondLastbooks {
  height: 11rem;
  width: 7.375rem;
}

.secondLastbooks img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bookContainer .lastbooks {
  height: 8.95rem;
  width: 5.875rem;
}

.lastbooks img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.letterButton {
  width: 12.5rem;
  margin: 1rem auto;
  background: linear-gradient(102.6deg, #A22A4E 40.87%, #FF5286 174.9%) !important;
}

/* Responsive Styles */

@media(max-width:1024px){

  .bookContainer .middleBook{
    width: 11rem;
    height: 8rem;
  }
  .bookContainer .secondLastbooks{
    width: 9rem;
    height: 6.5rem;
  }
  .bookContainer .lastbooks{
    width: 7rem;
    height: 4rem;
  }

}
@media (max-width: 768px) and (min-width:481px) {
  .container {
      width: 100%;
      padding: 20px;
  }

  .secondaryContainer {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
  }

  .exploreButton {
      font-size: 0.875rem;
  }

  .heading{
    font-size: 2rem;
  }

  .iconItem img{
    width: 1rem;
    height: 1rem;
  }

  .iconItem p {
    font-size: 0.75rem;
  }

  .rightMargin,.leftRightMargin {
    width: 0;
  }

  .envelopeContainer h2 {
    font-size: 1.25rem;
  }

  .openLetter h2 {
    font-size: 1.5rem;
  }

  .openLetter .bookContainer {
    gap: 0.25rem;
    padding: 0.25rem;
  }
  
  .bookContainer .middleBook{
    width: 19rem;
    height: 8rem;
    margin-bottom: 0.875rem;
  }

  .bookContainer .secondLastbooks{
    width: 12rem;
    height: 7.5rem;
  }

  .bookContainer .lastbooks{
    width: 11rem;
    height: 7rem;
  }

  
.description {
  font-size: 1rem;
  text-align: center;
}

.books {
  margin-top: 2rem;
}

}

@media (max-width: 480px) {
  .container {
      padding: 20px;
  }

  .books {
    margin-top: 2rem;
  }

  .heading {
      font-size: 2rem;
      line-height: 2.25rem;
  }

  .secondaryContainer {
    padding:1rem 0.5rem 0rem
  }

  .iconItem img {
      width: 1rem;
      height: 1rem;
  }

  .iconItem p {
      font-size: 0.75rem;
  }

  .rightMargin,.leftRightMargin{
    width: 0;
  }

  .envelopeContainer h2{
    font-size: 1.25rem;
  }

  .openLetter h2 {
    font-size: 1.5rem;
    line-height: 1.75rem;
    padding: 0;
  }

  .openLetter .bookContainer {
    gap: 0.25rem;
    padding: 0.25rem;
  }

  .bookContainer .middleBook{
    width: 14rem;
    height: 6rem;
  }
  .bookContainer .secondLastbooks{
    width: 11rem;
    height: 5rem
  }
  .bookContainer .lastbooks{
    width: 9rem;
    height: 4.25rem;
  }

  .description {
    font-size: 1rem;
    text-align: center;
  }

  .bottomMargin {
    height: 2rem;
  }
   
}

@media (max-width: 400px) {
  .container {
      padding: 8px 10px;
  }

  .heading {
      font-size: 2rem;
  }

  .iconItem img {
      width: 0.75rem;
      height: 0.75rem;
  }

  .iconItem p {
      font-size: 0.5rem;
  }

  .exploreButton {
      padding: 0.75rem 1.5rem;
      font-size: 0.875rem;
  }

  .openLetter h2 {
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding: 0;
  }

  .description {
    font-size: 1rem;
    text-align: center;
  }
}

@media (max-width : 360px) {
  .letterButton {
    width: 8rem;
    font-size: 0.75rem;
    margin: 0.5rem auto;
  }
  
}
