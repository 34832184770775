.mainContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    margin: var(--container-margin);
    padding: 2rem;
    border-radius: 0.75rem;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
  
  .textContainer {
    width: 60%;
    padding-right: 2rem;
  }
  
  .textContainer h3 {
    color: #0f0b05;
    font-family: "Butler", serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 1rem;
    position: relative;
  }
  
  .textContainer h3::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: var(--app-claret);
    border-radius: 2px;
    animation: growLine 0.6s ease-in-out forwards;
  }
  
  @keyframes growLine {
    0% {
      width: 0;
    }
    100% {
      width: 50px;
    }
  }
  
  .textContainer p {
    font-family: "Fraunces", sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .imageContainer {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .imageContainer img {
    max-width: 100%;
    border-radius: 0.75rem;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive Styles */
  @media (max-width: 1024px) {
    .mainContainer {
      flex-direction: column;
    }
  
    .textContainer {
      width: 100%;
      padding-right: 0;
      text-align: center;
    }
  
    .imageContainer {
      width: 100%;
      margin-top: 2rem;
    }
  }
  