.stepsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0 44px;
}

.step {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    margin-bottom: 0rem;
    animation: expand 0.6s ease-in-out forwards;
}

.stepContent {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 0.75rem 0;
}

.left {
    flex-direction: row;
}

.right {
    flex-direction: row-reverse;
}

.stepInfo {
    width: 40%;
    padding: 10px;
}

.stepTitle {
    color: #97851E;
    font-family: Fraunces;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    animation: expand 0.6s ease-in-out 0.2s forwards;
}

.stepName {
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 45px;
    text-align: left;
    color: var(--app-dark);
    animation: expand 0.6s ease-in-out 0.4s forwards;
}

.stepDescription {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--app-dark);
    animation: expand 0.6s ease-in-out 0.6s forwards;
}

.stepImage {
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 500px;
    animation: expand 0.6s ease-in-out 0.8s forwards;
}

.stepImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.stepNumberContainer {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.stepNumber {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: var(--app-claret);
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    animation: expand 0.6s ease-in-out 0.2s forwards;
}

.stepNumber img {
    width: 2.5rem;
    height: 2.5rem; 
    object-fit: contain;
}

.dottedLine {
    width: 2px;
    flex-grow: 1;
    border-left: 2px dashed #000;
    margin: 0;
}

.cta {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
}

.cta p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--app-dark);
    animation: expand 0.6s ease-in-out 0.6s forwards;
}

@media (max-width: 1024px) {
    .stepContent {
        flex-direction: column;
    }

    .stepInfo, .stepImage {
        width: 100%;
    }

    .stepNumberContainer {
        position: static;
        margin-bottom: 20px;
        height: auto;
    }

    .step {
        margin-bottom: 2rem;
    }
}

@media (max-width: 768px) {
    .stepContent {
        flex-direction: column !important;
    }

    .stepInfo, .stepImage {
        width: 100%;
    }

    .stepNumberContainer {
        position: static;
        margin-bottom: -20px;
        height: auto;
        margin-left: 0.75rem;
    }

    .stepsContainer{
        padding: 0 20px;
    }
    .step {
        margin-bottom: 1.5rem;
        word-break: break-word;
        hyphens: auto;
    }

    .stepName {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        word-break: break-word;
        hyphens: auto;
    }

    .stepNumber img {
        width: 1.5rem;
        height: 1.5rem; 
        object-fit: contain;
    }

    .stepNumber {
        width: 50px;
        height: 50px;
    }
    
}

@keyframes expand {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
