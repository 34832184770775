.ThesisBindingSteps {
    text-align: center;
    padding: 20px;
    margin: 5rem;
    background: linear-gradient(180deg, rgba(159, 120, 0, 0) -5.09%, rgba(249, 241, 214, 0.288213) 4.75%, rgba(255, 253, 246, 0.3) 15.41%, rgba(254, 251, 241, 0.295622) 43.77%, rgba(254, 251, 241, 0.295492) 43.78%, rgba(251, 246, 232, 0.287324) 63.75%, rgba(247, 240, 218, 0.274648) 81.34%, rgba(239, 226, 189, 0.249296) 99.99%, rgba(159, 120, 0, 0) 100%);
}

.title {
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    margin-bottom: 0.5rem;
    animation: expand 0.6s ease-in-out;
}

.subtitle {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    animation: expand 0.6s ease-in-out 0.2s forwards;
    margin-bottom: 3rem;
}

.contactUs{
    margin: 0 var(--container-margin);
}


@media (max-width:576px) {
    .ThesisBindingSteps{
        margin: 0;
    }
}