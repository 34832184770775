.mainContainer {
    margin:5rem var(--container-margin) 0;
    padding: 0 44px;
    display: flex;
    flex-direction: column;
    gap: 3.125rem;
}

.header {
    width: 100%;
    max-width: 676px;
    margin: 0 auto;
}

.header h2{
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    margin-bottom: 1rem;
}

.header p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--app-dark);
}

.cardContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem; /* Add gap between cards */
}

.videoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .mainContainer {
        padding: 0 32px;
        gap: 2.5rem;
    }

    .header h2{
        font-size: 2rem;
        line-height: 40px;
    }

    .header p {
        font-size: 12px;
    }

    .cardContainer {
        gap: 1rem;
    }
}

@media (max-width: 768px) {
    .mainContainer {
        padding: 0 8px;
        gap: 1.5rem;
        margin: 2.5rem var(--container-margin) 0;
    }

    .header p {
        font-size: 16px;
    }

    .cardContainer {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
}

@media (max-width: 480px) {
    .mainContainer {
        padding: 0 0;
    }

    .header p {
        font-family: Fraunces;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.001em;
        text-align: center;

    }

    .cardContainer {
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
    }
}
