.container {
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.icon {
    font-size: 50px;
    color: var(--app-primary); /* Bright yellow color */
    margin-bottom: 10px;
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin: 10px 0;
}

.message {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
}

.btnContainers {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}

.exploreBtn {
    width: 5rem;
    margin: 0 auto;

}

@media (max-width :480px ) {
    .btnContainers {
        flex-direction: column;
        gap: 0.5rem;
    }

    .exploreBtn {
        width: 15rem;
    }
}

