.container {
  position: relative;
  width: 100%;
  z-index: 100;
  overscroll-behavior: none
}

.toggleButton {
  position: fixed; /* Change to fixed positioning */
  bottom: 0; /* Stick to the bottom of the screen */
  width: 95%;
  z-index: 10;
}


.toggleClosed {
  display: none;
}

.drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
  transition: transform 0.3s ease;
  overflow: hidden;
}

.drawer.open {
  transform: translateY(0);
}

.drawerHeader {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.puller {
  width: 50px;
  height: 8px;
  background-color: #ccc;
  border-radius: 10px;
  margin: 0 auto;
}

.drawerContent {
  height: 100%; /* Adjust height based on content */
  overflow-y: auto;
  padding: 1rem 1.25rem;
}

.hamburgerIcon {
  cursor: pointer;
  font-size: 1.5rem;
  color: #333;
}

.nestedDrawer {
  position: fixed;
  bottom: -80vh;
  left: 0;
  width: 100%;
  height: 80vh;
  background-color: #f8f9fa;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  z-index: 30; /* Make sure it's above the primary drawer */
  overflow: hidden;
}

.drawerTitle {
  display: flex;
  justify-content: space-between; /* This will space the items evenly with the title in the center */
  align-items: center; /* Vertically center the items */
  padding: 0 16px; /* Add some padding for spacing */
  margin-bottom: 1.5rem;
}

.hamburgerIcon {
  font-size: 24px; /* Set a size for the icon */
  cursor: pointer; /* Add a pointer cursor on hover */
}

.titleText {
  display: flex;
  align-items: center; /* Vertically center the text */
  font-size: 18px; /* Adjust the font size */
  font-weight: 500; /* Set the font weight */
  color: #333; /* Set text color */
}

.titleText h2 {
  font-family: Butler;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  letter-spacing: 0.01em;
  text-align: center;
  color: var(--app-dark);
  margin: 0 0.5rem; /* Add margin to space the text between the arrows */
}

.titleText p{
  font-family: Butler;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #858585;
  margin-right: 0.5rem;
}

.tooltip {
  font-size: 18px; /* Adjust the size for the tooltip */
  cursor: pointer; /* Add a pointer cursor for the tooltip */
}

.nestedDrawerOpen {
  transform: translateY(-100%);
}

.nestedDrawerPuller {
  width: 50px;
  height: 8px;
  background-color: #ccc;
  border-radius: 10px;
  margin: 10px auto;
  cursor: pointer;
}

.nestedDrawerContent {
  padding: 1rem;
  height: calc(100% - 30px); /* Adjust based on puller size */
  overflow-y: auto;
}

.drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 16rem;
  background-color: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
  transition: transform 0.3s ease;
  overflow: hidden;
}

.drawer.open {
  transform: translateY(0);
}

.inVisible {
  position: fixed;
  top: 0;
  opacity: 0;
  pointer-events: none;
}