.cardContainer {
    display: flex;
    gap: 1.25rem;
    padding: 1.7rem 1rem;
    background: var(--app-light-pink);
    width: 100%;
    max-width: 19.75rem;
    border-radius: 0.75rem;
    align-items: center; /* Ensure vertical alignment */
  }
  
  .key {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem; /* Ensure height and width are equal */
    border-radius: 50%;
    background: var(--app-claret);
  }
  
  .key p {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

  }
  
  .data {
    flex: 1;
    font-size: 1rem;
  }

  .data p{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

  }
  
  @media (max-width: 1024px) {
    .cardContainer {
      padding: 1.5rem 0.75rem;
      max-width: 16rem;
      gap: 1rem;
    }
  
    .key {
      width: 2rem;
      height: 2rem; /* Ensure height and width are equal */
    }
  
    .data {
      font-size: 0.875rem;
    }
  }
  
  @media (max-width: 768px) {
    .cardContainer {
      align-items: center;
      padding: 1rem 0.5rem;
    }
  
    .key {
      margin-bottom: 0.5rem;
    }
  
    .data {
      font-size: 0.75rem;
    }
  }
  
  @media (max-width: 480px) {
    .cardContainer {
      padding: 0.75rem 0.5rem;
      max-width: 100%;
    }
  
    .key {
      width: 1.5rem;
      height: 1.5rem; /* Ensure height and width are equal */
    }
  
    .data {
      font-size: 0.625rem;
    }
  }
  