.mainContainer {
  width: 90%;
  box-sizing: border-box;
}

.headerSection {
  display: flex;
  gap: 0.625rem;
  align-items: center;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #d0d4d7;
}

.headerSection h2 {
  color: var(--app-dark);
  font-family: Butler;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 250% */
  letter-spacing: -0.24px;
  margin: 0;
}

.backBtn {
  font-size: 1.25rem;
  cursor: pointer;
}

.orders {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--app-primary-light);
  padding: 1.5rem;
  border-radius: 4px;
  margin-top: 1.5rem;
  border: 1px solid #e1cb81;
}

.orders h2 {
  font-family: Butler;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: var(--app-dark);
}

.orderSummary {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.orderSummary p {
  font-family: Fraunces;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #5d6568;
}

.orderSummary h2 {
  font-family: Fraunces;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: var(--app-dark);
}

.addressDetails {
  display: flex;
  gap: 1.25rem;
  align-items: center;
}

.addressDetails p {
  font-family: Fraunces;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #5d6568;
}

.timeline {
  margin: 1.25rem 0;
}

.tabs {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.tab,
.activeTab {
  font-family: Fraunces;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #25292c;
  padding: 0.625rem 1rem;
  border: 1px solid #d0d4d7;
  border-radius: 12px;
  cursor: pointer;
}

.activeTab {
  background: #6d8a91;
  color: var(--app-white);
}

.cardSection {
  margin: 0 1rem;
  margin-top: 1.5rem;
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 1rem;
}

.card {
  padding: 1.5rem 1.25rem;
  border: 1px solid #9f9f9f;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1.25rem;
  border-radius: 12px;
}

.card h2 {
  font-family: Butler;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--app-dark);
}

.card h3 {
  font-family: Fraunces;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.73px;
  text-align: left;
  color: var(--app-dark);
}

.card p {
  font-family: Fraunces;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.73px;
  text-align: left;
  color: #807d7e;
}

@media screen and (max-width: 820px) {
    .mainContainer {
      width: 100%;
    }
  
    .orders {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 1rem;
    }
  
    .addressDetails {
      flex-wrap: wrap;
      column-gap: 1.5rem;
      row-gap: 0.5rem;
    }
}

@media screen and (max-width: 820px) {
  .mainContainer {
    width: 100%;
  }

  .orders {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1rem;
  }

  .addressDetails {
    flex-wrap: wrap;
    column-gap: 1.5rem;
    row-gap: 0.5rem;
  }

  .card h2 {
    font-size: 1rem;
  }

  .card h3 {
    font-size: 0.875rem;
  }

  .card p {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 600px) {
  .mainContainer {
    width: 100%;
  }

  .orders {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1rem;
  }

  .addressDetails {
    flex-wrap: wrap;
    column-gap: 1.5rem;
    row-gap: 0.5rem;
  }
  .cardSection{
    margin: 0;
    grid-template-columns: 1fr;
  }

  .card h2 {
    font-size: 1rem;
  }

  .card h3 {
    font-size: 0.875rem;
  }

  .card p {
    font-size: 0.875rem;
  }
}
