/* Base styles remain the same */

.mainContainer {
    background: linear-gradient(180deg, rgba(159, 120, 0, 0) -5.09%, rgba(249, 241, 214, 0.288213) 4.75%, rgba(255, 253, 246, 0.3) 15.41%, rgba(254, 251, 241, 0.295622) 43.77%, rgba(254, 251, 241, 0.295492) 43.78%, rgba(251, 246, 232, 0.287324) 63.75%, rgba(247, 240, 218, 0.274648) 81.34%, rgba(239, 226, 189, 0.249296) 99.99%, rgba(159, 120, 0, 0) 100%);
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 44px;
    display: flex;
    flex-direction: column;
    gap: 3.125rem;
    padding: 60px 120px;
}

.header {
    width: 100%;
    max-width: 676px;
    margin: 0 auto;
}

.header h2 {
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    margin-bottom: 0.5rem;
}

.header p {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}

.stepsSection {
    display: flex;
    flex-direction: column;
    gap: 4.625rem;
}

.stepsCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5.875rem;
}

.stepImage{
    height: 19rem;
    width: 32rem;
    object-fit: contain;
}

.stepImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.stepsCard:nth-child(2),
.stepsCard:nth-child(4) {
    flex-direction: row-reverse;
}

.stepDetails {
    width: 100%;
    max-width: 600px;
}

.stepDetails h3 {
    color: #97851E;
    font-family: Fraunces;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
}

.stepDetails h2 {
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 45px;
    text-align: left;
    color: var(--app-dark);
}

.stepDetails p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--app-dark);
}

/* Responsive styles */

@media (max-width: 1200px) {
    .mainContainer {
        padding: 100px 80px;
    }

    .stepsCard {
        gap: 4rem;
    }
}

@media (max-width: 992px) {
    .mainContainer {
        padding: 80px 60px;
    }

    .stepsCard {
        gap: 3rem;
    }

}

@media (max-width: 768px) {
    .mainContainer {
        padding: 60px 40px;
    }

    .header h2 {
        font-size: 28px;
        line-height: 36px;
    }

    .header p {
        font-size: 12px;
        line-height: 16px;
    }

    .stepsSection {
        gap: 2rem;
    }
}

@media (max-width: 576px) {
    .mainContainer {
        padding: 40px 20px;
        margin-top: 1rem;
    }

    .header h2 {
        font-size: 24px;
        line-height: 32px;
    }

    .header p {
        font-size: 16px;
        line-height: 20px;
    }

    .stepDetails h2{
        font-size: 22px;
    }
    .stepsCard{
        flex-direction: column;
    }
    .stepsCard:nth-child(1n){
        flex-direction: column;
    }
}
