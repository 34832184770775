.card {
    background-color: #25292C;
    color: white;
    border-radius: 0.75rem;
    text-align: center;
    width: inherit;
    height: inherit;
    margin: auto;
}

.card img {
    width: 100%;
    height:100%;
    object-fit: contain;
    border-radius: 0.5rem;
    margin-bottom: 0.75rem;
}

.cardtitle {
    font-size: 1.25rem;
    font-family: fraunces;
    margin-bottom: 0.5rem;
}

.pricesection {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    color: black;
}

.couponCode {
    font-weight: bold;
    font-size: 1rem;
    font-family: fraunces;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}

.copiedMessage {
    position: absolute;
    top: -1.5rem; /* Position above the price section */
    background-color: #4caf50; /* Soft green to indicate success */
    color: white;
    font-size: 0.75rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    font-weight: bold;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    animation: fadeInOut 2s ease-in-out forwards, scaleUp 0.3s ease;
    white-space: nowrap;
    z-index: 10;
}

.offerTitle{
    height: 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.offerTitle h2 {
    font-family: Franunces;
}

.offerImage{
    height: 65%;
    display: flex;
    justify-content: center;
}

.bottomSection{
    height: 25%;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    10% {
        opacity: 1;
        transform: translateY(0);
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(-10px);
    }
}

@keyframes scaleUp {
    0% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}

.pricesection {
    position: relative; /* Ensures .copiedMessage positions correctly relative to .pricesection */
}

@media (max-width:1024px) {
    .card {
        background: none;
    }

    .cardtitle,.coupontitle {
        color: #25292C;
        font-size: 2rem;
        font-family: Butler;
        font-weight: 700;
        margin: 0;
    }

    .coupontitle {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }

    .pricesection {
        background-color: #25292C;
        color: #ffffff;
    }

    .offerTitle h2 {
        margin-bottom: 1rem;
    }
}

@media (max-width: 768px) {

    .offerTitle h2 {
        font-size: 1.5rem;
        font-family: Franunces;
        margin-bottom: 0.75rem;
    }

    .card {
        padding: 0.75rem;
    }

    .cardtitle {
        font-size: 1rem;
    }

    .coupontitle {
        font-size: 1rem;
    }

    .pricesection {
        padding: 0.25rem 0.75rem;
    }

    .couponCode {
        font-size: 0.875rem;
    }
}

@media (max-width:576px) {
    .pricesection {
        width: 90%;
        margin: 0 auto;
    }
}

@media (max-width: 480px) {
    .card {
        padding: 0.5rem;
    }

    .card img {
        width: 90%;
        height:90%;
    }

    .pricesection {
        padding: 0.25rem 0.5rem;
    }

    .coupontitle {
        margin-bottom: 0.35rem;
    }
}