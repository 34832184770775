.mainContainer{
    margin-top: 1rem;
}

.uploadBtn{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: var(--app-light-pink);
    padding: 10px 16px;
    border-radius: 12px;
}

.uploadBtn h2{
    font-family: Butler;
    font-size: 18px;
    font-weight: 500;
    line-height: 16.8px;
    text-align: left;

}

.disclaimer{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 10px 16px;
    border-radius: 12px;
    margin-top: 1rem;
}

.infoIcon{
    color: var(--app-claret);
}