.ctaContainer {
    background: #F7F0DA;;
    color: #25292C;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    padding: 1.5rem;
    border-radius: 0.75rem;
    margin:0 var(--container-margin) 0;
}

.textContent {
    flex: 1;
}

.textContent h2 {
    font-family: Butler;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;    
    margin: 0;
    text-align: center;
}

.textContent p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;

    margin-top: 0.5rem;
    color: #25292C;

}

.ctaButton {
    margin-left: 0;
    width: 100%;
}




@media (max-width: 768px) {
    .ctaContainer {
        flex-direction: column;
        text-align: center;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    
    .ctaButton {
        margin: 1rem 0 0 0;
    }

    .textContent h2 {
        font-size: 24px;
        line-height: 32px;
    }

    .textContent p {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
    
    }
    
}
