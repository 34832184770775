.mainContainer {
    margin:2.75rem 2.5rem 3rem;
    display: flex;
    justify-content: space-between;
    gap: 2.375rem;
    overflow: auto;
}

.imageSection {
    padding: 24px;
    border-radius: 12px;
    background: #25292C;
}

.mainImage {
    margin: 0.5rem 100px;
    width: 70%;
    height: 85%;
}

.mainImage img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.productDetails {
    background: var(--app-white);
    width: 100%;
    border-radius: 12px;
    max-width: 38rem;
}

.productHeaders {
    display: flex;
    justify-content: space-between;
}

.productHeaders h2 {
    font-family: Butler;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: var(--app-dark);
}

.productHeaders p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #666666;
}

.detailSection {
    background: var(--app-light-grey);
    padding: 1.5rem;
}

.detailSection h2 {
    font-family: Butler;
    font-size: 30px;
    font-weight: 700;
    line-height: 34px;
    text-align: left;
    color: var(--app-dark);
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #D0D4D7;
    margin-bottom: 2.25rem;
}

.leatherType {
    margin-bottom: 3rem;
}

.leatherType h3,
.variantType h3 {
    font-family: Butler;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: var(--app-dark);
    margin-bottom: 4px;
}

.detail {
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.26px;
    text-align: left;
    color: var(--app-claret);
    margin-bottom: 1.375rem;
}

.moreIcon {
    margin-left: 12px;
}

.leatherOptions,
.variantOptions {
    display: flex;
    width: 100%;
    gap: 1.5rem;
}

.variantType {
    margin-bottom: 7.125rem;
}

.cartDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cartDetails p {
    color: var(--app-claret);
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.26px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    gap: 1.375rem;
}

.price {
    font-family: Butler;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    color: var(--app-dark);
}

.editorSection {
    flex:1;
    background: #565656;
    padding: 1rem;
    border-radius: 12px;
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    opacity: 0;
    pointer-events: none;
}

/* Tablet Styles */
@media (max-width: 1024px) {
    .mainContainer {
        flex-direction: column-reverse;
        
        gap: 1.5rem;
    }

    .detailSection {
        padding: 1.25rem;
    }

    .detailSection h2 {
        font-size: 28px;
        margin-bottom: 1.5rem;
    }

    .leatherType h3, .variantType h3 {
        font-size: 20px;
    }

    .leatherOptions, .variantOptions {
        flex-direction: row;
        gap: 1.25rem;
    }

    .imageSection {
        padding: 20px;
    }

    .mainImage {
        margin: 32px auto;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .productDetails {
        padding: 18px;
    }

    .productHeaders {
        flex-direction: row;
        justify-content: space-between;
        gap: 8px;
    }

    .productHeaders h2 {
        font-size: 20px;
    }

    .productHeaders p {
        font-size: 16px;
    }

    .cartDetails {
        flex-direction: row;
        gap: 16px;
    }

    .buttonContainer {
        flex-direction: row;
        gap: 1.25rem;
    }

    .price {
        font-size: 20px;
        text-align: right;
    }

    .variantType {
        margin-bottom: 3.125rem;
    }
}


@media (max-width: 768px) {
    .mainContainer {
        flex-direction: column;
        gap: 1rem;
        margin: 0;
    }

    .detailSection {
        padding: 1rem;
    }

    .detailSection h2 {
        font-size: 24px;
        margin-bottom: 1rem;
    }

    .leatherType h3, .variantType h3 {
        font-size: 18px;
    }

    .leatherOptions, .variantOptions {
        gap: 1rem;
    }

    .imageSection {
        padding: 16px;
    }

    .mainImage {
        margin: 24px auto;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .productDetails {
        padding: 16px;
    }

    .productHeaders {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    .productHeaders h2 {
        font-size: 18px;
    }

    .productHeaders p {
        font-size: 14px;
    }

    .cartDetails {
        gap: 16px;
    }

    .buttonContainer {
        flex-direction: column;
        gap: 1rem;
    }

    .price {
        font-size: 18px;
        text-align: right;
    }
    .variantType {
        margin-bottom: 2.125rem;
    }
}


@media (max-width: 480px){
    .cartDetails{
        flex-direction: column;
    }

    .mainImage{
        width: 80%;
        height: auto;
        justify-content: end;
    }

    .mainImage img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}




