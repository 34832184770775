.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f8f8f8;
  }
  
  .loginForm {
    width: 100%;
    max-width: 512px;
    padding: 2rem;
    border-radius: 8px;
    margin-right: 104px;
  }
  
  .loginForm h2 {
    margin-bottom: 1rem;
  }
  
  .loginForm p {
    margin-bottom: 2rem;
    color: #666;
  }
  
  .formGroup {
    margin-bottom: 1.5rem;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .formGroup input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .options input[type="checkbox"] {
    margin-right: 0.5rem;
  }
  
  .options a {
    color: #1C1B1F;

    text-decoration: none;
  }
  
  .options a:hover {
    text-decoration: underline;
  }


  .password{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

}

.password span{
    transform: translateX(-28px);
}

.password span img{
    width: 18px;
    height: 18px;
}

.forgotPassword{
  color: var(--app-primary);
}

.forgotPassword:hover{
  cursor: pointer;
}
  
  .loginButton {
    width: 100%;
    padding: 0.75rem;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 1rem;
  }
  
  .loginButton:hover {
    background-color: #333;
  }
  
  .signUpText {
    text-align: center;
    margin: 1rem auto;
  }
  
  .signUpText a {
    color: #1C1B1F;

    text-decoration: none;
  }
  
  .signUpText a:hover {
    text-decoration: underline;
  }
  
  .orLoginWith {
    text-align: center;
    margin-bottom: 1rem;
    color: #666;
  }
  
  .socialButtons {
    display: flex;
    justify-content: space-between;
  }
  
  .socialButton {
    flex: 1;
    background-color: #f1f1f1;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 0.25rem;
  }
  
  .socialButton:hover {
    background-color: #e1e1e1;
  }
  
  .carouselContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 2rem;
  }
  
  .carousel {
    position: relative;
    width: 500px;
    height: 600px;
    background-color: #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .slide {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s ease-in-out;
    position: relative;
  }
  
  .slide.active {
    display: flex;
  }

  .slide p{
    font-family: Butler;
    font-size: 28px;
    font-weight: 500;
    line-height: 43.2px;
    text-align: left;
    color: wheat;
    position: absolute;
    bottom: 10%;
    left: 5%;
    width: 70%;
   
  }

  .slideTextTop{
    top: 10%;
    right: 5%;
    width: 70%;
    text-align: right;
  }
  
  .dots {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .activeDot {
    background-color: #333;
  }
  
  @media (max-width: 1200px) {
    .loginContainer {
        flex-direction: column;
        align-items: center;
    }

    .loginForm {
        margin-right: 0;
        margin-bottom: 2rem;
    }

    .carouselContainer {
        margin-left: 0;
    }

    .carousel {
        width: 400px;
        height: 500px;
    }
}

@media (max-width: 992px) {
    .carousel {
        width: 300px;
        height: 400px;
    }

    .carouselContainer {
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .loginContainer {
        flex-direction: column;
        align-items: center;
    }

    .loginForm {
        width: 100%;
        max-width: 400px;
        padding: 1.5rem;
        margin-right: 0;
    }

    .carousel {
        width: 50%;
    }

    .carouselContainer {
        margin-left: 0;
        width: 100%;
        padding: 0 1rem;
    }
}

@media (max-width: 576px) {
    .loginForm {
        width: 100%;
        max-width: 100%;
        padding: 1rem;
        margin-right: 0;
    }

    .options{
      flex-direction: column;
      gap: 1rem;
    }

    .carousel {
        width: 100%;
    }

    .carouselContainer {
        width: 90%;
        margin-left: 0;
        padding: 0;
    }
}