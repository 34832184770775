.container {
    position: fixed;
    /* background-color: rgb(0, 0, 0, 0.5); */
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 3.125rem;
    aspect-ratio: 1;
    border-radius: 50%;
    background:
        radial-gradient(farthest-side, var(--app-primary) 94%, #0000) top/8px 8px no-repeat,
        conic-gradient(#0000 30%, var(--app-primary));
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
    animation: loading 1s infinite linear;
}

@keyframes loading {
    to {
        transform: rotate(1turn)
    }
}