.mainContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--app-white);
    padding: 8px 24px;
    border-radius: 10px;
    margin-bottom: 0.75rem;
}

.mainContainer h2{
    font-family: Fraunces;
    font-size: 15px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #1F2024;
}

.priceSection{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.priceSection p{
    font-family: Butler;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    text-align: left;
    color: var(--app-dark);
}

.icon{
    color: var(--app-claret);
}