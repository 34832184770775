.container {
    width: 80vw;
    height: 35vh;
}

.container h2 {
    font-family: Butler;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--app-dark);
}

.container p {
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--app-grey);
}

.image {
    width: 13rem;
    height: 8rem;
    margin: 1.5rem auto 0;
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}