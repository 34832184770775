.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 120;
  background: rgba(0, 0, 0, 0.5);
  border-radius: inherit;
}

.modal {
  padding: 2.5rem;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 5px;
  max-height: 95dvh;
  max-width: 95vw;
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--app-primary-dark);
  font-family: var(--inter);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.375rem;
  letter-spacing: -0.015rem;
}

.cancelIcon {
  display: flex;
  justify-content: flex-end;
}

.body {
  margin: 1.25rem 0px;
  flex: 1;
  overflow-y: auto;
}

.footer {
  display: flex;
  align-items: center;
}

.close {
  cursor: pointer;
}

@media (max-width: 560px) {
  .modal {
    min-width: 85vw;
    padding: 1rem;
  }

  .header {
    font-size: 1.125rem;
  }
}