  .modal {
    background-color:rgba(0, 0, 0, 0.8) !important;
    width: 100%;
    height: 100%;
  }

  .modalContainer {
    background: transparent;
    border-radius: 8px;
    padding: 1.5rem;
    padding-left: 1rem;
    padding-right: 0;
    width: 90%;
    max-width: 400px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
  
  .modalTitle {
    font-family: Butler;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
  }
  
  .modalSubtitle {
    font-family: Butler;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    color: #fff;
  }
  
  .modalLabel {
    font-family: Fraunces;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.8px;
    text-align: left;
    color: #fff;
  }
  
  .modalInput {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #fff;
    border-radius: 4px;
    margin-bottom: 24px;
    box-sizing: border-box;
    color: #fff;
    background: #000;
    margin-top: 0.5rem;
  }

  .modalInput:focus {
    outline: none;
  }
  
  .modalActions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .modalCancel,
  .modalSubmit {
    padding: 10px 20px;
    font-family: Fraunces;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.001em;
    text-align: left;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .modalCancel {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
  }
  
  .modalSubmit {
    background: #fff;
    color: #000;
  }
  
  .modalCancel:hover {
    background: #ddd;
  }
  
  .modalSubmit:hover {
    background: #333;
  }
  