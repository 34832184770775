.sidebarWrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
}

.hamburger {
    display: none;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1100;

     /* Add these new styles */
     -webkit-appearance: none; /* Removes iOS default styling */
     appearance: none;
     color: inherit; /* Uses the parent's text color instead of iOS blue */
     padding: 0; /* Removes default padding that might affect positioning */
     margin: 0; /* Removes default margins */
     transform: translateZ(0); /* Forces hardware acceleration, can help with positioning */
     -webkit-transform: translateZ(0);
}

.sidebarContainer {
    border: 1px solid var(--app-grey);
    border-top: none;
    width: 15.5rem;
    height: fit-content;
    background-color: white;
    transition: transform 0.3s ease-in-out;
}

.sidebarContainer h2 {
    font-family: Fraunces;
    font-weight: 400;
    padding: 2rem;
}
@media (max-width: 1200px) {
    .sidebarWrapper {
        z-index: 5;
    }

    .hamburger {
        display: block;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 11;
        transition: left 0.3s ease-in-out; /* Add smooth transition for the movement */
    }

    .open {
        left: 12.65rem;
    }

    /* Safari-specific open state */
    @supports (-webkit-touch-callout: none) {
        .hamburger.open {
            left: 12.25rem;
        }
    }

    .sidebarContainer {
        position: absolute;
        left: 0;
        top: 0;
        width: 15.5rem;
        background: white;
        z-index: 10;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        overflow-y: auto;
        max-height: 100vh;
    }

    .sidebarContainer.open {
        transform: translateX(0);
    }

    
}


@media (max-width: 480px){
    .sidebarContainer h2{
        padding: 1.5rem 2rem;
    }
}