.cardContainer , .active{
    border: 1px solid #DFDFDF;
    padding: 2.125rem 2.875rem;
    background: var(--app-white);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    border-radius: 12px;
    width: 100%;
    position: relative;
}
.active{
    border: 1px solid var(--app-claret);
}

.cardContainer p , .active p{
    color: #25292C;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
    text-align: center;
    /* margin: 0;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;  */

}

.selectedIcon{
    position: absolute;
    top: 5%;
    right: 5%;
    color: var(--app-primary);
    font-size: 18px;
}

.imageSection{
    display: flex;
    justify-content: center;
}

.imageContainer,.thumbnailContainer{
    height: 3.375rem;
    width:3.375rem;
    border-radius: 50%;
    background: var(--app-light-pink);
}

.thumbnailContainer {
    height: 6rem;
    width: 6rem;
}

.imageContainer img,.thumbnailContainer img{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: contain;
}

.disbaled {
    cursor: not-allowed;
    opacity: 0.85;
}

.deleteIcon {
    position: absolute;
    top: 6%;
    left: 6%;
    cursor: pointer;
}

@media(max-width:480px){
    .active,.cardContainer{
        padding: 1rem;
    }
}
