.reviewComponent {
    width: 65vw;
    border: 1px solid #ccc;
    padding: 36px 32px 36px 36px;
    border-radius: 12px;
    background:var(--app-white);
    font-family: Butler;
}
.reviewComponent h2{
    margin-bottom: 24px;
    padding-bottom: 2rem;
    color: var(--app-black);
    font-size: 36px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -0.01em;
    border-bottom: 1px solid #D0D4D7;
}

.bookDetails {
    display: flex;
    gap: 48px;
    margin-bottom: 46px;
}

.bookName{
    display: flex;
    flex-direction: column;
}
.bookName p{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #666666;    
    margin-bottom: 1rem;
}

.bookName h3{
    font-family: Butler;
    font-size: 28px;
    font-weight: 700;
    line-height: 33.6px;
    text-align: left;    
}

.bookCover {
    width: 235px;
    height: 280px;
    background-color: #e0e0e0;
}

.bookCover img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.reviewText {
    margin: 0;
}

.reviewText p{
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 600;
    line-height: 22.19px;
    text-align: left;
    
}

.reviewText textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: none;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.73px;
    text-align: left;
    color:#64615D;
    margin-bottom: 2rem;
}

.ratingSection{
    margin-bottom: 2rem;
}

.star{
    font-size: 32px;
}

.ratingSection h3,.photoUpload h3{
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 600;
    line-height: 22.19px;
    text-align: left;
}

.photoUpload{
    display: flex;
    flex-direction: column;
}

.photoUpload h3{
    margin-bottom: 0.5rem;
}


.actions {
    display: flex;
    justify-content: flex-start;
    gap: 13px;
}

.btnClass{
    height: 46px;
    width: 180px;
    border-radius: 8px;
}

.fileInputLabel {
    border: 1px solid var(--app-claret);
    color: var(--app-claret);
    padding: 16px 0;
    border-radius: 15px;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.73px;
    text-align: center;
}


.fileInput {
    display: none; /* Hide the actual file input */
}

@media (max-width: 768px) {
    .reviewComponent {
        padding: 20px; /* Reduce padding on small screens */
    }

    .bookDetails {
        flex-direction: column; /* Stack book details vertically */
        align-items: center; /* Center items */
        gap: 1rem; /* Reduce gap between items */
        margin-bottom: 0;
    }

    .bookCover {
        width: 200px;
        height: 240px;
    }

    .bookName h3 {
        font-size: 24px; /* Reduce font size for headings */
    }

    .bookName p,
    .ratingSection h3,
    .photoUpload h3,
    .reviewText p {
        font-size: 14px; /* Reduce font size for text */
    }

    .star {
        font-size: 24px; /* Reduce star size */
    }

    .btnClass {
        height: 40px;
        width: 150px; /* Reduce button size */
    }

    .fileInputLabel {
        padding: 10px 0; /* Reduce padding */
        font-size: 14px; /* Reduce font size */
    }
}