.customerCardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    padding: 1.875rem 2.25rem;
    background-color: #fff;
    width: 100%;
    max-width: 320px;
    margin: 1rem;
    height: 24rem;
    justify-content: space-between;
}

.authorImage {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
}

.roundImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cardDescription {
    width: 100%;
    min-height: 120px;
    padding-bottom: 2rem;
    margin-top: 1.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text p {
    color: #110D06;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    margin: 0 0 0.5rem;
    text-overflow: ellipsis;
}

.author{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #D0D4D7;
    padding-top: 0.5rem;
}

.author p {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    color: #333;
    text-overflow: ellipsis;
}

@media (max-width: 768px) {
    .customerCardContainer {
        max-width: 100%;
        margin: 0;
    }
    
    .authorImage {
        width: 80px;
        height: 80px;
        border: 1px solid black;
    }
    .authorImage img{
        width: 100%;
        height: 100%;
    }
    
    .cardDescription {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}