.mainContainer{
    background: var(--app-light-grey);
    padding:2rem;
    border-radius: 12px;
}

.mainContainerInactive{
    background: var(--app-white);
    border: 1px solid var(--app-light-grey);
    border-radius: 12px;
    padding: 2rem;
}

.headerSection{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--app-dark);
}

.headerSectionInActive{
    border: none;
    display: flex;
    justify-content: space-between;
}

.headerSectionInActive button{
    color: var(--app-primary);
    background:transparent;
    border: none;
    box-shadow: none;
}

.headerSection h2,.headerSectionInActive h2{
    font-family: Fraunces;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--app-dark);
}

.headerSection p,.headerSectionInActive p{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--app-grey);
}

.btnContainer{
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
}

.btnContainer button{
    width: 11.25rem;
}

.mainContainerInactive {
    background: var(--app-white);
    border: 1px solid var(--app-light-grey);
    border-radius: 12px;
    padding: 2rem;
}

.headerSectionInActive {
    border: none;
    display: flex;
    flex-direction: column;
}

.btnContainerInactive {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.selectedAddresses {
    margin-top: 1.5rem;
}

@media screen and (max-width: 586px) {
    .mainContainer{
        padding: 0.875rem;
    }
}