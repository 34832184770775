.mainContainer {
    margin: 0.5rem 2.5rem 0;
    display: flex;
    justify-content: space-between;
    gap: 2.375rem;
    overflow: auto;
}

.imageSection {
    padding: 24px;
    border-radius: 12px;
    background: #25292C;
    max-width: 38rem;
}

.mainImage {
    margin: 20px 134px;
}

.mainImgAlt {
    margin: 60px 134px;
}

.productDetails {
    background: var(--app-white);
    width: 100%;
    border-radius: 12px;
}

.detailSection {
    background: var(--app-light-grey);
    padding: 1.5rem;
    height: fit-content;
}

.info {
    background: #FFF1EF;
    margin: 1rem;
    padding: 16px;
    font-family: Fraunces;
    font-size: 1rem;
}

.detailSection h2 {
    font-family: Butler;
    font-size: 30px;
    font-weight: 700;
    line-height: 34px;
    text-align: left;
    color: var(--app-dark);
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #D0D4D7;
    margin-bottom: 2.25rem;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    padding-bottom: 2rem;
}

.leatherType {
    margin-bottom: 3rem;
}

.leatherType h3 {
    font-family: Butler;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: var(--app-dark);
    margin-bottom: 2.25rem;
}

.leatherOptions {
    display: flex;
    width: 100%;
    gap: 1.5rem;
}

.sampleOptions {
    display: flex;
    /* grid-template-columns: repeat(2, 1fr); */
    padding: 0 0.375rem;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
}

.savedDesigns {
    flex: 1 1 45%; 
    height: fit-content;
    box-sizing: border-box; 
}

.buttonContainer {
    display: flex;
    gap: 1.375rem;
}

.price {
    font-family: Butler;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    color: var(--app-dark);
}

.bookImg {
    width: 16rem;
    height: 21rem;
}

/* Tablet Styles */
@media (max-width: 1024px) {
    .mainContainer {
        flex-direction: column-reverse;
        gap: 1.5rem;
        margin: 0;
    }

    .detailSection {
        padding: 1.25rem;
    }

    .detailSection h2 {
        font-size: 28px;
        margin-bottom: 1.5rem;
    }

    .leatherType h3 {
        font-size: 20px;
    }

    .leatherOptions {
        flex-direction: row;
        gap: 1.25rem;
    }

    .imageSection {
        padding: 20px;
    }

    .mainImage {
        margin: 32px auto;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .productDetails {
        padding: 18px;
    }
}

/* Mobile Styles */
@media (max-width: 768px) {
    .mainContainer {
        flex-direction: column;
        gap: 1rem;
    }

    .detailSection {
        padding: 1rem;
        width: 100%;
    }

    .detailSection h2 {
        font-size: 24px;
        margin-bottom: 1rem;
    }

    .leatherType h3 {
        font-size: 18px;
    }

    .leatherOptions {
        gap: 1rem;
    }

    .imageSection {
        padding: 16px;
    }

    .mainImage {
        margin: 24px auto;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .productDetails {
        padding: 16px;
    }
}

/* Small Mobile Styles */
@media only screen and (max-width: 480px) {
    .mainContainer {
        margin: 0;
        flex-direction: column;
        gap: 1rem;
    }

    .detailSection {
        height: auto; /* Adjust height */
        padding: 1rem;
    }

    .detailSection h2 {
        font-size: 20px;
    }

    .leatherType h3 {
        font-size: 16px;
    }

    .leatherOptions {
        flex-direction: row;
        gap: 0.75rem;
    }

    .imageSection {
        display: none; /* Hide image on small screens */
    }

    .mainImage {
        margin: 16px auto;
        width: 100%;
    }

    .productDetails {
        padding: 12px;
    }
}
