.main {
    background-color: var(--app-black);
    height: calc(100dvh - 5.25rem);
    position: relative;
}

.imageContainer {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.middleImage {
    height: 100%;
    z-index: 1;
}

.middleImage>img {
    height: 100%;
    object-fit: contain;
}

.rightImage {
    display: flex;
    flex-direction: column;
    justify-content: end
}

.leftImage {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: -4rem
}


.primaryButton{
    position: absolute;
    top: 59%;
    left: 0;
    z-index: 1;
    width: 100%;
}

.primaryButton>button{
    background-color: var(--app-white);
    color: var(--app-black);
    font-size: 0.875rem;
    font-family: "Fraunces";
}

/**Hover div **/

.hoverContainer {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
}

.hoverContainer>div:hover {
    background-color: var(--app-white);
}

.box {
    width: 100%;
    height: 100%;
    transition: background-color 0.3s ease;
    background-color: transparent;
    border: 1px solid transparent;
}

.box:hover,
.hoverable {
    background-color: var(--app-white);
}

.hoverable {
    transition: background-color 0.3s ease;
}

.exclusiveVerification{
    width: 12rem;
    margin: 0 auto;
}

@media (max-width:720px) and (min-width:576px) {
    .primaryButton{
        width: 12rem;
        left: 42%;
    }
}

@media (max-width:576px){
    .box{
        display: none;
    }
    .leftImage > img{
        width: 25vw;
    }
    .middleImage > img{
        width: 50vw;
        height: 100%;
    }
    .rightImage > img{
        width: 25vw;
    }

    .primaryButton{
        width: 100%;
        left: 0;
    }
    .exclusiveVerification{
        width: 10rem;
        margin: 0 auto;
    }
}

@media (max-width:380px) {
    .exclusiveVerification{
     width: 8rem;   
    }
}