.container{
  width: 100%;
}

.container h1{
  font-family: Butler;
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  margin-bottom: 48px;
}

.leftArrow,.RightArrow{
  border: 1px solid var(--app-dark);
  border-radius: 50%;
  padding: 12px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 102%;
  left: 4%;
  z-index: 5;
  background: var(--app-white);
}
  
.RightArrow{
  left:48%
}

.leftArrow{
  left:52%
}
  
.slider{
    :global(.slick-slide > div){
    width: 16rem;
  }
}


.slider {
  margin: 1rem 0;
}

.slick-slide {
  display: flex;
  justify-content: center;
}

.slick-prev,
.slick-next {
  z-index: 10;
  font-size: 1.5rem;
}

.slick-prev:before,
.slick-next:before {
  color: #6d8a91; /* Matches your tab active color */
}

@media(max-width:1024px) {
  .leftArrow{
    left:54%
  }
    
}

@media(max-width:768px){
  .container h1 {
    margin-bottom: 1.5rem;
  }
  .RightArrow{
    left:48%
  }

  .leftArrow{
    left:54%
  }

  .slider{
    :global(.slick-slide){
      display: flex !important;
      justify-content: center !important;
    }
  }
}

@media(max-width:600px) {

  .RightArrow {
    left: 45%;
  }
    
}

@media (max-width:576px) {
  .slider{
    :global(.slick-slide > div){
    width: 14rem;
    margin: 0;
    }
  }
}
@media (max-width:480px) {
  .slider{
    :global(.slick-slide > div){
    width: 10rem;
    margin: 0;
    }
  }

  .RightArrow{
    left:44%
  }

  .leftArrow{
    left:56%
  }
}

@media (max-width:360px) {
  .slider{
    :global(.slick-slide > div){
    width: 15rem;
    }
  }
}