.container {
  margin: 0 auto 0 auto;
  border-radius: 0.75rem;
  width: 80%;
  padding: 0 1rem;
}

.headerSection {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  margin-bottom: 3rem;
}

.header h2 {
  font-family: Butler, sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.description {
  max-width: 38rem;
  margin-left: auto;
  margin-right: auto;
  opacity: 1; /* Fixing opacity to ensure it's visible */
}

.description p {
  font-family: Fraunces, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}



.slide{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.slide{
  width: 10.625rem;
  height: 10.625rem;
  border-radius: 50%;
}

.slide>img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.cardContainer{
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

.slide p {
  color: #110D06;
  font-family: Inter, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 19.73px;
  text-align: center;
  width: 100%;
}

.buttonContainer {
  width: fit-content;
  padding: 1rem 1.5rem;
  gap: 0.625rem;
  border-radius: 0.5rem;
  margin:1.25rem auto 0;
}

.authorName{
  /* display: flex;
  width: 100%;
  justify-content: center; */
  font-family: Fraunces;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.73px;
  /* text-align: center; */
  margin-top: 1rem;  
  width: 12rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.leftArrow,.RightArrow{
  border: 1px solid var(--app-dark);
  border-radius: 50%;
  padding: 12px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -4%;
  top: 50%;
}

.RightArrow{
  left: -4%;
}
@media(max-width:1028px){
  .imageContainer{
    margin-right: 2rem;
  }

  .leftArrow{
    right: -10%;
  }
  
  .RightArrow{
    left: -10%;
  }
}

@media (max-width: 768px) {
  .container {
      width: 90%;
      padding: 0 1rem;
  }

  .header h2 {
      font-size: 32px;
      line-height: 32px;
  }

  .description {
      width: 100%;
  }

  .description p {
      font-size: 14px;
      line-height: 18px;
  }

  .imageSection {
      row-gap: 1rem;
  }

  .imageContainer {
     margin-right: 0.75rem;
  }
}

@media (max-width: 480px) {
  .imageSection {
      gap: 0.25rem;
  }

  .imageContainer {
      width: 100%; /* Full width in grid */
  }

  .slide{
    width: 8.625rem;
    height: 8.625rem;
  }
  
}

@media (max-width: 360px) {
  .header h2 {
      font-size: 20px;
      line-height: 28px;
  }

  .description p {
      font-size: 14px;
      line-height: 16px;
  }

}
