.mainContainer {
  width: 100%;
  height: 42.375rem; 
  margin-bottom: 4rem;
}

.videoFrame {
  position: relative;
  width: 100%;
  height: 42.375rem; 
  margin: auto;
  border: 1px solid transparent;
  background-image: url('/public/Assets/Icons/videoBackground.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

video {
  border-radius: 3.75rem;
  width: 100%;
  height: auto;
  max-width: 70%;
  max-height: 70%;
  object-fit: contain;
}

.playButton {
  width: 10vw;
  height: 10vw;
  max-width: 50px;
  max-height: 50px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn{
  width: 11rem;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

.playIcon {
  width: 0;
  height: 0;
  border-left: 15px solid white;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.controlsContainer {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.3);
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  backdrop-filter: blur(4px);
}

.fullscreenIcon {
  width: 1.5rem;
  height: 1.5rem;
  color: white;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}

.fullscreenIcon:hover {
  opacity: 0.8;
}

@media (min-width:1025px) and (max-width:1300px) {
  .mainContainer {
    width: 100%;
    height: 31.375rem; 
    margin-bottom: 2.5rem;
  }
  
  .videoFrame {
    height: 31.375rem; 
  }
}

@media (max-width: 1024px) {
  .mainContainer,
  .videoFrame {
    height: auto;
    padding: 1.5rem 0;
  }

  .mainContainer {
    margin-bottom: 0;
  }

  .playButton {
    width: 15vw;
    height: 15vw;
  }

  video {
    max-width: 70%;
    max-height: 90%;
  }
}

@media (max-width: 768px) {
  .playButton {
    width: 20vw;
    height: 20vw;
  }

  .controlsContainer {
    gap: 1rem;
    padding: 0.5rem 1rem;
  }
}

@media (max-width: 576px) {
  .mainContainer,
  .videoFrame {
    height: auto;
    padding: 1rem 0;
  }

  video {
    max-width: 80%;
    max-height: 100%;
    border-radius: 2.75rem;
  }

  .videoFrame {
    height: 15rem;
    background-size: 100% 100%;
    max-width: 100%;
    max-height: 100%;
    margin-top: 0rem;
  }

  .btn {
    width: 100%;
    margin-top: 1.25rem;
  }

  .controlsContainer {
    gap: 0.75rem;
    padding: 0.5rem 0.75rem;
  }
}

@media (max-width:440px) {
  video {
    max-width: 90%;
    max-height: 90%;
    border-radius: 2.75rem;
  }
}

@media (max-width:400px) {
  .videoFrame {
    height: 13rem;
  }

  video {
    width: 90%;
    height: 100%;
  }
}