    .dropdownContainer {
    display: inline-block;
    position: relative;
    width: 100%;
  }
  
  .dropdownHeader {
    padding: 24px 28px;
    border: 1px solid #ddd;
    background-color: var(--app-white);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Fraunces, serif;
    font-size: 18px;
    font-weight: 500;
  }
  
  .arrowUp::after,
  .arrowDown::after {
    display: inline-block;
    transform: rotate(90deg);
    margin-left: 10px;
    font-size: 1.25rem;
  }
  
  .arrowUp::after {
    transform: rotate(-90deg);
  }
  
  .container {
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #ddd;
  }
  
  .sliderContainer {
    position: relative;
    width: 100%;
    margin-top: 1rem;
  }
  
  .slider {
    pointer-events: none;
    position: absolute;
    height: 6px;
    width: 100%;
    -webkit-appearance: none;
    background: transparent;
    outline: none;
    margin: 0;
  }
  
  .slider::-webkit-slider-thumb {
    pointer-events: all;
    position: relative;
    z-index: 3;
    -webkit-appearance: none;
    appearance: none;
    height: 20px;
    width: 20px;
    margin: 0;
    padding: 0;
    background-color: var(--app-primary);
    border-radius: 50%;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    pointer-events: all;
    position: relative;
    z-index: 3;
    height: 20px;
    width: 20px;
    background-color: var(--app-primary);
    border-radius: 50%;
    cursor: pointer;
  }
  
  .sliderTrack {
    position: absolute;
    height: 8px;
    width: 100%;
    background-color: var(--app-grey);
    border-radius: 0.5rem;
    /* transform: translateY(50%); */
    z-index: 1;
  }
  
  .sliderRange {
    position: absolute;
    height: 8px;
    background-color: var(--app-primary);
    border-radius: 0.5rem;
    padding: 0;
    margin: 0;
    z-index: 2;
  }
  
  .sliderLeftValue,.sliderRightValue {
   display: none;
  }
  
  /* .sliderRightValue {
    position: absolute;
    top: -30px;
    right: 0;
    transform: translateX(50%);
    font-size: 14px;
    color: var(--app-primary-dark);
  } */
  
  .inputWrapper {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  
  .inputBox {
    display: flex;
    flex-direction: column;
  }
  
  .inputBox label {
    font-size: 15px;
    color: var(--app-primary-dark);
    margin-bottom: 5px;
  }
  
  .input {
    width: 100px;
    padding: 8px;
    border: 1px solid var(--app-grey);
    border-radius: 5px;
    outline: none;
    background-color: var(--app-white);
    color: var(--app-primary-dark);
    font-size: 14px;
    text-align: center;
  }
  
  .input:focus {
    border-color: var(--app-primary);
    box-shadow: 0 0 5px rgba(151, 30, 66, 0.5);
  }
  