.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .emailIcon{
    background: var(--app-light-pink);
    padding: 2.125rem;
    width: 100%;
    max-width: 150px;
    height: 100%;
    max-height: 150px;
    border-radius: 50%;
  }

  .emailIcon img{
    width: 100%;
    height: 100%;
  }
  
  .modalContent {
    background-color: #fff;
    padding: 20px 40px;
    border-radius: 8px;
    text-align: center;
    width: 85%;
    height: 85%;
    padding: 8.375rem 20rem;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  
  .closeIcon {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    cursor: pointer;
  }
  
  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

  .title {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .message {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .actions {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .resendButton {
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .editButton {
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .loginText {
    font-size: 14px;
    color: #666;
  }
  
  .loginText a {
    color: #000;
    text-decoration: none;
    font-weight: bold;
  }

  /* Tablets and small laptops */
@media (max-width: 1024px) {
  .modalContent {
    padding: 6rem 10rem;
    width: 90%;
  }

  .title {
    font-size: 22px;
  }

  .message {
    font-size: 15px;
  }
}

/* Large phones */
@media (max-width: 768px) {
  .modalContent {
    padding: 4rem 2rem;
    width: 90%;
  }

  .emailIcon {
    padding: 1.5rem;
    max-width: 100px;
    max-height: 100px;
  }

  .title {
    font-size: 20px;
  }

  .message {
    font-size: 14px;
  }

  .actions {
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px;
  }

  .resendButton,
  .editButton {
    width: 100%;
  }
}

/* Small phones */
@media (max-width: 480px) {
  .modalContent {
    padding: 2rem 1rem;
    width: 95%;
    gap: 0.5rem;
  }

  .emailIcon {
    padding: 1rem;
    max-width: 80px;
    max-height: 80px;
  }

  .title {
    font-size: 18px;
  }

  .message {
    font-size: 13px;
  }

  .closeIcon {
    top: 10px;
    right: 10px;
    width: 15px;
  }

  .actions {
    flex-direction: column;
    gap: 8px;
  }

  .resendButton,
  .editButton {
    padding: 8px 15px;
    font-size: 14px;
  }
}

  