.search{
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 647px;
  position: relative;
}

.searchContainer {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 647px;
  background-color:var(--app-white);
  border: 1px solid var(--app-grey);
  border-radius: 30px;
  overflow: hidden;
}

.searchIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-left: 10px;
}

.searchInput {
  flex: 1;
  font-size: 1rem;
  color: var(--app-grey);
  font-family: Fraunces;
  background-color: transparent;
  border: none;
  outline: none;
}

.searchButton {
  padding: 0.5rem 1.25rem;
  background:var(--app-coral-pink);
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin: 12px;
}


.keywords {
  display: flex;
  margin-top: 32px;
  justify-content: center;
}

.keywords span {
  padding: 8px 16px;
  margin-right: 10px;
  background-color: #e9ecef; /* Lighter gray background */
  color:#25292C;
  ;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 14px;
}

.dropdown {
  position: absolute;
  top: 3.5rem;
  width: 100%;
  max-width: 40rem;
  background-color: var(--app-white);
  border: 1px solid var(--app-light-grey);
  border-radius:1.8rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 12;
  max-height: 16rem; /* Limit height and make it scrollable if too long */
  overflow-y: auto;
  text-align: left;
}

.dropdownItem {
  padding: 0.75rem 1rem;
  cursor: pointer;
  color: var(--app-grey);
  font-family: Fraunces;
  font-size: 1rem;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.dropdownItem:hover {
  background: var(--app-light-grey);
}

@media (max-width: 1024px) {
  .searchBar {
      width: 100%;
  }
}

@media (max-width: 768px) {
  .keywords {
      flex-wrap: wrap;
      justify-content: center;
  }

  .keywords span {
      margin-bottom: 10px;
  }

}

/* Responsive adjustments */
@media (max-width: 600px) {
  .searchContainer {
    max-width: 100%;
    flex-direction: row;
    border-radius: 1.8rem;
  }
  
  .searchInput {
    width: calc(50%);
  }
  
  .searchButton {
    width: 30%;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    border-radius: 1.8rem;
    overflow: hidden;
  }
  }

@media (max-width: 576px) {

  .search{
    width: 100%;
    padding:0.75rem 1rem ;
    font-size: 0.75rem;
  }

  .searchContainer input{
    font-size: 10px;
  }

  .searchContainer button{
    font-size: 10px;
  }

  .keywords span {
      font-size: 12px;
  }
  .dropdown{
    width:calc(100% - 32px);
  }
}

@media (max-width:356px){
  .dropdown{
    top: 4.25rem;
  }
}