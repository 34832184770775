.mainContainer {
    background: #480519;
    display: flex;
    flex-direction: column;
    gap: 2.25rem;
    height: 100vh;
    padding: 1.875rem 0.875rem;
    z-index: 2000;
}

.steps, .active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.875rem;
}

.steps h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: #959595;
    font-family: Butler;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    border: 1px solid #959595;
    border-radius: 50%;
    margin-bottom: 0;
}

.active h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--app-light-grey);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-family: Butler;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    color: #5C0720;
    margin-bottom: 0;
}

.steps p, .active p {
    font-family: Butler;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: var(--app-white);
    width: 60px;
    text-align: center;
}


.options h2{
    font-family: Butler;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #2E3646;
}

.customizationOptions{
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
}


.openedOptionTitle{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

/* Responsive Styles */

@media (max-width: 1024px) {
    .mainContainer {
        padding: 0.5rem 0.25rem;
        height: auto;
        width: 100%;
        background-color: #480519;
        z-index: 1000;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); 
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        align-items: stretch; /* Centers the items vertically */
        gap: 1rem; /* Optional: Adjusts the space between grid items */
    }

    .steps, .active {
        flex-direction: column;
        gap: 0.5rem;
    }

    .steps h2, .active h2 {
        width: 30px;
        height: 30px;
        font-size: 12px;
    }

    .steps p, .active p {
        font-size: 10px;
        width: auto;
        text-wrap: nowrap;
    }
}
