.mainContainer {
    margin: var(--container-margin);
    background: #FFF1EF;
    margin-top: 5rem;
    margin-bottom: 0;
    padding: 0 44px;
    display: flex;
    flex-direction: column;
    gap: 3.125rem;
    padding: 3.625rem 3.25rem;
}

.header {
    width: 100%;
    max-width: 676px;
    margin: 0 auto;
}

.header h2 {
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 55px;
    text-align: center;
    color: var(--app-dark);    
}

.header p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--app-dark);    
}

.faqSection {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .mainContainer {
        padding: 3.625rem 2rem; /* Adjust padding for medium screens */
    }

    .header h2 {
        font-size: 28px; /* Adjust font size for medium screens */
        line-height: 40px;
    }

    .header p {
        font-size: 14px; /* Adjust font size for medium screens */
        line-height: 18px;
    }
}

@media (max-width: 768px) {
    .mainContainer {
        padding: 3.625rem 1rem; /* Adjust padding for small screens */
    }

    .header h2 {
        font-size: 24px; /* Adjust font size for small screens */
        line-height: 32px;
    }

    .header p {
        font-size: 12px; /* Adjust font size for small screens */
        line-height: 16px;
    }

    .faqSection {
        gap: 1rem; /* Adjust gap for small screens */
    }
}

@media (max-width: 480px) {
    .mainContainer {
        padding: 2rem 0.5rem; /* Adjust padding for extra small screens */
    }

    .header h2 {
        font-size: 24px; /* Adjust font size for extra small screens */
        line-height: 28px;
    }

    .header p {
        font-size: 16px; /* Adjust font size for extra small screens */
        line-height: 14px;
    }

    .faqSection {
        gap: 0.75rem; /* Adjust gap for extra small screens */
    }
}
