.main {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}


.form-field:focus {
    outline: none;
}
