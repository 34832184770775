.main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: .9rem;
}

.modal {
    padding: 1.5rem;
}

.referIcon {
    width: 3.5625rem;
    height: 3.5625rem;
}

.referIcon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.closeIcon {
    align-self: flex-end;
    cursor: pointer;
}

.title {
    color: var(--app-primary-dark);
    text-align: center;
    font-family: var(--inter);
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: -0.0125rem;
}

.messageContainer {
    width: 100%;
    color: var(--app-primary-dark);
    text-align: center;
    font-family: var(--inter);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 2.375rem;
    letter-spacing: -0.00875rem;
}

.actionContainer {
    border-top: 1px solid lightgray;
    margin-top: 1.25rem;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
}

@media (min-width: 560px) {
    .main {
        min-width: 21rem;
    }
}