.tabSection{
    height:fit-content;
    margin: 5rem var(--container-margin) 0;
    background: 
    linear-gradient(180deg, rgba(159, 120, 0, 0) -2.09%, rgba(249, 241, 214, 0.7) 12.24%, #FFFDF6 28.01%, rgba(254, 251, 241, 0.985407) 43.77%, rgba(254, 251, 241, 0.984974) 43.78%, rgba(251, 246, 232, 0.957746) 63.75%, rgba(247, 240, 218, 0.915493) 81.34%, rgba(246, 239, 216, 0.911638) 81.35%, rgba(246, 239, 216, 0.911638) 82.19%, rgba(239, 226, 189, 0.830986) 99.99%, rgba(159, 120, 0, 0) 100%) top, /* top to bottom */
    linear-gradient(0deg, rgba(159, 120, 0, 0) -5.09%, rgba(249, 241, 214, 0.7) 12.24%, #FFFDF6 28.01%, rgba(254, 251, 241, 0.985407) 43.77%, rgba(254, 251, 241, 0.984974) 43.78%, rgba(251, 246, 232, 0.957746) 63.75%, rgba(247, 240, 218, 0.915493) 81.34%, rgba(246, 239, 216, 0.911638) 81.35%, rgba(246, 239, 216, 0.911638) 82.19%, rgba(239, 226, 189, 0.830986) 99.99%, rgba(159, 120, 0, 0) 100%) bottom, /* bottom to top */
    linear-gradient(90deg, rgba(159, 120, 0, 0) -5.09%, rgba(249, 241, 214, 0.7) 12.24%, #FFFDF6 28.01%, rgba(254, 251, 241, 0.985407) 43.77%, rgba(254, 251, 241, 0.984974) 43.78%, rgba(251, 246, 232, 0.957746) 63.75%, rgba(247, 240, 218, 0.915493) 81.34%, rgba(246, 239, 216, 0.911638) 81.35%, rgba(246, 239, 216, 0.911638) 82.19%, rgba(239, 226, 189, 0.830986) 99.99%, rgba(159, 120, 0, 0) 100%) left, /* left to right */
    linear-gradient(270deg, rgba(159, 120, 0, 0) -5.09%, rgba(249, 241, 214, 0.7) 12.24%, #FFFDF6 28.01%, rgba(254, 251, 241, 0.985407) 43.77%, rgba(254, 251, 241, 0.984974) 43.78%, rgba(251, 246, 232, 0.957746) 63.75%, rgba(247, 240, 218, 0.915493) 81.34%, rgba(246, 239, 216, 0.911638) 81.35%, rgba(246, 239, 216, 0.911638) 82.19%, rgba(239, 226, 189, 0.830986) 99.99%, rgba(159, 120, 0, 0) 100%) right; /* right to left */
    border-radius: 0.75rem;
    padding-top: 5rem;
  }

  .aboutSection{
    display: flex;
    justify-content: space-around;
    gap: 1rem;
  }

  .whoAreWe{
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 2rem;
  }

  .whoAreWe h2{
    color: #0F0B05;
    font-family: "Butler";
    font-size: 36px;
    font-weight: 700;
    line-height: 43.2px;
    text-align: left;    
  }

  .whoAreWe p{
    width: 100%;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: var(--app-grey);
  }

  .knowMoreLink{
    padding: 0;
    color:var(--app-claret) ;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.73px; 
  }

.footerImage{
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
}

@media(max-width:768px){
  .whoAreWe h2{
    font-size: 32px;
  }

  .whoAreWe p {
    text-align: center;
  }

  .whoAreWe{
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .footerImage{
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
  }

}

  @media(max-width:600px){
    .tabSection{
      margin: 2.5rem 0 0;
      padding-top: 1rem;
    }
    .aboutSection{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
    }

    .whoAreWe{
        align-items: center;
        text-align: left;
        width: 100%;
    }

    .whoAreWe h2{
      font-size: 2rem;
    }
    .whoAreWe p{
        width: 90%;
        text-align: center;
    }

    .aboutImage {
      width: 8rem;
      height: 8rem;
    }

    .aboutImage img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  @media (min-width:600px) and (max-width:768px){
    .aboutSection{
      padding: 1rem;
    }
  }