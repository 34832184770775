.search {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

.searchContainer {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 360px;
  background-color: var(--app-white);
  border: 1px solid var(--app-grey);
  border-radius: 30px;
  overflow: hidden;
}

.searchIcon {
  margin-left: 15px;
  color: white;
  font-size: 1.5rem;
}

.searchInput {
  flex: 1;
  padding: 0.75rem 1.25rem;
  font-size: 0.75rem;
  color: var(--app-grey);
  font-family: Fraunces;
  background-color: transparent;
  border: none;
  outline: none;
}

.searchButton {
  padding: 0.5rem 1.25rem;
  background: var(--app-coral-pink);
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin: 12px;
  min-width: 8rem;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .searchContainer {
    max-width: 100%;
    flex-direction: row;
    border-radius: 1.8rem;
  }

  .searchInput {
    width: calc(50%);
  }

  .searchButton {
    width: 30%;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    border-radius: 1.8rem;
    overflow: hidden;
  }
}

.keywords {
  display: flex;
  margin-top: 32px;
  justify-content: center;
}

.keywords span {
  padding: 8px 16px;
  margin-right: 10px;
  background-color: #e9ecef; /* Lighter gray background */
  color: #25292c;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 14px;
}

@media (max-width: 1024px) {
  .searchBar {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .keywords {
    flex-wrap: wrap;
    justify-content: center;
  }

  .keywords span {
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .search {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 0.75rem;
  }

  .searchContainer input {
    font-size: 8px;
  }

  .searchContainer button {
    font-size: 8px;
    margin:8px;
    margin-left: 0;
  }

  .keywords span {
    font-size: 12px;
  }
}

@media screen and (max-width: 994px) {
  .search {
    justify-content: center;
  }
}