.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 16rem; /* Adjust width as needed */
  height: 29.75rem;
  margin: 2.125rem 1rem;
  padding: 1.25rem 0;
  background: var(--app-light-grey);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05); /* Slightly increases the card size */
  background: var(--app-light-pink);
}

.imageContainer {
  position: relative;
  display: flex;
}

.image {
  position: relative;
  width: 13.5rem; /* Ensures image takes full width of container */
  height: 18.75rem; /* Allows image to adjust height proportionally */
  object-fit: cover; /* Ensures the image covers the container without stretching */
  margin: 0 auto 0;
}

.heartButton {
  width: 1.875rem;
  height: 1.875rem;
  position: absolute;
  color: var(--app-claret);
  background: #fff;
  border-radius: 50%;
  top: 1%;
  right: 8%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 5;
}

.card:hover .heartButton {
  opacity: 1;
}

.addToCartButton {
  display: flex;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  width: 7.37rem;
  padding: 0.5rem;
  border: 1px solid var(--app-claret);
  background: #fff;
  border-radius: 0.5rem;
  cursor: pointer;
  transform: translateY(10%);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 10;
}

.card:hover .addToCartButton {
  opacity: 1;
}

.addToCartButton button {
  color: var(--app-claret);
  font-family: var(--inter);
  font-size: 1rem;
  font-weight: 600;
  line-height: 16.94px;
}

.addToCartButton:hover {
  background-color: #e0e0e0;
}

.content {
  padding: 0 1rem;
}

.topRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.875rem auto;
}

.titleContainer {
  max-width: 80%;
}

.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  margin: 0;
  max-height: 3rem; /* Adjust height for 2 lines based on line height */
  width: 100%;
}

.author {
  font-size: 0.875rem;
  color: #757575;
  margin: 0.25rem 0;
  padding: 0;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
}

.bottomRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.25rem 0;
}

.price {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.editButton {
  background: none;
  border: none;
  color: #757575;
  font-size: 1rem;
  cursor: pointer;
}

.topRow {
  min-height: 5rem;
}

/* Media Queries for Tablet and Mobile */
@media (max-width: 768px) {
  .card {
      width: 100%; /* Adjust to full width of viewport */
      max-width: 300px; /* Limit width to maintain readability */
      margin: 1rem auto; /* Center align and add margin */
  }

  .card:hover{
    transform: none;
  }

  .heartButton {
      width: 2.25rem;
      height: 2.25rem;
  }

  .image {
      margin-top: 10px; /* Reduce top margin for smaller screens */
  }

  .topRow {
      flex-direction: column; /* Stack items vertically */
      align-items: flex-start; /* Align items to the start */
      margin: 0.25rem auto;
      min-height: 5rem;
  }

  .author {
      font-size: 0.75rem; /* Reduce font size for author */
      margin-top: 0.25rem; /* Adjust margin */
  }

  .bottomRow {
      flex-direction: row; /* Stack items vertically */
      margin: 0.5rem 0; /* Adjust vertical margin */
  }
}

@media screen and (max-width: 560px) {
  .card {
      width: 10.25rem;
      height: 22rem;
  }

  .image {
      width: 8.625rem; /* Ensures image takes full width of container */
      height: 12rem; /* Allows image to adjust height proportionally */
      object-fit: cover; /* Ensures the image covers the container without stretching */
      margin: 0 auto 0;
  }

  .title {
      font-size: 1rem;
  }

  .addToCartButton {
      transform: translateY(0px);
      opacity: 1; /* Always visible on smaller screens */
  }

  .heartButton {
      opacity: 1; /* Always visible on smaller screens */
  }

  .topRow {
    margin: 0.25rem auto;
    min-height: 5rem;
  }
}
