.ctaContainer {
    background: var(--app-light-pink);
    color: #25292C;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 2rem;
    border-radius: 0.75rem;
    margin: 4rem var(--container-margin);
}

.textContent {
    flex: 1;
}

.textContent h2 {
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 47px;
    text-align: left;
}

.textContent p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;    
    margin-top: 0.5rem;
    color: #25292C;

}

.ctaButton {
    margin-left: 2rem;
}


@media (max-width: 768px) {
    .ctaContainer {
        flex-direction: column;
        text-align: center;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 2.5rem;
        margin-bottom: 3rem;
        padding: 1rem 1.5rem;
    }
    
    .ctaButton {
        margin: 1rem 0 0 0;
    }

    .textContent h2{
        font-size: 24px;
        line-height: 30px;
        text-align: center;
    }

    .textContent p{
        font-size: 1rem;
    }
}
