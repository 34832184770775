.addressCard {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem 0;
    border-bottom: 1px solid #E8ECEF;
    border-radius: 5px;
    background-color: var(--app-light-grey);
    margin-bottom: 10px;
  }
  
  .radioContainer {
    display: flex;
    align-items: flex-start;
  }
  
  .addressCard input[type="radio"] {
    accent-color: var(--app-primary);
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: 5px;
    cursor: pointer;
    flex-shrink: 0;
  }
  
  .addressLabel {
    font-size: 14px;
    color: #333;
  }
  
  .addressLabel p {
    margin: 0;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  
  .actions {
    font-size: 14px;
  }
  
  .actions button {
    background: none;
    border: none;
    color: var(--app-claret);
    cursor: pointer;
    padding: 0;
  }
  
  .actions button:hover {
    text-decoration: underline;
  }
  
  .actions button:focus {
    outline: none;
  }
  
  .actions > div {
    margin: 0;
    color: var(--app-primary);
    text-align: left;
    font-family: Fraunces;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    cursor:pointer;
  }

  @media screen and (max-width: 820px) {
    .addressCard {
     gap: 2rem;
    }

    .addressCard input[type="radio"] {
      width: 0.875rem;
      height: 0.875rem;
    }
  }