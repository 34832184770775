.headerSection{
    display: flex;
    justify-content: space-between;

}
.title {
    border-bottom: 1px solid var(--app-grey);
    font-family: "Butler";
    font-size: 1.25rem;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.75rem;
}

.headerBtn{
    width: 9.125rem;
}

.tabs{
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
}

.tab{
    border: 1px solid #D0D4D7;
    padding: 0.625rem 1rem;
    border-radius: 0.75rem;
    color: #25292C;
    cursor: pointer;
}

.activeTab{
    background: #6D8A91;
    color: var(--app-white);
}

.addressSection{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.875rem;
    margin-top: 1.875rem;
}