.container{
    width: 7.75rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    padding: 1rem;
}

.image{
    width: 72px;
    height: 72px;
    border-radius: 50%;
}

.image img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.details h2{
    font-family: Butler;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: center;
    color: black;
    margin-bottom: 4px;
}

.details p{
    margin-top: 0.25rem;
    color: var(--app-white);
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 15px; */
    text-align: center;
}

@media (max-width: 1024px) {
    .mainContainer {
        padding: 1rem;
    }

    .productDetails {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .mainImage {
        width: 100%;
        height: auto;
    }

    .images {
        gap: 1rem;
    }

    .details h1 {
        font-size: 2rem;
    }

    .details h2 {
        font-size: 1rem;
    }

    .details h3 {
        font-size: 1.5rem;
    }

    .leatherTypes,
    .varientTypes,
    .quantityContainer {
        margin-top: 1rem;
    }

    .quantity {
        flex-direction: column;
        gap: 1rem;
    }

    .selectQty {
        width: 100%;
        justify-content: space-between;
    }
}

@media (max-width: 768px) {
    .details h1 {
        font-size: 1.5rem;
    }

    .details h3 {
        font-size: 1.25rem;
    }

    .featureSection {
        padding: 2rem;
    }

    .mainBody {
        padding: 2rem;
    }

    .tabs {
        width: 100%;
    }

    .tabs::-webkit-scrollbar {
        display: none;
    }
}

@media (max-width: 600px) {
    .productDetails {
        gap: 1rem;
    }

    .imageSection,
    .details {
        width: 100%;
    }

    .selectQty {
        gap: 0.5rem;
    }

    .selectQty h2 {
        font-size: 1rem;
    }

    .addToCart,
    .customizeBtn button {
        padding: 1rem;
        font-size: 0.875rem;
    }

    .featureSection {
        padding: 1rem;
    }

    .mainBody {
        padding: 1rem;
    }

    .tabs {
        padding: 0.5rem 0;
    }

    .tab {
        padding: 0.5rem;
        font-size: 0.875rem;
    }

    .container{
        width: 5.75rem;
        padding: 0.25rem;
        gap: 1rem;
    }
}

@media (max-width:480px){
    .container{
        width: 5.75rem;
        padding: 0.25rem;
    }
}