.title {
    height: 2.625rem;
    border-bottom: 1px solid var(--app-grey);
    font-family: "Butler";
    font-size: 1.25rem;
    font-weight: 700;
}

.container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.tabs {
    display: flex;
    gap: 1rem;
}

.tab{
    display: flex;
    justify-content: center;
    border: 1px solid #D0D4D7;
    color: #25292C;
    padding: 0.375rem 1rem;
    border-radius: 0.75rem;
    cursor: pointer;
}

.activeTab{
    background: #6D8A91;
    color: #fff;
}

.OptionCard {
    flex: 1 1 45%; 
    height: fit-content;
    box-sizing: border-box; 
    max-width: 20rem;
}

.container text{
    font-family: Fraunces;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

.cardsSection {
    display: flex;
    grid-template-columns: 1fr 1fr;
    justify-content: flex-start;
    gap: 2.5rem;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.cardsSection p {
    font-size: 0.875rem;
    font-family: Fraunces;

}

.cardsSection > div{
    width: 100%;
    margin: 0;
}

.pagination {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 2.5rem;
  }

  .pageButton {
    width: 3rem;
  }

  .pageInfo {
    font-size: 15px;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .pagination {
      flex-wrap: wrap;
      gap: 5px;
    }
  
    .pageButton {
      font-size: 12px;
      padding: 6px 12px;
      width: 1rem;
    }
  
    .pageInfo {
      font-size: 12px;
      margin: 5px 0;
    }
  }
  
  @media (max-width: 480px) {
    .pagination {
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }
  
    .pageButton {
      width: 1.25rem;
      text-align: center;
    }
  
    .pageInfo {
      font-size: 12px;
    }
  }
  