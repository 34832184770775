.otherBlogs{
    margin-top: 3.125rem;
}

.headerSection h2{
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
    color: var(--app-dark);
}

.headerSection{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blogs {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 26px;
    row-gap: 40px;
}

.card {
    max-width: 28rem;
}

.paginationControl{
    display: flex;
    align-items: center;
    gap: 0.315rem;
}

.paginationControl p,.disabled{
    font-family: Fraunces;
    font-size: 13px;
    font-weight: 600;
    line-height: 16.03px;
    text-align: left;
    color: #333333;
    cursor: pointer;
}

.disabled{
    color: #CCCCCC !important;
}

.page,.activePage{
    font-family: Fraunces;
    font-size: 13px;
    font-weight: 600;
    line-height: 16.03px;
    text-align: left;
    color: var(--app-dark);
    padding: 0.5rem 0.75rem;
    border: 1px solid #F1F1F1;
    border-radius: 0.5rem;
}

.activePage{
    background: var(--app-dark);
    color: var(--app-white) !important;
}


@media (max-width:768px){
    .card {
        min-width: 30vw;
    }
    .blogs{
        grid-template-columns: repeat(2,1fr);
    }
}