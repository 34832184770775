.message {
    text-align: center;
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    margin: 0;
  }
  
  .waitIcon {
    font-size: 2.5rem;
    color: var(--app-primary);
    margin-bottom: 1rem;
  }
  
  .actions {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .btn {
    min-width: 140px;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn:disabled {
    background-color: #f1f1f1;
    color: #888;
    cursor: not-allowed;
  }
  
  .sendingButton {
    background-color: #f1f1f1;
    color: #888;
    cursor: not-allowed;
    transform: scale(0.98);
    transition: transform 0.2s ease;
    border: 2px solid var(--app-primary);
  }
  
  /* ---------- MEDIA QUERIES ---------- */
  
  /* Tablets and small laptops */
  @media (max-width: 1024px) {
    .message {
      font-size: 0.95rem;
    }
  
    .btn {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  
  /* Large phones */
  @media (max-width: 768px) {
    .message {
      font-size: 0.9rem;
    }
  
    .actions {
      flex-direction: column; /* Stack buttons vertically */
      gap: 10px;
    }
  
    .btn {
      width: 100%;
      font-size: 0.9rem;
      padding: 10px;
    }
  }
  
  /* Small phones */
  @media (max-width: 480px) {
    .message {
      font-size: 0.85rem;
      margin: 10px 0;
    }
  
    .btn {
      width: 100%;
      font-size: 0.85rem;
      padding: 8px 12px;
    }
  }
  