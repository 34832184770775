:root {
  --color-env: #0077b2;
  --color-env2: #005a8a;
  --color-flap: #005c91;
  --color-heart: #d00000;
  --env-border-radius: 6px;
  --env-width: 480px;
  --env-height: 322px;
}

.envelopeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(var(--env-height)+100px);
  position: relative;
}

.envelope {
  position: relative;
  width: var(--env-width);
  height: var(--env-height);
  border-bottom-left-radius: var(--env-border-radius);
  border-bottom-right-radius: var(--env-border-radius);
  background: linear-gradient(90deg, #7C564A 0%, #8F5D4B 33%, #9D634C 69%, #A3654D 99.99%);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 0px; /* Add some margin for spacing */
}

.front {
  position: absolute;
  width: 0;
  height: 0;
  z-index: 3;
}

.flap {
  width: 100%;
  height: 202px;
  background: url(/public/Assets/Images/envolope-top-gradient.svg);
  transform-origin: top;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: contain;
}

.leftFlap {
  height: 100%;
  width: 50%;
  background: url(/public/Assets/Images/envolope-left-gradient.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.rightFlap {
  position: absolute;
  right: 0;
  width: 50%;
  height: 100%;
  background: url(/public/Assets/Images/envolope-right-gradient.svg);
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain;
}

.pocket {
  width: 100%;
  height: 100%;
  background: url(/public/Assets/Images/envolope-bottom-gradient.svg);
  pointer-events: none;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: contain;
}

.letter {
  position: relative;
  background-color: #fff;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 90%;
  top: 5%;
  border-radius: var(--env-border-radius);
  box-shadow: 0 2px 26px rgba(0, 0, 0, 0.12);
}

.letter:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(180deg,
      rgba(255, 255, 255, 0) 25%,
      rgba(215, 227, 239, 0.7) 55%,
      rgba(215, 227, 239, 1) 100%);
}

.words {
  position: absolute;
  left: 10%;
  width: 80%;
  height: 14%;
  background-color: #eeeff0;
}

.words.line1 {
  top: 15%;
  width: 20%;
  height: 7%;
}

.words.line2 {
  top: 30%;
}

.words.line3 {
  top: 50%;
}

.words.line4 {
  top: 70%;
}

.open .flap {
  transform: rotateX(180deg);
  transition: transform 0.4s ease, z-index 0.6s;
  z-index: 1;
}

.close .flap {
  transform: rotateX(0deg);
  transition: transform 0.4s 0.6s ease, z-index 1s;
  z-index: 5;
}

.close .letter {
  transform: translateY(0px);
  transition: transform 0.4s ease, z-index 1s;
  z-index: 1;
}

.open .letter {
  transform: translateY(calc(-1 * (var(--env-height) / 3)));
  transition: transform 0.4s 0.6s ease, z-index 0.6s;
  z-index: 2;
}

.openLetter {
  width: 800px;
  height: 450px;
  background: var(--app-white);
  display: flex;
  overflow: hidden;
}

.reset {
  text-align: center;
}

.btnContainer {
  position: absolute;
  top: 10rem;
  left: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -50px; /* Adjust to center below the envelope */
  padding: 1rem;
  background: url('/public/Assets/Images/buttonBackground.svg') no-repeat center center;
  background-size: cover;
  z-index: 7;
}

.backgroundBtn {
  border-radius: 61% 39% 43% 57% / 39% 53% 47% 61%;
  background: radial-gradient(55.64% 55.78% at 47.94% 55.27%, #A3654D 0%, #A4664E 46%, #AB6D53 62%, #B7785C 74%, #CA8B6B 84%, #A3654D 100%);
  padding: 10px;
}

.RBBtn {
  font-family: Bricktown;
  font-size: 36px;
  font-weight: 400;
  line-height: 55px;
  text-align: center;
  color: var(--app-claret);
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, #F7B284 0%, #F4B082 41%, #EDA97D 61%, #DF9D75 77%, #CD8D69 91%, #BB7E5E 100%);
}

.animateBtn {
  animation: moveAndWobble 0.8s ease-in-out;
}

@keyframes moveAndWobble {
  0% {
    transform: scale(1);
  }
  40% {
    transform: rotate(5deg) scale(0.9);
  }
  60% {
    transform: rotate(-5deg) scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  :root {
    --env-width: 400px;
    --env-height: 268px;
  }

  .openLetter {
    width: 600px;
    height: 350px;
  }

  
.btnContainer {
  top: 8rem;
}
}

@media (max-width: 768px) {
  :root {
    --env-width: 320px;
    --env-height: 214px;
  }

  .openLetter {
    width: 400px;
    height: 250px;
  }

  .RBBtn {
    font-size: 28px;
    line-height: 40px;
  }

  .btnContainer {
    top: 7rem;
    left: 33%;
  }
}

@media (max-width: 480px) {
  :root {
    --env-width: 200px;
    --env-height: 150px;
  }

  .openLetter {
    width: 300px;
    height: 200px;
  }

  .btnContainer{
    right: 35%;
    top: 5.5rem;
  }

  .RBBtn {
    font-size: 18px;
    line-height: 24px;
  }
}
