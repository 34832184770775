.mainContainer {
    height: 100vh;
    overflow: hidden;
}

.mainSection {
    margin: 0rem 0;
    flex: 1;
    overflow: hidden;
}

.headerSection{
    display: flex;
    gap: 30%;
}

.crumb {
    color: var(--app-black) !important;
}


.icons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap:50%;
    margin-bottom: 1.125rem;
}

.iconContainer{
    display: flex;
    gap: 1.5rem;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.preview{
    width: 100%;
    max-width: 88px;
}


.container {
    display: flex;
    flex-direction: row-reverse;
    gap: 2.25rem;
}

.sidebar {
    position: fixed;
    left: 0;
}

.mainSection {
    flex: 1;
    padding: 1rem;
    margin-left: 10rem;
}

.previewModalHidden {
    display: none;
  }
  
  .previewModalVisible {
    display: block; /* Or any other styling to display the modal */
  }

  .previewBtns{
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
  }

  .saveMessage {
    position: absolute;
    top: 30px; /* Adjust based on where you want it */
    left: 70%;
    background-color: #4caf50; /* Green color for success */
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.9em;
    animation: fadeInOut 2s ease forwards;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  
  .checkIcon {
    color: white;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    20% {
      opacity: 1;
      transform: translateY(0);
    }
    80% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-10px);
    }
  }

/* Responsive Styles */

@media (max-width: 1200px) and (min-width: 1024px) {
    .mainSection {
        flex: 1;
        padding: 1rem;
        margin-left: 1rem;
    }
}

@media (max-width: 1200px) {

    .icons {
        gap: 1rem;
    }

    .sidebar{
        position: sticky;
        bottom: 0;
        z-index: 2000;
    }
}

@media (max-width: 1024px) {
    .container {
        flex-direction: column;
        justify-content: spa;
    }

    .headerSection{
        flex-direction: column;
    }
    .icons {
        position: sticky;
        top: 50%;
        align-items: center;
        gap: 0.75rem;
        margin-bottom: 1rem;
    }

    .mainSection{
        margin: 0;
    }
}

@media (max-width: 768px) {
    .icons {
        gap: 0.75rem;
    }

    .mainSection {
        padding: 0.5rem;
    }
    .previewBtns{
        width: 90vw;
        font-size: 1rem;
        justify-content: space-evenly;
    }
}

@media (max-width: 576px) {
    .icons {
        gap: 1.5rem;
        font-size: 0.875rem; /* Adjust icon size for very small screens */
    }

    .mainSection {
        padding: 0.5rem;
        margin: 0.5rem;
    }
}


.backModal p {
    font-family: Fraunces;
    font-size: 1rem;
    color: var(--app-black);
}

.btnContainer {
    display: flex;
    align-items: center;
    gap: 1.25rem;
}
