.main {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.26px;
    text-align: left;
    color: #0F0B05;
    text-decoration: none;
}