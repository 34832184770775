.lightButton {
    width: 100%;
    display: flex;
    padding: 0.5rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background: #fff;
    border: 1px solid var(--app-primary);
    /* color: var(--app-primary); */
    color:var(--app-primary);
    font-family: Fraunces;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    outline: none;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    min-width: max-content;
}

.lightButton:hover{
    background: #FFECF1;
}