.container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: 70dvw;
    height: 70dvh;
}

.viewDetails {
    width: 65%;
}

.bookDetails {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.bookImage {
    width: 6.875rem;
    height: 6.875rem;
    margin-right: 20px;
    border-radius: 1rem;
}

.bookImage img{
    width:100%;
    height: 100%;
    object-fit: contain;
    border-radius: 1rem;
}

.bookInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.bookTitle {
    font-family: Butler;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 19.2px;
    text-align: left;
    color: var(--app-dark);
}

.authorName {
    margin-top: 0.5rem;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #666666;
}

.bookPrice {
    font-family: Butler;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    color: var(--app-dark);
    margin: 10px 0;
}

.quantity {
    display: flex;
    align-items: center;
}

.quantityButton {
    background-color: var(--app-light-grey);
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    color: var(--app-primary);
    font-size: 1rem;
    border-radius: 50%;
}

.quantityValue {
    margin: 0 10px;
}

.steps {
    display: flex;
    margin-bottom: 20px;
}

.step,
.stepActive {
    margin-right: 10px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.stepActive {
    background-color: #f0f0f0;
    font-weight: bold;
}

.customizationCharges {
    margin-bottom: 20px;
}

.customizationCharges h3 {
    margin-bottom: 10px;
    font-family: Fraunces;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 17.26px;
    text-align: left;
    color: #1F2024;
}

.infoText {
    font-size: 12px;
    color: #888;
    margin-bottom: 15px;
}

.customizationList {
    padding: 10px;
}

.customizationItem {
    margin-bottom: 15px;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--app-dark);
}

.customizationItem h4{
    font-family: Fraunces;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #5F6368;
    margin-bottom: 0.625rem;
}

.customizationItemDetail{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 0.3fr;
}

.customizationItemDetail p{
    font-family: Fraunces;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #5F6368;
}

.customizationItemDetail .optionPrice{
    color: var(--app-dark);
    font-weight: 500;
}

.orderSummary {
    width: 30%;
    padding: 20px;
    border-radius: 10px;
    background-color: #fafafa;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  }
  
  .orderSummaryTitle {
    font-family: Butler;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--app-dark);
    margin-bottom: 20px;
    text-align: center;
  }
  
  .summaryItem {
    display: flex;
    justify-content: space-between;
    font-family: Fraunces;
    font-size: 1rem;
    color: #666666;
    margin-bottom: 10px;
  }
  
  .divider {
    border: none;
    border-top: 1px solid #ccc;
    margin: 20px 0;
  }
  
  .summaryTotal {
    display: flex;
    justify-content: space-between;
    font-family: Butler;
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--app-dark);
  }

  /* Responsive Adjustments */
@media screen and (max-width: 1024px) {
    .container {
        flex-direction: column;
        height: auto;
        padding-top: 0;
    }

    .viewDetails {
        width: 100%;
    }

    .orderSummary {
        width: 100%;
        margin-top: 20px;
    }

    .bookDetails {
        flex-direction: column;
        align-items: flex-start;
    }

    .bookTitle, .bookPrice {
        font-size: 1.125rem;
    }

    .bookTitle {
        margin-top: 0.5rem;
    }

    .authorName {
        font-size: 0.875rem;
    }

    .summaryTotal {
        font-size: 1rem;
    }
}

@media screen and (max-width: 768px) {

    .bookTitle {
        font-size: 1.125rem;
    }

    .authorName {
        font-size: 0.875rem;
    }

    .bookPrice, .summaryTotal {
        font-size: 1rem;
    }

    .orderSummary {
        padding: 15px;
    }

    .customizationCharges h3 {
        font-size: 1rem;
    }
}

@media screen and (max-width: 480px) {

    .customizationCharges h3 {
        font-size: 0.875rem;
    }

    .orderSummary {
        padding: 10px;
        box-shadow: none;
        background-color: #fff;
    }
}
  
