.secondaryButton {
    width: 100%;
    display: flex;
    padding: 0.5rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background: var(--app-claret);
    /* color: var(--app-primary); */
    color: #ffffff;
    font-family: Fraunces;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    outline: none;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    min-width: max-content;
}

.secondaryButton:hover{
    background: #6C142E;
}

.disabled{
    opacity: 0.5;
}

.disabled:hover{
    background: var(--app-claret);
    opacity: 0.5;
}
