.title {
    height: 2.625rem;
    border-bottom: 1px solid var(--app-grey);
    font-family: "Butler";
    font-size: 1.25rem;
    font-weight: 700;
}

.bookSection{
    margin: 2rem 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.mainContainer{
    width: 100%;
}
.text {
    text-align: center; /* Center-align text */    
    width: 50%;
    margin: 6rem auto;
}

.text h2 {
    font-family: "Butler"; /* Match the title font family */
    font-size: 1.5rem; /* Larger font size for heading */
    margin-bottom: 1rem; /* Space below the heading */
    color: var(--app-dark-grey); /* Darker color for the heading */
}

.text p {
    font-family: "Fraunces";
    font-size: 1rem; /* Regular font size */
    color: var(--app-medium-grey); /* Medium grey color for readability */
    margin-bottom: 1.5rem; /* Space below the paragraph */
}

.text .textBtn {
    width: 9rem;
    margin: 0 auto;
}

@media (max-width:576px){
    .bookSection{
        justify-content: center;
        align-items: center;
    }
}

@media (max-width:576px) and (min-width:350px) {
    .bookSection{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        column-gap: 0.25rem;
    }
}
