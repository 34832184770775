.cardContainer {
    display: flex;
    gap: 1.25rem;
    background:#5C0B16;
    width: 100%;
    max-width: 19.75rem;
    border-radius: 0.75rem;
    align-items: center; /* Ensure vertical alignment */
  }
  
  .image {
    width: 6rem;
    height: 5.45rem;
  }

  .image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  
  .data {
    flex: 1;
    
  }

  .data p{
    color: #ffffff;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    
  }
  
  @media (max-width: 1024px) {
    .cardContainer {
      padding: 1.5rem 0.75rem;
      max-width: 16rem;
      gap: 1rem;
    }
  
    .key {
      width: 2rem;
      height: 2rem; /* Ensure height and width are equal */
    }
  
    .data {
      font-size: 0.875rem;
    }
  }
  
  @media (max-width: 768px) {
    .cardContainer {
      padding: 1rem 0.5rem;
    }
  
    .key {
      margin-bottom: 0.5rem;
    }
  
    .data {
      font-size: 0.75rem;
    }
  }
  
  @media (max-width: 480px) {
    .cardContainer {
      padding: 0.75rem 0.5rem;
      max-width: 100%;
    }
  
    .key {
      width: 1.5rem;
      height: 1.5rem; /* Ensure height and width are equal */
    }
  
    .data {
      font-size: 0.625rem;
    }
  }
  