.mainContainer{
   
}

.mainContainer h1{
    font-family: Butler;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: var(--app-dark);
    padding: 0 1.5rem 1.25rem;
}

.overview{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EAEAEA;
    padding-bottom: 0.875rem;
}

.overview h2{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #141718;
    margin: 0;
}

.overview p{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: right;
    color: #141718;
}

.addOn{
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
    padding-bottom: 1rem;
}

.addOnHeader{
    margin: 0;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    color: #141718;
}

.priceSection{
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #E4E7E9;
}

.Tax,.Discount,.shipping,.subTotal{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Tax h2,.Discount h2,.shipping h2,.subTotal h2{
    color: #5F6C72;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin: 0;
}

.Tax p,.Discount p,.shipping p,.subTotal p{
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #191C1F;
}

.total{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.total h2{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin: 0;
    color: #191C1F;
}

.total p{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #191C1F;
}

.couponSection{
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.couponSection h2{
    font-family: Fraunces;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: var(--app-dark);
    margin-bottom: 7px;
}

.couponSection p{
    color: #6C7275;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
}

.couponButton{
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    border: 1px solid #6C727580;
    padding: 12px 16px;
    gap: 0.5rem;
}

.couponButton div{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.couponButton img{
    width: 20px;
    height: 20px;
}

.couponButton input{
    border: none;
    width: 100%;
    max-width: 240px;
    color:#6C7275;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;

}

.couponButton input:focus{
    outline: none;
}

.couponButton button{
    border: 1px solid var(--app-claret);
    border-radius: 15%;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.012em;
    text-align: left;
    color: var(--app-claret);
}