.mainContainer{
    margin: 4rem var(--container-margin);
}

.blogHeader{
    font-family: Butler;
    font-size: 2.25rem;
    font-style: normal;
    text-align: left;
    font-weight: 700;
    line-height: 2.75rem; /* 115.385% */
    letter-spacing: -0.52px;
}

.blogBanner{
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 500px;
    border-radius: 12px;
    margin-bottom: 2rem;
}

.blogBanner img{
    width: 50%;
    height: 50%;
    object-fit: contain;
    border-radius: 12px;
}

.blogViewContainer {
    margin-top: 2rem;
    background-color: var(--header-color);
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.blogBannerImageContainer img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin: 1rem 0;
}

.blogContent img {
    max-width: 100%;
}

@media (max-width:768px) {
    .blogHeader{
        font-size: 1.75rem;
        line-height: 2rem; /* 115.385% */
    }
}

@media (max-width:480px) {
    .blogHeader{
        font-size: 1.5rem;
        line-height: 1.75rem; /* 115.385% */
    }
}