.title p {
  font-family: Fraunces;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.10000000149011612px;
  text-align: left;
  color: var(--app-dark);
}

.title h2 {
  font-family: Butler;
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-align: left;
  color: var(--app-dark);
  margin-top: 0.5rem;
}

.formBody {
  background: #f9f9f9;
  padding: 2rem;
  border-radius: 12px;
}

.headerSection {
  background: #f9f1d6;
  padding: 1.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
}

.invoice {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerItem h2 {
  color: var(--Gray-50, #5f6d7e);
  font-family: Fraunces;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.012px;
}

.headerItem p {
  color: var(--app-dark);
  font-family: Fraunces;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.014px;
}

.orderDetails {
  margin-top: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.orderDetails h2 {
  color: var(--app-dark);
  font-family: Fraunces;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.disputeReason {
  margin-top: 1.75rem;
}

.disputeReason h2 {
  font-family: Butler;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.001em;
  text-align: left;
  color: var(--app-dark);
}

.disputeForm {
  display: flex;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.disputeForm h2 {
  font-family: Fraunces;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.001em;
  text-align: left;
  color: var(--app-dark);
}

.bookName,
.reason {
  flex: 1;
}

.dropdown select {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  font-family: Fraunces;
}

.dropdown select:focus {
  outline: none;
}

.dropdown {
  display: flex;
  font-family: Fraunces;
  border: 1px solid var(--app-grey);
  border-radius: 10px;
  padding: 0.5rem;
  background: var(--app-white);
  margin-top: 1rem;
}

.dropdown select {
  border: none;
  border-radius: 0;
  flex: 1;
  font-family: Fraunces;
  padding: 8px;
}

.dropdown input {
  border: none;
  flex: 2;
  font-family: Fraunces;
}

.disputeImages {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 2rem;
}

.disputeImages h2 {
  font-family: Butler;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.001em;
  text-align: left;
  color: var(--app-dark);
}

.disputeImages p {
  font-family: Fraunces;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.10000000149011612px;
  text-align: left;
  color: #5f6d7e;
}

.disputeImages h3 {
  font-family: Fraunces;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  text-align: left;
  color: var(--app-primary);
}

.additionalText {
  margin-top: 2rem;
}

.additionalText label {
  font-family: Fraunces;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.001em;
  text-align: left;
  color: var(--app-dark);
}

.additionalMessage {
  margin-top: 0.5rem;
  border: 1px solid var(--app-grey);
  background: var(--app-white);
  border-radius: 12px;
}

.submitBtn {
  width: 11rem;
  margin-top: 1.5rem;
}

.main {
  width: 80vw;
  height: 70vh;
  padding: 0.5rem;
}

.fileInput {
  display: none;
}

.uploadLabel,.disabledUploadLabel {
  font-family: Fraunces;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  text-align: left;
  color: var(--app-primary);
  cursor: pointer;
}

.disabledUploadLabel {
    opacity: 0.5;
}

.uploadLabel:hover {
  color: #6C142E
}

.imagePreview {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.previewImage {
  width: 80px; /* Set the desired width for the preview images */
  height: 80px;
  object-fit: cover;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd; /* Optional border for the images */
  border-radius: 5px; /* Optional rounded corners */
}

.previewContainer {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.previewImage {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
}

.removeIcon {
  position: absolute;
  top: 5%;
  right: 15%;
  cursor: pointer;
  font-size: 0.875rem;
  background-color: white;
  border-radius: 50%;
  padding: 2px;
}



@media screen and (max-width: 560px) {
  .headerSection {
    flex-wrap: wrap;
  }

  .formBody {
    padding: 0.5rem;
  }

  .main {
    width: 100%;
    height: 70vh;
    padding: 0;
  }

  .title h2 {
    font-size: 1.5rem;
  }
  
}
