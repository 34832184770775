.mainContainer{
    background: var(--app-light-grey);
    padding:2rem;
    border-radius: 12px;
}

.mainContainerInactive{
    background: var(--app-white);
    border: 1px solid var(--app-light-grey);
    border-radius: 12px;
    padding: 2rem;
}

.headerSection{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--app-dark);
}

.headerSectionInActive{
    border: none;
    display: flex;
    justify-content: space-between;
}

.headerSectionInActive button{
    color: var(--app-primary);
    background:transparent;
    border: none;
    box-shadow: none;
}

.headerSection h2,.headerSectionInActive h2{
    font-family: Fraunces;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--app-dark);
}

.headerSection p,.headerSectionInActive p{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--app-grey);
}



.btnContainer{
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
}

.btnContainer button{
    width: 11.25rem;
}

.radioContainer {
    display: flex;
    align-items: flex-start;
    margin-top: 1.5rem;
  }
  
  .addressCard input[type="radio"] {
    accent-color: var(--app-primary);
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: 5px;
    cursor: pointer;
  }
  
  .addressLabel {
    font-size: 14px;
    color: #333;
  }
  
  .addressLabel p {
    margin: 0;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  
  .radioContainer {
    display: flex;
    align-items:center;
  }
  
  .radioContainer input[type="radio"] {
    accent-color: var(--app-primary);
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: 5px;
    cursor: pointer;
  }
  
  .addressLabel {
    font-size: 14px;
    color: #333;
    margin: 0;
  }
  
  .addressLabel p {
    margin: 0;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  .otherAddress h2 {
    font-family: Fraunces;
    font-size: 1.175rem;
    font-weight: 400;
    color: var(--app-grey);
  }

  .shippingAddressMap {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    max-width: 100%; /* Ensures grid container respects the parent's width */
    gap: 10px; /* Optional: spacing between grid items */
  }
  
  @media (min-width: 600px) {
    .shippingAddressMap {
      grid-template-columns: repeat(4, 1fr); /* Fixes maximum columns to 4 */
    }
  }

  @media screen and (max-width: 586px) {
    .mainContainer{
      padding: 1rem;
    }
  }