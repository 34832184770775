.title {
    height: 2.625rem;
    border-bottom: 1px solid var(--app-grey);
    font-family: "Butler";
    font-size: 1.25rem;
    font-weight: 700;
}

.container {
    background-color: #F6F6F6;
    margin-top: 1rem;
    border-radius: 0.5rem;
    padding: 2rem;
    min-height: calc(100dvh - 4rem);
}

.balance {
    display: flex;
    justify-content: center;
    padding-bottom: 1.875rem;
    gap: 1rem;
    align-items: center;
}

.walletIcon {
    width: 2.25rem;
    height: 2.25rem;
}

.walletIcon>img {
    width: 100%;
    height: 100%;
}

.totalAvailable {
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
    font-family: "Butler";
}

.totalAvailable>div:first-child {
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--app-grey);
}

.totalAvailable>div:last-child {
    font-size: 1.125rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 0.125rem;
}

.totalAvailable>div>span {
    font-size: 1rem;
}

.heading{
    background-color: var(--app-white);
    padding: 1rem 2rem;
    border-radius: 0.375rem;
    font-family: "Butler";
    font-weight: 600;
    font-size: 1rem;
}

.tableContainer{
    max-height: calc(100dvh - 20rem);
    background-color: var(--app-white);
    margin-top: 1.5rem;
    border-radius: 0.5rem;
}

.tableContainer > div {
    height: calc(100dvh - 18rem);
}

.table>thead>tr {
    background-color: var(--app-black);
}

.table>thead>tr>th{
    color: var(--app-white);
    font-family: "Butler";
}

.table>tbody>tr>td{
    font-weight: 500;
    color: var(--app-black);
    font-family: "Fraunces";
}

.tableMain{
    border-radius: 0.5rem;
}

.transaction{
    font-weight: 500;
    font-family: "Fraunces";
    color: var(--app-dark-green);
}

.debited {
    color: var(--app-red);
}

.dateContainer{
    text-wrap: nowrap;
}

@media screen and (max-width: 784px) {
    .container {
        padding: 1rem 0.125rem;
    }
}

@media screen and (max-width: 556px) {
    .container {
        padding: 1rem 0.125rem;
    }
}