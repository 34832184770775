.ctaContainer {
    background: var(--app-light-pink);
    width: 100%;
    color: var(--app-black);
    font-family: Butler;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 2rem 1rem;
    border-radius: 0.75rem;
    margin: 0 auto;
    text-align: center;
    max-width: 800px; /* Limit max width for large screens */
}

.textContent {
    flex: 1;
    max-width: 660px;
    width: 100%;
}

.textContent h2 {
    font-family: Butler;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.3;
    margin: 0;
}

.textContent p {
    font-family: Fraunces;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
    margin-top: 0.5rem;
    color: var(--app-black);
}

.ctaButton {
    width: 100%;
    max-width: 600px;
    margin-top: 1rem;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .ctaContainer {
        margin: 0;
        width: 92%;
    }
}

@media (max-width: 768px) {
    .ctaContainer {
        padding: 1.5rem 1rem;
        border-radius: 0.5rem;
    }

    .textContent h2 {
        font-size: 1.75rem;
        line-height: 1.2;
    }

    .textContent p {
        font-size: 0.9rem;
        line-height: 1.3;
    }
    
    .ctaButton {
        margin-top: 1rem;
        width: 90%; /* Reduce width slightly for better fit */
    }
}

@media (max-width: 480px) {
    .ctaContainer {
        padding: 1rem;
    }

    .textContent h2 {
        font-size: 1.5rem;
        line-height: 1.1;
    }

    .textContent p {
        font-size: 0.85rem;
        line-height: 1.2;
    }

    .ctaButton {
        width: 100%;
        margin-top: 0.75rem;
    }
}
