.ctaContainer {
    background-color: #25292C;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3.375rem;
    border-radius: 0.75rem;
    margin-top: 5rem;
    margin-left: 8.25rem;
    margin-right: 8.25rem;
}

.textContent {
    flex: 1;
}

.textContent h2 {
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 47px;
    text-align: left;
    
}

.textContent p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #ffffff;
}

.ctaButton {
    margin-left: 2rem;
}

.ctaButton button {
    color: #ffffff;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.73px;
    text-align: center;

    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}


@media (max-width: 768px) {
    .ctaContainer {
        flex-direction: column;
        text-align: center;
    }
    
    .ctaButton {
        margin: 1rem 0 0 0;
    }
}

@media (max-width:768px) {
    .ctaContainer{
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 0rem;
        margin-top: 2.5rem;
        padding: 1rem 1.5rem;
    }

    .textContent h2{
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 0.5rem;
    }

    .textContent p{
        font-size: 1rem;
    }
}
