.bannersContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    height: auto;
    padding: 2rem;
    gap: 32px;
}

.leftBanner {
    padding: 0px;
    border: 1px solid #ccc;
    border-radius: 0.75rem;
    flex: 2;
    width: 100%;
    height: 450px;
    overflow: hidden;
    position: relative;
    overflow: hidden; /* Hide overflow content */
    width: 100%; /* Adjust to fit your design */
}
.leftBanner {
    padding: 0;
    border: 1px solid #ccc;
    border-radius: 0.75rem;
    width: 100%;
    height: 450px;
    overflow: hidden;
    position: relative;
}

.carouselWrapper {
    display: flex; /* Ensure items are in a row */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    transition: 1s ease-in;
}

.carousel {
    width: 100%; /* Each item takes up 100% of the container width */
    height: 100%; /* Full height to match container */
    flex: 0 0 100%; /* Prevent items from shrinking or growing */
    box-sizing: border-box; /* Include padding and border in width and height calculations */
}

.rightBanner {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 0.75rem;
    background: #25292C;
    flex: 0.5;
    gap: 1.5rem;
    align-items: center;
}

.imageContainerLeft {
    flex: 1;
    position: relative;
    height: 450px;
}

.imageContainerLeft img {
    width: 100%;
    height: 450px;
    position: relative;
    right: -120px;
}

.top_text {
    color: #ffffff;
    text-align: center;
    /* margin-bottom: 1rem; */
}

.top_text h2 {
    font-size: 2rem;
    font-family: Butler;
    font-weight: bold;
    margin: 0;
}

.top_text p {
    font-size: 1rem;
    font-family: Franunces;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
}

.imageContainerRight {
    position: relative;
    height: 17.625rem;
    width: 21rem;
}

.Button {
    display: flex;
    background-color: #ffffff;
    width: 150px;
    height: 49px;
    margin-top: 38px;
    padding: 10px;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around;
}

.oldPrice {
    color: #A5ADB0;
}

.newPrice {
    color: #110D06;
}

.section {
    padding: 20px;
    box-sizing: border-box;
    background: #EFF1D9;
    color: #fff;
    margin-top: 20px;
    margin: 20px 132px;
    border-radius: 12px;
}

.mainSection {
    margin-top: 1.5rem;
}

.ctaDiv {
    background: #25292C;
    padding: 1.25rem 1rem;
}

.ctaDiv .mainHeader {
    font-family: Butler;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    text-decoration-skip-ink: none;
    color: var(--app-white);
}
.ctaDiv button {
    margin-top: 1.5rem;
}

@media (min-width:1024px){
    .singleBanner{
        max-width: 85%;
        margin: 0 auto;
    }
}

@media (max-width: 1024px) {

    .bannersContainer{
        flex-direction: column;
    }

    .leftBanner, .rightBanner {
        width: 100%;
        height: 100%;
    }

    .rightBanner {
        margin: 1.5rem auto 2.75rem;
        width: 95%;
        padding: 0;
        border: none;
        background: none;
    }

    .leftBanner {
        margin-top: 1.5rem;
    }


    .top_text h1 {
        font-size: 20px;
    }

    .top_text p {
        font-size: 10px;
    }
    .section {
        margin: 2.5rem 0px 0px;
    }

    .imageContainerRight{
        height: 24rem;
        width: 32rem;
    }
    
}

@media (max-width: 768px) {
    .top_text h1 {
        font-size: 20px;
    }

    .top_text p {
        font-size: 10px;
    }

    .bannersContainer {
        padding: 1rem;
        gap: 16px;
    }

    .top_text h1 {
        font-size: 18px;
    }

    .top_text p {
        font-size: 8px;
    }

    .Button {
        width: 120px;
        height: 40px;
        padding: 8px;
    }

    .leftBanner{
        height: 100%;
        padding: 0;
    }

    .rightBanner {
        gap: 0rem;
        margin: 1.5rem auto 0;
        width: 100%;
    }

    .imageContainerRight{
        height: 26rem;
        width: 35rem;
    }
}

@media (max-width: 576px) {
    .leftBanner {
        height: 250px; /* Adjust height for mobile devices */
    }

    .imageContainerRight{
        height: 24rem;
        width: 32rem;
    }

}

@media (max-width: 480px) {
    .top_text h1 {
        font-size: 16px;
    }

    .top_text p {
        font-size: 8px;
    }

    .Button {
        width: 100px;
        height: 35px;
        padding: 6px;
    }

    .imageContainerRight{
        height: 20rem;
        width: 30rem;
    }

}

@media (max-width: 400px) {
    .imageContainerRight{
        height: 18.5rem;
        width: 26rem;
    }
}


@media (max-width: 360px) {
    .imageContainerRight{
        height: 16rem;
        width: 21rem;
    }
}

@media (max-width: 1200px) {
    .leftBanner {
        height: 400px; /* Adjust height for smaller screens */
    }
}

@media (max-width: 992px) {
    .leftBanner {
        height: 350px; /* Adjust height for tablets */
    }
}

@media (max-width: 768px) {
    .leftBanner {
        height: 400px; /* Adjust height for smaller tablets and larger phones */
    }
}

@media (max-width: 480px) {
    .leftBanner {
        height: 300px; /* Adjust height for smaller tablets and larger phones */
    }
}

