.primaryButton {
    width: 100%;
    display: flex;
    padding: 0.875rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--app-primary);
    color: var(--app-white);
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.5px;
    text-align: center;
    outline: none;
    border-radius: 0.5rem;
    border: none;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    min-width: max-content;
}

.primaryButton:hover{
    background-color: #6C142E;
}

.disabled{
    opacity: 0.5;
}

.disabled:hover{
    background-color: var(--app-primary);
}

@media (max-width:576px) {
    .primaryButton {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.25rem;
    }
}