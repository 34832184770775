/* Main container styling */
.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.5rem;
  padding: 1rem; /* Added padding to prevent content from touching the edges on small screens */
  height: 70dvh;
  overflow: auto;
}

@media (min-width:1024px) and (max-height:800px) {
  .mainContainer {
    height: 65dvh;
    overflow: auto;
  }
}

.details {
  background: var(--app-light-grey);
  padding: 1.5rem; /* Adjusted padding for responsiveness */
  flex-grow: 1;
}

/* Header section styling */
.headerSection {
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #D0D4D7;
  margin-bottom: 2rem;
}

/* Header title styling */
.headerSection h2,.headerSectionBack h2 {
  font-family: Butler;
  font-size: 2rem; /* Changed from 30px for better scaling */
  font-weight: 700;
  line-height: 1.2; /* Adjusted line height for better readability */
  color: var(--app-dark);
  margin-bottom: 0.5rem; /* Adjusted margin for spacing */
}
.headerSectionBack {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  position: relative; /* For absolute positioning of FontAwesomeIcon */
  margin-bottom: 0.75rem;
}

.headerSectionBack h2 {
  flex: 1; /* Takes up available space */
  text-align: center; /* Centers the text horizontally */
  margin-bottom: 0;
}

.headerSectionBack .iconContainer {
  position: absolute;
  left: 0; /* Position icon at the left */
}
.sectionController {
    position: fixed;
    bottom: 0;
    width: 560px;
    background: var(--app-white);
    border-radius: 8px;
    padding: 45px 25px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  
  /* Section controller styling */
  .sectionController {
    position: fixed;
    bottom: 0;
    max-width: 500px; /* Maximum width to ensure it doesn’t stretch too much on large screens */
    background: var(--app-white);
    border-radius: 8px;
    padding: 2rem; /* Adjusted padding for better spacing */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .optionSection{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    gap:0.5rem;
}

.detailsSmall{
  background: var(--app-white);
}

.inVisible {
  position: fixed;
  top: 0;
  opacity: 0;
  pointer-events: none;
}
  
@media(max-width:1024px){
  .mainContainer {
    padding: 0rem; /* Added padding to prevent content from touching the edges on small screens */
    height: fit-content;
  }

  .optionSection{
    display: initial;
}

  .details {
    background: var(--app-white);
    padding: 0rem; /* Adjusted padding for responsiveness */
  }
}

@media(max-width:768px){
  .headerSection h2,.headerSectionBack h2{
    font-size: 20px;
  }
}