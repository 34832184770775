.footerAction {
    display: flex;
    align-items: center;
    gap: 1rem;
}

@media (min-width:1024px) {
    .container {
        width: 30dvw;
    }
}