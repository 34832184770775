.mainContainer {
    border: 1px solid #E4E7E9;
    margin-top: 1.5rem;
}

.itemCount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.625rem 1.5rem;
}

.itemCount h3 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--app-primary-dark);
}

.itemCount p {
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.012em;
    text-align: left;
    color: var(--app-claret);
}

.cartTableWrapper {
    overflow-x: auto; /* Enable horizontal scroll */
}

.cartTable {
    width: 100%;
    border-collapse: collapse;
}

.cartTable th, .cartTable td {
    padding: 0.625rem 1.5rem;
    text-align: left;
    vertical-align: middle;
}

.cartTable thead {
    background: #EFF1D9;
    color: #475156;
}

.cartTable th {
    white-space: nowrap; /* Prevent wrapping */
}

.cartTable td {
     /* Prevent wrapping */
    
}

.backBtn {
    display: flex;
    align-items: center;
    color: var(--app-claret);
    gap: 0.5rem;
}

.backBtn p {
    padding: 1.5rem;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.012em;
    text-align: left;
    color: var(--app-claret);
}

.product{
    display: flex;
    gap: 0.75rem;
}

.productImage{
    width: 88px;
    height: 68px;
    border-radius: 8px;
}
 
.productImage img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
}

.productDetails{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.productDetails h2{
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgb(25, 28, 31);
    margin-bottom: 0;
}

.productDetails p{
    font-family: Fraunces;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #6C7275;
}

.qtyController{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.5rem 0rem;
}

.qtyController h2{
    margin-bottom: 0;
    font-family: Public Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.qtyController p{
    font-size: 1.25rem;
}

.price p,.subTotal p, .customization p{
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #191C1F;
}

.customization {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-top: 1.25rem;
}

.customization h3{
    margin: 0;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--app-claret);
}
