/* PrivacyPolicy.module.css */

.policyContent {
    height: fit-content;
    margin: var(--container-margin);
    background: linear-gradient(180deg, rgba(159, 120, 0, 0) -5.09%, rgba(249, 241, 214, 0.288213) 4.75%, rgba(255, 253, 246, 0.3) 15.41%, rgba(254, 251, 241, 0.295622) 43.77%, rgba(254, 251, 241, 0.295492) 43.78%, rgba(251, 246, 232, 0.287324) 63.75%, rgba(247, 240, 218, 0.274648) 81.34%, rgba(239, 226, 189, 0.249296) 99.99%, rgba(159, 120, 0, 0) 100%);
    border-radius: 0.75rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s ease-out forwards;
}

/* Animation */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Heading Styles */
.heading {
    color: #0F0B05;
    font-family: "Butler", serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    position: relative;
    padding-bottom: 0.75rem;
    animation: fadeInUp 1.2s ease-out forwards;
}

.heading::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: var(--app-claret);
    border-radius: 2px;
    animation: growLine 0.6s ease-in-out forwards;
}

@keyframes growLine {
    0% {
        width: 0;
    }
    100% {
        width: 50px;
    }
}

/* Subheading Styles */
.subHeading {
    font-size: 20px;
    font-weight: 600;
    color: #495057;
    line-height: 1.8;
    padding-bottom: 0.4rem;
    animation: fadeInUp 1.4s ease-out forwards;
}

.subHeading::after {
    content: "";
    display: block;
    width: 40px;
    height: 2px;
    background-color: var(--app-claret);
    border-radius: 1px;
    margin-top: 0.5rem;
}

/* Paragraph Styles */
.paragraph {
    font-size: 16px;
    font-family: "Fraunces", sans-serif;
    font-weight: 400;
    line-height: 28px;
    color: #333;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1.6s ease-out forwards;
    animation-delay: 0.3s;
    text-align: justify;
}


/* Responsive Styles */
@media (max-width: 768px) {
    .policyContent {
        padding: 1.5rem;
        gap: 1.5rem;
    }

    .heading {
        font-size: 24px;
        line-height: 32px;
    }

    .heading::after {
        width: 40px;
    }

    .subHeading {
        font-size: 18px;
    }

    .subHeading::after {
        width: 35px;
    }

    .paragraph {
        font-size: 14px;
        line-height: 24px;
    }
}

@media (max-width: 480px) {
    .policyContent {
        padding: 1rem;
        gap: 1rem;
    }

    .heading {
        font-size: 20px;
        line-height: 28px;
    }

    .heading::after {
        width: 35px;
    }

    .subHeading {
        font-size: 16px;
    }

    .subHeading::after {
        width: 30px;
    }

    .paragraph {
        font-size: 13px;
        line-height: 22px;
    }
}
