.counterContainer {
    display: flex;
    align-items: center;
    border: 2px solid var(--app-claret);
    border-radius: 8px;
    padding: 5px 10px;
    background-color: #ffffff;
}

.button {
    background-color: var(--app-light-grey);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: var(--app-claret);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #e0e0e0;
}

.count {
    font-size: 18px;
    margin: 0 15px;
    color: #000000;
}

@media (max-width: 600px) {
    .counterContainer {
        padding: 3px 8px;
    }

    .button {
        width: 30px;
        height: 30px;
        font-size: 16px;
    }

    .count {
        font-size: 16px;
        margin: 0 10px;
    }
}
