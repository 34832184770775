.main {
    padding: .75rem 1.25rem;
    /* border-bottom: 1px solid var(--border-light-grey); */
    background-color: var(--app-white);
}

.mainHidden {
    padding: .75rem 4rem;
    /* border-bottom: 1px solid var(--border-light-grey); */
    background-color: var(--app-white);
}

.container {
    display: flex;
    gap: 1.25rem;
    align-items: center;
}

.exclusiveBtn {
    padding: .25rem 1rem !important;
    background: linear-gradient(102.6deg, #A22A4E 40.87%, #FF5286 174.9%) !important;
}

@media (max-width:1200px) {
    .main,.mainHidden {
        padding: 1rem;
        height: 100%;
        width: 100%;
    }

    .container {
        flex-direction: column;
        align-items: flex-start;
    }
}