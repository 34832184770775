.bookCustomization {
    padding: 2rem;
    background-color: #ffffff;
    margin: 5rem var(--container-margin);
}

.header {
    width: 100%;
    max-width: 660px;
    margin: 0 auto;
    margin-bottom: 2.25rem;
}

.header h2{
  font-family: Butler;
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  margin-bottom: 0.5rem;
}

.header p {
  font-family: Fraunces;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-bottom: 1.25rem;
}

.customizationTypes {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Ensure vertical alignment */
    justify-content: center;
    margin-top: 1rem;
}

.customizationTypes h3 {
    font-size: 1.5rem;
    font-weight: 600;
}

.buttonGroup {
    display: flex;
    gap: 1.5rem;
}

.button {
    padding: 0.75rem 1.5rem;
    border-radius: 100px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.claret {
    background: var(--app-claret);
    color: #ffffff;
}

.outline {
    background-color: white;
    color: var(--app-claret);
    border: 0.1rem solid var(--app-claret);
}

.outline:hover {
    background-color: var(--app-light-pink);
}

.deluxeVariant {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 2rem;
    background: var(--app-light-pink);
    margin-bottom: 2rem;
    padding-left: 3.375rem;
    padding-right: 3.375rem;
    justify-content: space-evenly;
    margin-bottom: 0;
}

.content {
  flex: 1;
    margin-left: 7.25rem;
    max-width: 490px;
    margin-bottom: 3.375rem;
}

.content h2{
  font-family: Butler;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  text-align: left;

}

.content p{
    padding:0;
    margin-bottom: 3.375rem;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin-top: 0.675rem;
}

.titles {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem; /* Add gap between columns */
}

.title {
    width: calc(50% - 1rem); /* Adjust width and subtract gap */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.title h3 {
  font-family: Butler;
  font-size: 24px;
  font-weight: 500;

  text-align: left;
  color: var(--app-dark);
}

.title p {
    margin-top: 0.5rem;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin-bottom: 0;
}

.customizationOptions {
    padding: 2rem;
    background: var(--app-light-pink);
}

.videoSection{
  width: 100%;
  background: var(--app-light-pink);
}

.videoContainer {
  background: var(--app-light-pink);
    display: flex;
    justify-content: center;
    align-items: center;
}

.videoContainer video {
  width: 90%;
  object-fit: cover;
}


.heading{
    margin-left: 3.375rem;;
    margin-bottom: 0.75rem;
    font-family: Butler;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: left;

}

.description{
  font-family: Fraunces;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;  
    width: 614px;
    margin-left: 3.375rem;
    padding: 0;
    margin-bottom: 2rem;
}

.option {
    padding: 1rem;
    background: var(--app-light-pink);
    text-align: center;
    border: 1px solid #dddddd;
}

.imageContainer {
  width: 24.25rem;
  height: 24.25rem;
  border-radius: 50%; /* Makes the container circular */
  overflow: hidden; /* Ensures the image does not overflow out of the container */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .imageContainer {
    width: 18rem;
    height: 18rem;
  }
}

/* For screens smaller than 1024px (tablets and mobile devices) */
@media (max-width: 1023px) {

  .bookCustomization{
      padding: 1.5rem;
  }

  .buttonGroup {
      display: flex;
      gap: 1rem;
  }


  .customizationTypes h3{
      font-size:0.75rem;
      margin-right: 1.5rem;
  }
  
  .button {
      padding: 0.5rem 1rem;
      border-radius: 20px;
      border: none;
      cursor: pointer;
      font-size: 0.75rem;
      transition: background-color 0.3s ease;
  }

  .header {
    font-size: 24px;
    line-height: 36px;
  }

  .header p {
    font-size: 12px;
    line-height: 18px;
  }

  .deluxeVariant {
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
    text-align: center;
    gap: 1rem;
  }


  .content {
    margin-left: 0;
    text-align: center;
    margin-bottom: 0;
  }

  .titles {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .titles .title {
    width: 100%;
  }

  .customizationOptions {
    padding: 2rem 1rem;
  }

  .carousel {
    width: 100%;
  }

  .card {
    width: 100%;
    height: auto;
    padding: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;
  }
  
  .cardImage {
    width: 5rem;
    height: 5rem;
    margin-right: 1rem;
  }

  .cardContainer {
    padding: 0.4rem;
    width: 100%;
  }

  .heading {
    margin-left: 1rem;
  }

  .description {
    width: 100%;
    margin-left: 0;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 1rem;
  }
}


@media (max-width: 768px) {
  .header h2{
    font-size: 32px;
  }
  .header p{
    font-size: 1rem;
  }
  .imageContainer {
    width: 15rem;
    height: 15rem;
  }

  .content p{
    margin-bottom: 0.5rem;
}

.titles {
  margin-top: 1.5rem;
  gap: 0.5rem;
}

.bookCustomization {
  margin-top: 2.5rem;
}
}

@media (max-width: 480px) {
  .imageContainer {
    width: 10rem;
    height: 10rem;
  }
}

.image {
  max-width: 90%;
  max-height: 90%;
  object-fit: fill; /* Ensures the image covers the entire container */
}


.carousel {
    width: 90%;
    margin: 0 auto;
    gap: 1rem;
    display: flex;
  }

.cardContainer{
    padding: 5px;
}
  
  .card {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    border: 0.0625rem solid var(--app-claret);
    border-radius: 0.5rem;
    text-align: left;
    background-color: white;
    /* width: 25.25rem; */
    height: max-content;
    margin-right: 0.5rem;
  }

  
  .cardImage {
    object-fit: contain;
    width: 8rem;
    width: 8rem;
    border-radius: 0.5rem;
    margin-right: 1rem;
  }
  
  .text {
    flex: 1;
  }
  
  .cardTitle {
    font-family: Butler;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    
    margin-bottom: 0.5rem;
  }
  
  .cardDescription {
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    
  }

  @media(max-width:460px){
    .bookCustomization {
        margin-top: 2.5rem;
        padding: 0;
      }
    .header h2{
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -0.02em;
      text-align: center;
    }

    .header p{
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.001em;
      text-align: center;
    }

    .card {
      width: 100%;
      flex-direction: column;
      padding: 1rem 0.5rem;
    }

    .cardTitle {
      text-align: center;
    }

  }