.mainContainer {
    width: 100%;
}

.faqContainer {
    margin: 20px 5%;
    margin-top: 0;
    background: var(--app-light-grey);
}

.cardContainer {
    display: flex;
    padding: 3rem 2.25rem;
    flex-direction: column;
    gap: 1.25rem;
    justify-content: center;
    width: 100%;
}

.tabs {
    margin: 4rem 5% 12px;
    padding: 12px 16px 1.5rem;
    position: relative;
}

.tab {
    padding: 1rem 2rem;
    cursor: pointer;
    margin-right: 0.5rem;
    background-color: #ffffff;
    transition: background-color 0.3s ease;
    border-radius: 6.25rem;
    white-space: nowrap;
    color: #25292C;
    text-align: center;
    font-family: Fraunces;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    border: 1px solid #6D8A91;
}

.tab:hover {
    background-color: #ccc;
}

.tab.active {
    background-color: #6D8A91;
    color: #fff;
    color: #FFF;
    text-align: center;
    font-family: Fraunces;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    border: 1px solid var(--app-dark);
}

.tabs::-webkit-scrollbar {
    display: none;
}

.rightArrow,.leftArrow {
    border-radius: 50%;
    background: var(--app-white);
    color: var(--app-dark);
    border: 1px solid var(--app-dark);
    position: absolute;
    top:30%;
}

.rightArrow {
    right: -5%;
}

.leftArrow {
    left: -5%;
}

@media (max-width: 768px) {
    .tab {
        padding: 10px 20px;
        font-size: 14px;
    }

    .tab.active {
        font-size: 14px;
    }

    .cardContainer {
        gap: 1rem;
    }

    .headerSection {
        width: 100%;
    }

    .description {
        padding: 0 1rem;
        width: 100%;
        font-size: 12px;
        margin-bottom: 1rem;
    }

    .buttonContainer {
        margin-top: 1rem;
    }

    .rightArrow {
        right: -7%;
    }

    .leftArrow {
        left: -7%;
    }
}

@media (max-width: 600px) {
    .Slider {
        :global .slick-slider {
            display: flex;
            justify-content: center;
            width: 75%;
        }

        width: 75%;
        margin-left: auto;
        margin-right: auto;
    }
    .headerSection {
        margin-bottom: 3.5rem;
    }

    .faqContainer {
        margin: 20px 2%;
    }


    .cardContainer {
        padding: 1rem 1.25rem;
    }

    .tabs {
        justify-content: flex-start;
        overflow-x: auto;
        margin: 0rem 0% 12px;
        padding: 3.5rem 2px 1.5rem;
        /* width: 75%; */
        margin-left: auto;
        margin-right: auto;
    }

    .tab {
        padding: 8px 16px;
        font-size: 12px;
        margin-right: 0;
    }

    .tab.active {
        font-size: 12px;
    }

    .leftArrow,.rightArrow {
        top: 10%;
    }

    .leftArrow {
        left: -12%;
    }

    .rightArrow {
        right: -12%;
    }


}

@media (max-width : 420px) {
    .leftArrow {
        left: -12%;
    }

    .tabs {
        margin: 0rem 0% 12px;
        padding: 3.5rem 0px 1.5rem;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width : 320px) {
    .leftArrow {
        left: -12%;
    }
}
