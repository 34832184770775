.tabs {
    display: flex;
    max-width: 100%;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    /* overflow: auto; */
  }
  
  .tab {
    padding: 1rem 2rem;
    cursor: pointer;
    margin-right: 0.5rem;
    background-color: #ffffff;
    transition: background-color 0.3s ease;
    border-radius: 0.5rem;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    border: 1px solid #D0D4D7;
    white-space: nowrap;
  }

  .tabs::-webkit-scrollbar {
    display: none;
  }
  
  .tab:hover {
    background-color: #ccc;
  }
  
  .tab.active {
    background: #6D8A91;
    color: #fff;
    font-weight: 600;
  }

  @media screen and (max-width: 1284px) {
    .tabs{
      flex-wrap: wrap;
      row-gap: 1rem;
      justify-content: flex-start;
    }
  }

  @media(max-width:1024px){
    .tabs{
      overflow: auto;
      justify-content: center;
    }
  }
  
  @media (max-width: 768px) {
    .tab {
      padding: 0.5rem 1rem;
      margin-right: 0.25rem;
      font-size: 0.875rem;
    }
  }
  
  @media (max-width: 600px) {
    .tabs{
      column-gap: 0.25rem;
      row-gap: 0.625rem;
    }

    .tab {
      padding: 0.5rem 1rem;
      font-size: 0.75rem;
      width: fit-content;
    }
  }