/* Main container styling */
.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.5rem;
  padding: 1rem; /* Added padding to prevent content from touching the edges on small screens */
}

/* Header section styling */
.headerSection {
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #D0D4D7;
  margin-bottom: 2rem;
}

/* Header title styling */
.headerSection h2,.headerSectionBack h2 {
  font-family: Butler;
  font-size: 2rem; /* Changed from 30px for better scaling */
  font-weight: 700;
  line-height: 1.2; /* Adjusted line height for better readability */
  color: var(--app-dark);
  margin-bottom: 0.5rem; /* Adjusted margin for spacing */
}

.headerSectionBack {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  position: relative; /* For absolute positioning of FontAwesomeIcon */
  margin-bottom: 0.75rem;
}

.headerSectionBack h2 {
  flex: 1; /* Takes up available space */
  text-align: center; /* Centers the text horizontally */
  margin-bottom: 0;
}

.headerSectionBack .iconContainer {
  position: absolute;
  left: 0; /* Position icon at the left */
}

/* Header paragraph styling */
.headerSection p {
  color: #666666;
  font-family: Fraunces;
  font-size: 1rem; /* Changed from 16px for better scaling */
  font-weight: 400;
  line-height: 1.5; /* Adjusted line height for better readability */
}

/* Book container styling */
.bookContainer {
  height: 100%;
  width: 100%;
  padding: 1rem; /* Reduced padding for responsiveness */
  background-color: #25292C;
}

/* Details section styling */
.details {
  background: var(--app-light-grey);
  padding: 1.5rem; /* Adjusted padding for responsiveness */
  flex-grow: 1;
  height: 75vh;
  overflow: auto;
}

@media (min-width:1024px) and (max-height:800px) {
  .details {
    height: 65dvh;
    overflow: auto;
  }
}

/* Customization body styling */
.customizationBody {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  flex-grow: 1;
  padding-bottom: 5rem; /* Adjusted padding to avoid overlap with the section controller */
}

/* Section controller styling */
.sectionController {
  position: fixed;
  bottom: 0;
  left: 12%;
  width: 70%;
  max-width: 520px; /* Maximum width to ensure it doesn’t stretch too much on large screens */
  background: var(--app-white);
  border-radius: 8px;
  padding: 2rem; /* Adjusted padding for better spacing */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.drawer{
  margin-bottom: 10rem;
}

.OptionCard{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}

.inVisible {
  position: fixed;
  top: 0;
  opacity: 0;
  pointer-events: none;
}


@media(max-width:1024px){
  /* Main container styling */
.mainContainer {
  padding: 0rem; /* Added padding to prevent content from touching the edges on small screens */
}
  .sectionController{
    position: static;
    margin-top: 1.5rem;
    width: calc(100% + 2 * 24px);
    margin-left: -24px; /* Shift left to cover padding */
    margin-right: -24px; /* Shift right to cover padding */
    padding:12px;
    box-sizing: border-box; /* Include padding and border in width */
    max-width: 100dvw;
  }

  .sectionController button{
    font-size: 12px;
    padding: 0.5rem;
  }

  .detailsNew{
    background: var(--app-white);
  }

  .details {
    background: var(--app-white);
    padding: 0; /* Adjusted padding for responsiveness */
    height: fit-content;
  }

  .customizationBody {
    padding-bottom: 0rem; /* Adjusted padding to avoid overlap with the section controller */
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .headerSection h2,.headerSectionBack h2 {
    font-size: 1.5rem; /* Adjusted for smaller screens */
  }

  .headerSection p {
    font-size: 0.875rem; /* Adjusted for smaller screens */
  }

  .sectionController {
    padding: 1rem; /* Reduced padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .headerSection h2,.headerSectionBack h2 {
    font-size: 1.25rem; /* Further adjustment for very small screens */
  }

  .headerSection p {
    font-size: 0.75rem; /* Further adjustment for very small screens */
  }

  .sectionController {
    padding: 0.75rem; /* Reduced padding for very small screens */
  }
}
