.mainContainer {
    background: linear-gradient(102.6deg, #A22A4E 40.87%, #FF5286 174.9%);
    padding: 55px 60px;
}

.headerSection {
    width: 100%;
    max-width: 676px;
    margin: 0 auto;
}

.headerSection h2 {
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    margin-bottom: 0.5rem;
    color: #FFF3E9;
}

.headerSection p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #FFF3E9;
}

.cardSection {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-top: 2rem;
}

.card {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    background: var(--app-white);
    align-items: center;
    padding: 1.5rem 2rem 2.25rem;
    border-radius: 0.75rem;
    max-width: 27rem;
}

.title {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.title h2 {
    font-family: Fraunces;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: var(--app-dark);
}

.title p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.001em;
    text-align: center;
    color: #5F6D7E;
}

.linkBtn {
    text-align: left;
    text-decoration: underline;
    border-bottom: none;
}

@media (max-width: 1024px) {
    .cardSection {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 768px) {
    .mainContainer {
        padding: 40px 30px;
    }

    .cardSection {
        grid-template-columns: 1fr;
    }

    .headerSection h2 {
        font-size: 32px;
        line-height: 44px;
    }

    .headerSection p {
        font-size: 14px;
        line-height: 18px;
    }

    .card {
        padding: 1rem 1.5rem 2rem;
    }

    .title h2 {
        font-size: 18px;
    }

    .title p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media (max-width: 480px) {
    .mainContainer {
        padding: 30px 15px;
    }

    .headerSection h2 {
        font-size: 28px;
        line-height: 40px;
    }

    .headerSection p {
        font-size: 12px;
        line-height: 16px;
    }

    .card {
        padding: 0.75rem 1rem 1.5rem;
    }

    .title h2 {
        font-size: 16px;
    }

    .title p {
        font-size: 12px;
        line-height: 18px;
    }
}
