.socialButton {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color:#ffffff;
    border: 1px solid #D1D9E2;
    /* color: var(--app-primary); */
    color: #ffffff;
    font-family: var(--inter);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    outline: none;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    min-width: max-content;
}

.socialButton img{
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}