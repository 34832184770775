.container{
    display: flex;
    justify-content: space-between;
    gap: 6rem;
    margin-top: 4rem;
}

.confirmation {
  font-size: 1.25rem;
  font-weight: 600;
  font-family: Butler;
}

.actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1rem;
}

.details{
    flex: 1;
    width: 100%;
    max-width: 522px;
}

.details h2{
    color:var(--app-black) ;
    font-family: Butler;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 13px;
}

.details p{
    color: var(--app-grey);
    font-family:Fraunces;
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    padding: 0;
}

.email{
    margin-top: 24px;
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    color:#110D06;
}

.email h3{
    color: #110D06;
    font-family: Butler;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.001em;
    text-align: left;

}

.email p{
  font-family: Fraunces;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #110D06;
}

.addresses{
  font-family: Butler;
  display: flex;
  gap: 1.25rem;
  color: #110D06;
    margin-top: 32px;
    width: 250px;
}

.addresses h3{
  font-family: Butler;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
}

.addresses p{
  padding: 0;
  margin: 0;
  font-family: Fraunces;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #110D06;
}

.formSection{
    width: 100%;
    max-width: 628px;
    flex:1.5;
    background: #F8F9FB;
    padding: 32px;
    font-family: Fraunces;
}


.formGroup {
    margin-bottom: 1.5rem;
    font-family: Fraunces;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--app-grey);
    font-family: Fraunces;
    font-size: large;
  }
  
  .formGroup input,
  .contactNumber select {
    width: 100%;
    padding: 0.75rem;
    background: #FFFFFF;
    border: 1px solid var(--app-grey);
    border-radius: 4px;
    font-family: Fraunces;
  }

  .formGroup textarea{
    background-color: #FFFFFF;
    border: 1px solid var(--app-grey);
    border-radius: 5px;
    font-size: large;
    font-family: Fraunces;
  }

  .contactNumber {
    display: flex;
    font-family: Fraunces;
    border: 1px solid var(--app-grey);
    border-radius: 10px;
    padding: 0.5rem;
    background: var(--app-white);
  }
  
  .contactNumber select {
    border: none;
    border-radius: 0;
    flex: 1;
    font-family: Fraunces;
    font-size: large;
    padding: 8px;
    border-right: 1px solid var(--app-grey);
    cursor: pointer;
  }
  
  .contactNumber input {
    border: none;
    flex: 2;
    font-family: Fraunces;
  }
  
  .options {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    font-family: Fraunces;
  }
  
  .options input[type="checkbox"] {
    margin-right: 0.5rem;
    font-family: Fraunces;
  }
  
  .options label {
    margin-bottom: 0.5rem;
  }

  .submitBtn{
    width: 100%;
    font-family: Fraunces;
  }

  .socialMediaBtns{
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 32px;
  }

  .socialMediaBtns p{
    color: var(--app-black);
    font-family: Butler;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.001em;

  }

  .socialBtns{
    display: flex;
    flex-direction: row;
    gap: 16px;
    
  }

  .socialMediaBtn button{
    height: fixed(36px);
    width: 36px;
    padding: 0;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }

  .contactNumber select {

  
    /* Style */
    width: 100%;
    max-width: 400px;
    padding: 0.75rem;
    border-right: 1px solid #9F9F9F;
  
    /* Arrow */
    appearance: none;
    background-repeat: no-repeat;
    background-image: url(/public/Assets/Icons/caret-down-solid.svg);
    background-position: right 0.7rem top 50%;
    background-size: 0.65rem auto;
  }

  .contactNumber select:focus{
    
    outline: none;
  }

  .footerIcons{
    font-size: 25px;
    cursor: pointer;
  }
  .footerIcons:hover{
    transform: scale(1.1);
  }

  @media (max-width: 1200px) {
    .container {
      padding: 32px;
    }
  
    .details,
    .formSection {
      width: 100%;
      max-width: none;
    }
    
    .header h1 {
      padding: 64px 192px;
      font-size: 36px;
      line-height: 50px;
    }
  }
  
  @media (max-width: 992px) {
    .container {
      padding: 24px;
    }
  
    .header h1 {
      padding: 48px 96px;
      font-size: 32px;
      line-height: 40px;
    }
  
    .details h2 {
      font-size: 32px;
      line-height: 36px;
    }
  
    .details p,
    .email h3,
    .socialMediaBtns p {
      font-size: 16px;
      line-height: 20px;
    }
  
    .formSection {
      padding: 24px;
    }
  
    .formGroup label {
      font-size: 14px;
    }
  
    .formGroup input,
    .contactNumber select,
    .formGroup textarea {
      padding: 0.5rem;
    }
  
    .contactNumber select,
    .contactNumber input {
      flex: 1;
      margin-right: 0.5rem;
    }
  
    .contactNumber input {
      flex: 2;
    }
  
    .submitBtn {
      max-width: 100%;
      width: 100%;
    }
  
    .socialBtns {
      gap: 8px;
    }
  
    .socialMediaBtn button {
      height: 30px;
      width: 30px;
    }

    .container{
      flex-direction: column;
      gap: 1.25rem;
      padding: 0;
    }
  }
  
  @media (max-width: 768px) {
    .container{
      flex-direction: column;
      gap: 1.25rem;
      padding: 0;
    }
    .header h1 {
      padding: 32px 48px;
      font-size: 28px;
      line-height: 36px;
    }
  
    .details p,
    .email h3,
    .socialMediaBtns p {
      font-size: 12px;
      line-height: 18px;
    }
  
    .formSection {
      padding: 16px;
    }
  
    .formGroup label {
      font-size: 12px;
    }
  
    .formGroup input,
    .contactNumber select,
    .formGroup textarea {
      padding: 0.5rem;
    }
  
    .submitBtn {
      max-width: 100%;
      width: 100%;
    }
  
    .socialBtns {
      gap: 8px;
    }
  
    .socialMediaBtn button {
      height: 30px;
      width: 30px;
    }
  }
  
  @media (max-width: 576px) {
    .header h1 {
      padding: 16px 24px;
      font-size: 24px;
      line-height: 32px;
    }

  
    .details p,
    .email h3,
    .socialMediaBtns p {
      font-size: 15px;
      line-height: 16px;
    }
  
    .formSection {
      padding: 8px;
    }
  
    .formGroup label {
      font-size: 16px;
    }
  
    .formGroup input,
    .contactNumber select,
    .formGroup textarea {
      padding: 0.5rem;
    }

    .contactNumber select{
      font-size: 14px;
      text-wrap: wrap;
      /* background-image: none; */
    }
  
    .submitBtn {
      max-width: 100%;
      width: 100%;
    }
  
    .socialBtns {
      gap: 8px;
    }
  
    .socialMediaBtn button {
      height: 24px;
      width: 24px;
    }

    .socialMediaBtns p{
      font-size: 16px;
    }
  }