.mainContainer {
    margin:var(--container-margin);
    margin-top: 1.5rem;
    margin-bottom: 0;
    padding: 0 44px;
    display: flex;
    flex-direction: column;
    gap: 3.125rem;
    padding: 64px 54px 32px;
}


.header {
    width: 100%;
    max-width: 982px;
    margin: 0 auto;
}

.header h2{
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    margin-bottom: 0.5rem;
}

.header p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.cardContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1.5rem; /* Add gap between cards */
}

.videoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    padding: 1rem 2rem;
    margin-right: 1rem;
    cursor: pointer;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-family: Fraunces;
    font-size: 15px;
    font-weight: 600;
    line-height: 18.5px;
    text-align: center;

  }

  .buttons{
    display: flex;
    gap: 1rem;
    justify-content: center;
  }

  .buttons .button{
    width: 211px;
    height: 50px;
    padding: 16px 24px;
    gap: 10px;
    border-radius: 8px;
  }

    
  .button.outline {
    background-color: white;
    color: var(--app-claret);
    border: 0.1rem solid var(--app-claret);
  }
  
  .button.outline:hover {
    background-color: #e6ffe6;
  }

/* Responsive adjustments */
@media (max-width: 1024px) {
    .mainContainer {
        padding: 20px 32px;
        gap: 2rem;
    }

    .header h2{
        font-size: 32px;
    }

    .header p {
        font-size: 16px;
    }

    .cardContainer {
        gap: 1rem;
    }
}

@media (max-width: 768px) {
    .mainContainer {
        padding: 0 24px;
        gap: 1.5rem;
    }

    .header h2{
        font-size: 24px;
    }

    .header p {
        font-size: 16px;
    }

    .cardContainer {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
}

@media (max-width: 480px) {
    .mainContainer {
        padding: 0;
        gap: 1rem;
    }

    .cardContainer {
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
    }

    .header p{
        font-size: 16px;
    }
}

@media (max-width:576px){
    .header h2{
        line-height: 30px;
    }
}
