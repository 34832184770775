.countryDropdown label {
  color: #3d3d3d;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-transform: capitalize;
}

.countryDropdown {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
}

.mainContainer {
  display: flex;
  gap: 0.5rem;
  font-family: Fraunces;
  border: 1px solid #ccc;
  background: var(--app-white);
  height: 2.5rem;
  border-radius: 0.25rem;
}

.mainContainer select {
  border: none;
  border-radius: 0;
  width: 100%;
  font-family: Fraunces;
  padding: 8px;
  cursor: pointer;
}

.mainContainer input {
  border: none;
  flex: 2;
  font-family: Fraunces;
  border-left: 1px solid var(--app-grey);
  border-radius: 0;
}

.mainContainer select:focus,
.mainContainer input:focus {
  outline: none;
}

.mainContainer select:focus {
  outline: none;
}

.flag {
  display: inline-block;
  width: 20px;
  height: 14px;
  margin-right: 8px;
  background-size: cover;
  vertical-align: middle;
}
