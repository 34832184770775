/* Base styles remain the same */

.mainContainer {
    margin: var(--container-margin);
    background: linear-gradient(102.6deg, #A22A4E 40.87%, #FF5286 174.9%);
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 3.125rem;
    padding: 84px 76px;
    border-radius: 0.75rem;
}

.header {
    width: 100%;
    max-width: 676px;
    margin: 0 auto;
}

.header h2 {
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    margin-bottom: 0.5rem;
    color: #FFF3E9;
}

.header p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #FFF3E9;
}

.cardSection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 18px;
    column-gap: 18px;
    justify-items: center;
}

.card {
    width: 100%;
    height: 108px;
    padding: 24px 32px;
    gap: 24px;
    border-radius: 5px;
    background: var(--app-white);
    box-shadow: 0px 1px 2px 0px #1018280D;
}

.card h3 {
    font-family: Fraunces;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.01em;
    text-align: center;
    color: var(--app-dark);
}

.card:hover{
    transition: 0.1s ease-in;
    transform: scale(1.05);
}

.imageSection {
    margin: 1.5rem auto 0;
    width: 100%;
    height: 100%;
    max-width: 952px;
    max-height: 632px;
    border-radius: 12px;
}

.imageSection img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Responsive styles */

@media (max-width: 1200px) {
    .mainContainer {
        padding: 70px 60px;
    }

    .cardSection {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .imageSection{
        width: 90%;
    }
}

@media (max-width: 992px) {
    .mainContainer {
        padding: 60px 40px;
    }

    .cardSection {
        grid-template-columns: 1fr 1fr;
    }

    .card {
        width: 100%;
        max-width: 280px;
    }

    .imageSection {
        max-width: 800px;
        max-height: 500px;
    }
}

@media (max-width: 768px) {
    .mainContainer {
        padding: 50px 20px;
    }

    .cardSection {
        grid-template-columns: 1fr;
        row-gap: 16px;
    }

    .card {
        width: 100%;
        max-width: 300px;
    }

    .imageSection {
        max-width: 600px;
        max-height: 400px;
    }
}

@media (max-width: 576px) {
    .header h2 {
        font-size: 28px;
        line-height: 36px;
    }

    .header p {
        font-size: 14px;
        line-height: 18px;
    }

    .card {
        width: 100%;
        max-width: 280px;
        padding: 16px;
    }

    .card h3 {
        font-size: 18px;
        line-height: 26px;
    }

    .imageSection {
        max-width: 100%;
        max-height: 300px;
    }
}
