.mainContent {
    display: flex;
    justify-content: space-between;
    padding: 5rem;
  }

.contentContainer{
    display: flex;
    margin-left: auto;
    margin-right: auto;
}
  
  .customizationContent {
    flex: 1;
    margin-right: 10rem;

  }

  .customizationContent h3 {
    font-family: Fraunces;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--app-dark-gold);
  }
  
  .customizationContent h2 {
    font-size: 2rem;
    margin-top: 0.5rem;
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 45px;
    text-align: left;

  }
  
  .customizationContent p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-top: 1rem;
    color: var(--app-grey);
  }
  
  .buttons {
    display: flex;
    margin-top: 1rem;
    gap: 1.25rem;
  }
  
  .button {
    text-align: center;
    padding: 16px 20px;
  }
  
  
  .button.outline {
    background-color: white;
    color: var(--app-claret);
    border: 0.1rem solid var(--app-claret);
  }
  
  .button.outline:hover {
    background-color: #e6ffe6;
  }
  
  .bookCover{
    flex: 1;
    width: 30%;
    height: fit-content;
    border-radius: 12px;
    width: 20.5rem;
    height: 27rem;
    border: 1px 0px 0px 0px;
    /* top: -400px; */
  }
  
  .bookCover img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    gap: 0px;
    border-radius: 0.75rem;
  }
  
/* Responsive styles */
@media (max-width: 1024px) {
    .mainContent {
      padding: 2rem;
      flex-direction: column;
      align-items:center;
    }
  
    .customizationContent {
      margin-right: 0;
      text-align: left;
    }
  
    .buttons {
      margin: 1rem auto 0;
    }
  
    .bookCover {
      width: 20.5rem;
      height: 27rem;
    }
  }
  
  @media (max-width: 768px) {

    .contentContainer{
      flex-direction: column;
      height: fit-content;
      align-items: center;
      justify-content: center;
    }

    .customizationContent h3 {
      font-size: 0.875rem;
      line-height: 1rem;
      margin-bottom: 0.375rem;
      text-align: center;
    }

    .customizationContent h2 {
      font-size: 1.5rem;
      line-height: 1.875rem;
      margin-top: 0;
      text-align: center;
    }
  
    .button {
      padding: 0.75rem 1.5rem;
    }

    .bookCover{
      display:flex;
      justify-content: center;
      width: 15rem;
      height: 20rem;
    }

    .customizationContent p {
      font-size: 1rem;
      text-align: center;
    }

    .buttons {
      margin-top: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .mainContent {
      padding: 1rem;
    }

    .contentContainer{
      flex-direction: column;
    }
  
    .button {
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
    }
  }