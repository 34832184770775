.modalOverlay {
 padding: 0.75rem 0.25rem;
}

.modalContent {
  background: #fff;
  border-radius: 8px;
  width: 40dvw;
  padding: 0 1rem;
}

.modalContent h2 {
  font-family: Butler;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-bottom: 2rem;       
}

.uploadArea {
  margin-bottom: 20px;
}

.uploadBox {
  border: 2px dashed var(--app-claret);
  border-radius: 4px;
  padding: 40px;
  text-align: center;
  margin-bottom: 10px;
}

.uploadBox .fileInput {
  display: none;
}

.uploadLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.uploadIcon {
  font-size: 24px;
  margin-bottom: 10px;
}

.uploadText {
font-family: Fraunces;
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: left;
color: var(--app-dark);
}

.modalOverlay p {
  font-size: 14px;
  color: #666666;
}

.modalOverlay span {
  font-weight: bold;
}

.sampleImageSection {
  border-top: 1px solid #d0d4d7;
  padding-top: 20px;
}

.sampleImageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.sampleImage {
  font-weight: bold;
}

.sampleLogo {
  width: 10rem;
  height: 10rem;
  margin: 1rem auto;
}

.sampleLogo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.recommendedButton {
  background:#97851E;
  border: none;
  color: #fff;
  padding: 8px 12px;
  border-radius: 12px;
  cursor: pointer;
}

.sampleDetails {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.lineArt, .fileFormat, .fileSize {
  display: flex;
  gap: 10px;
}

.circle {
  width: 36px !important;
  height: 36px !important;
  background: var(--app-light-grey);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle img{
  width : 34px;
  height: 34px;
  object-fit: contain;
}

.sampleDetails h3 {
  font-family: Butler;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;
  color: var(--app-dark);
  margin-bottom: 0.5rem;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
  margin-top: 20px;
}

.disclaimer {
  font-size: 12px;
  color: #666666;
}

.buttons {
  width: 100%;
  display: flex;
  gap: 10px;
}

.uploadButton {
}

.cancelButton {
  width: 100%;
  border:1px solid var(--app-claret);
  border-radius: 8px;
}

.detailsContent{
  width: 90%;
}

@media (max-width : 768px) {
  .modalContent {
    width: 80dvw
  }
  
}