.mainContainer {
  overflow-x: hidden;
  margin-top: 5rem;
}

.galleryContainer {
  border-radius: 0.75rem;
  width: 100%;
  overflow: hidden;
}

.headerSection {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  margin-bottom: 1rem;
}

.header h1 {
  font-family: Butler;
font-size: 36px;
font-weight: 700;
line-height: 48px;
text-align: center;
margin-bottom: 1rem;
  padding: 0 0.75rem;
}

.description {
  width: 38.1875rem;
  height: 2.5rem;
  margin-left: auto;
  margin-right: auto;
}

.description p {
  font-family: Fraunces;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-align: center;
  margin: 0 2rem;
}

.tabs {
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 0.625rem;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.tab {
  padding: 1rem 2rem;
  cursor: pointer;
  margin-right: 0.5rem;
  background-color: #ffffff;
  border-radius: 100px;
  border: 1px solid #6D8A91;
  transition: background-color 0.3s ease;
  border-radius: 6.25rem;
  white-space: nowrap;
  width: 15rem;
}

.tabs::-webkit-scrollbar {
  display: none;
}

.tab p{
  overflow: auto;
  font-family: Fraunces;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
}

.tab:hover {
  background-color: #ccc;
}

.tab.active {
  background-color: #6D8A91;
  color: #fff;
}

.tab.active p{
  color: #fff;
}

.carouselContainer{

  :global .slick-list{
    padding-top: 4rem !important;
  }

  :global .slick-dots{
    padding: 1.5rem !important;
  }

}

.slick-track{
  top:4rem
}

.cardContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13.75rem;
  height: 28.25rem;
}

.slide{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13.75rem;
  height: 18.25rem;
  object-fit: fill;
}

.centerSlide{
  transform: scale(1.2);
}

.slide img{
  width: 13.75rem;
  height: 18.25rem;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .mainContainer{
    margin-top: 0;
  }
  .galleryContainer {
    margin-top: 3rem;
    border-radius: 0.75rem;
  }

  .header {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .description {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
  }

  .carouselContainer {
    padding: 1rem 0 0;
    min-height: 25rem;
  }
}

/* Mobile View */
@media (max-width: 800px) {
  .mainContainer{
    margin-top: 0;
  }
  .galleryContainer {
    margin-top: 2.5rem;
    border-radius: 0.75rem;
    width: 100%;
  }

  .header h1{
    font-size: 2rem;
    line-height: 2rem;
  }

  .description {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  .cardContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .slide {
    margin-left: auto;
    margin-right: auto;
  }

  .tabs{
    overflow: visible;
    flex-wrap: wrap;
    justify-content: center;
  }

  .tab {
    padding: 0.75rem 1.5rem;
    width: 13rem;
  }
  
}

@media (max-width: 576px){
  .carouselContainer{
    :global .slick-list{
      padding-top: 2rem !important;
    }
  }
  .cardContainer{
    height: auto;
  }
}


@media(max-width:480px) {
  .header h1{
    font-size: 2rem;
    line-height: 2.25rem;
  }

  .tab p{
    font-size: 0.875rem;
  }
}

@media (max-width:420px){
  .description p {
    margin: 0 1.25rem;
  }
}


