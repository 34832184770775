:root {
    font-size: 16px; /* Ensures 1rem = 16px */
}

.banner {
    background-image:url(/public/Assets/Icons/background-image.svg);
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    padding: 3.375rem;
    border-radius: 0.75rem;
    margin:4rem auto 0;
    max-width: 80%;
    overflow: hidden;
}

.banner h2 {
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 47px;
    text-align: left;
    margin: 0;
    margin-bottom: 0.5rem;
    text-align: left;
    width: 100%;
    max-width: 47.625rem; /* 762px / 16 = 47.625rem */
    height: 5.875rem; /* 94px / 16 = 5.875rem */
}

.banner p {
    font-family: Fraunces;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
    margin-bottom: 3rem;
    color: #ffffff;
    width: 100%;
}

.statistics {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 53rem; /* 856px / 16 = 53.5rem */
    height: 5.625rem; /* 90px / 16 = 5.625rem */
    row-gap: 7.3125rem; /* 117px / 16 = 7.3125rem */
}


.stat {
    flex: 1;
    text-align: left;
}

.stat h3 {
    font-family: Fraunces;
    font-size: 3rem;
    font-weight: 700;
    line-height: 59.18px;
    text-align: left;
    
    color: #FFC107; /* Adjust the color as needed */
    margin-bottom: 0.5rem;
}

.statLabel {
    line-height: 1.25rem; /* 20px / 16 = 1.25rem */
    margin: 0 auto;
}

.stat p {
    font-family: Fraunces;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #fff;
}

/* Mobile View */
@media (max-width: 768px) {
    .banner {
        padding: 1rem 1.25rem; /* Decrease padding */
        text-align: center; /* Center align text */
        width: 100%;
        height: 26rem;
        margin-top: 2.5rem;
    }

    .banner h2 {
        font-size: 2rem; /* Decrease font size */
        margin-bottom: 1rem; /* Decrease bottom margin */
        line-height: 2rem;
    }

    .banner p {
        font-size: 1rem; /* Decrease font size */
        margin-bottom: 1rem; /* Decrease bottom margin */
    }

    .statistics {
        width: 100%; /* Full width */
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
    }

    .stat {
        /* flex: 1 1 100%; Full width */
        max-width: 100%; /* Full width */
    }

    .stat h3 {
        font-size: 1.5rem; /* Decrease font size */
        margin-bottom: 0.5rem; /* Decrease bottom margin */
    }

    .stat p {
        font-size: 0.875rem; /* Decrease font size */
    }
}

/* Tablet View */
@media (min-width: 769px) and (max-width: 1024px) {
    .banner {
        max-width: 90%; /* Adjust maximum width */
    }

    .statistics {
        justify-content: center; /* Center items horizontally */
    }
    
    .statistics::-webkit-scrollbar{
        display: none;
    }
    
    .stat::-webkit-scrollbar{
        display: none;
    }
    .stat {
        flex: 1; /* Two items per row */ /* Two items per row */
        margin: 0.5rem; /* Adjust margin */
    }
}

@media (max-width:480px){
    .banner h2 {
        font-size: 1.5rem; /* Decrease font size */
        margin-bottom: 1rem; /* Decrease bottom margin */
        line-height: 1.75rem;
    }

    .banner p {
        font-size: 1rem; /* Decrease font size */
        margin-bottom: 0rem; /* Decrease bottom margin */
    }

}
