.main {
    width: 13.875rem;
    background-color: var(--app-light-pink);
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.375rem;
    align-items: center;
    position: relative;
    justify-content: space-between;
}

.image {
    width: 12.25rem;
    height: 16.5rem;
    margin-bottom: 1rem;
}

.image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
}

.icon {
    font-size: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right:10% ;
    top: 6%;
    background-color: white;
    border-radius: 50%;
    padding: 0.375rem;
    z-index: 1;
    cursor: pointer;
}

.icon img{
    width: 100%;
    height: 100%;
}

.title {
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    margin-bottom: 0.375rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    overflow: hidden;
    text-overflow: ellipsis;
}



.author {
    font-size: 0.75rem;
    font-weight: 500;
    width: 100%;
}
.cart {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
}

.cart>div:first-child {
       font-size: 1rem;
       font-weight: 600;
}

.button {
    height: 1.875rem;
    font-size: 0.875rem;
    font-weight: 500;
}

.unavailable {
    opacity: 0.75; /* Dim the entire card */
    position: relative;
}

.unavailableBanner {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black */
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    padding: 0.5rem 0;
    transform: translateY(-50%);
    z-index: 2;
    border-radius: 0.375rem;
}

@media (max-width : 450px) {
    .main{
        width: 10.5rem;
    }
    .image{
        width: 9rem;
        height: 14rem;
    }
}
