.buttonLink {
  border: none;
  outline: none;
  background: none;
  font-size: 1rem;
  color: var(--app-primary);
  font-family: Fraunces;
  font-size: 16px;
  font-weight: 500;
    line-height: 18.5px;
    text-align: center;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  border-bottom: 1px solid var(--app-primary);
}

.buttonLink:hover{
  color: #6C142E;
}

.disabled{
  opacity: 0.5;
}

.disabled:hover{
  background-color: var(--app-primary);
}
