.main {
  width: 30vw;
  height: 15dvh;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.radio {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.actions > button {
  width: 6rem;
  padding: 0.75rem;
}

input[type="radio"] {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
}

.modal {
    padding: 2rem;
}

.radio > label {
    cursor: pointer;
    font-weight: 500;
}