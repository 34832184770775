.mainContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;
    width: 100%;
}

.mainContainer button{
    padding: 10px 18px;
}

@media(max-width:460px) {
    .mainContainer button{
        padding: 2px 8px;
    }
}