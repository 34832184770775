.mainContainer{
    border: 1px solid #C7C7C7;
    border-radius: 12px;
    background: var(--app-white);
    padding: 24px;
    margin-top: 1.5rem;
}

.headerSection{
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #D0D4D7;
    margin-bottom: 2rem;
}

.headerSection h2{
    font-family: Butler;
    font-size: 30px;
    font-weight: 700;
    line-height: 34px;
    text-align: left;
    color: var(--app-dark);
    margin-bottom: 4px;
}

.headerSection p{
    color: #666666;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.dedicationPageOptions{
    display: flex;
    align-items: center;
    gap: 1.25rem;
    margin-top: 1rem;
}

.ColorOption, .outerBorder{
    border: 1px solid #C7C7C7;
    border-radius: 12px;
    background: var(--app-white);
    padding: 24px;
}
.colorHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.dropDownImage{
    width: 32px;
    height: 32px;
    background: #E9E9E9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.ColorOption p{
    color: #64615D;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

}

.selectColor {
    margin-top: 1rem;
}

.selectColor h2 {
    font-family: Butler;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    color: var(--app-black);
}

.colors {
    margin-top: 12px;
    display: flex;
    gap: 0.625rem;
    flex-wrap: wrap;
}

.colorPicker {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    cursor: pointer;
}

.selected {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 2px solid var(--app-black);
    display: flex;
    align-items: center;
    justify-content: center;
}

.active {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid var(--app-white);
}

.colorPickerDropdown h3{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.001em;
    text-align: left;
    color: #5F6D7E;
}

.tabSection{
    display: flex;
    gap:1rem;
    margin-top: 1rem;
}

.tab,.tabActive{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--app-grey);
    border-radius: 12px;
    padding: 10px;
}

.tabActive{
    background: var(--app-grey);
}

.tabActive h2{
    color: #fff;
}

.price{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
    background: #FFF3E9;
    border-radius: 12px;
    margin-top: 1.125rem;
}

.price p{
    font-family: Butler;
    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    text-align: left;
    color: var(--app-dark);
}

.price h2{
    font-family: Butler;
    font-size: 16px;
    font-weight: 700;
    line-height: 16.8px;
    text-align: left;
    color: var(--app-dark);
}

.noPaper p {
    margin-top: 1.5rem;
    text-align: center;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #666666;
}