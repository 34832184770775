.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.tableContainer {
  flex: 1;
  max-height: 100%;
  overflow-y: auto;
}

.main table {
  width: 100%;
  border-spacing: 0;
  flex: 1;
  background-color: var(--app-white);
  border-radius: 0.5rem;
  border-collapse: collapse;
  font-family: var(--inter);
}

.thead > tr {
  background-color: var(--app-white);
  border-bottom: 1px solid var(--border-light-grey);
  position: sticky;
  top: 0;
  z-index: 1;
}

.main tr:not(:last-child) {
  border-bottom: 1px solid var(--border-light-grey);
}

.main td,
.main th {
  font-size: 0.875rem;
  color: var(--app-grey);
}

.main td {
  padding: 1rem 1.375rem;
  vertical-align: middle;
  font-weight: 500;
  text-wrap: pretty;
}

.main th {
  color: var(--app-grey);
  padding: 1rem 1.375rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.125rem;
  letter-spacing: -0.00069rem;
  vertical-align: top;
}

.row {
  font-weight: normal;
}

/* .row td:first-child {
     text-align: center; 
} */

.main th {
  text-align: left;
}

/* .main th:first-child {
 text-align: center; 
} */

.bookImageContainer{
  display: flex;
  justify-content: center;
}

.photoUpload{
  display: none;
}

.photoUploadButton{
  border: 1px solid #000000;
  font-family: var(--inter);
  font-size: 15px;
  color: #000000;
  cursor: pointer;
  width: 100%;
}

.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  font-weight: 500;
}

.actionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.pageNo {
  margin: 0 0.75rem;
  font-size: 0.875rem;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.errorRow {
  color: var(--app-warning);
}

.errorRow td {
  color: var(--app-danger);
  text-align: center;
}

.tableWrapper {
  margin-top: 1.5rem;
  width: 100%;
}

.collapseAbleRow {
  cursor: pointer;
}

.cardTable {
}

@media (max-width: 650px) {
  .cardTable {
  }

  .cardTable > .thead > tr > th:not(.collapseCell) {
    display: none;
  }

  .cardTable > tbody > tr > td:not(.collapseCell) {
    display: block;
    padding: 0.5rem 1rem;
  }

  .cardTable > tbody > tr > td:first-child {
    padding-top: 1rem;
  }

  .cardTable > tbody > tr > td:last-child {
    padding-bottom: 1rem;
  }

  .cardTable > tbody > tr > td:not(.collapseCell)::before {
    content: attr(data-cell) ": ";
    font-weight: 700;
    text-transform: capitalize;
  }
}
