  .profileForm label{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.73px;
    text-align: left;

  }

  .customDropdown {
    width: 50%;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
  
  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .name input{
    background: #ffffff;
    border: 1px solid #5F6D7E;
    border-radius:0.3rem;
    padding: 0.5rem 1.25rem;
  }
  
  .column + .column {
    margin-left: 2rem;
  }
  
  .profileForm label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .inputWithButton {
    display: flex;
    align-items: flex-end;
    border-radius: 0.3rem;
    background: #ffffff;
  }
  
  .inputWithButton input {
    flex: 1;
    padding: 0.5rem 1.25rem;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
  }

  .dob{
    width: 48%;
    height: fit-content;
    overflow: hidden;
  }

  .inputWithButton input,.dateInput{
    background: var(--app-white);
  }

  .dateInput:disabled,.inputWithButton input:disabled{
    background:var(--app-light-grey);
  }

  /* .dob input{
    background: #ffffff;
    border-radius:0.3rem;
    border: none;
    padding: 0.5rem 1.25rem;
    font-family: var(--inter);
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 22px;

  } */
  
  .changeButton {
    padding: 0.5rem 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
    font-family: Fraunces;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.001em;
    text-align: left;
    color: var(--app-claret);
    display: flex;
    align-items: center;      
  }

  
  .changePasswordTitle {
    font-size: 1.25rem;
    margin: 2rem 0 1rem 0;
    color: #333;
  }

  .submitBtn{
    width: 10.6rem;
  }

  .phoneEmailDiv {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .contactNumber{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
  }

  .contact{
    width: 100%;
    display: flex;
    border: 1px solid #5F6D7E;
  }
  .contact select {
    padding: 10px;
  }

  .label {
    color: #3d3d3d;
    font-size: 0.875rem;
    font-weight: 500;
  }

  @media(max-width:1024px){
    .password{
        display: flex;
        flex-direction: column;
    }
  }
  
  @media (max-width: 768px) {
    .row {
        flex-direction: column;
        gap: 1rem;
    }

    .column + .column {
        margin-left: 0;
    }

    .name input, .dob input, .inputWithButton input {
        font-size: 0.875rem; /* Decreased font size */
    }

    .changeButton {
        padding: 0.5rem 1rem; /* Decreased padding */
        font-size: 0.875rem; /* Decreased font size */
    }
}

/* Media Queries for Mobile (max-width: 576px) */
@media (max-width: 576px) {
    .profileForm {
        padding: 1rem 0;
    }

    .row {
        flex-direction: column;
        gap: 1rem;
    }

    .column + .column {
        margin-left: 0;
    }

    .changeButton {
      padding: 0.5rem 0.125rem;
      font-size: 0.75rem;
    }
  
    .dob{
        width: 100%;
    }

    .submitBtn {
        width: 100%;
    }

    .inputWithButton input {
      flex: 1;
      padding: 0.625rem 0.875rem;
      height: 2.25rem;
    }

    .dateInput {
      padding: 0.5rem 0.75rem !important;
    }
}

@media (max-width:500px) {
  .customDropdown {
    width: 75%;
  }
}

@media (max-width:420px) {
  .customDropdown {
    width: 95%;
  }
}