.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--app-white);
  padding: 1rem 1.5rem;
  gap: 1rem;
}

.btn {
  border: none;
  outline: none;
  background: none;
  color: #000;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: var(--inter);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: -0.00088rem;
}

.btn:disabled {
  color: var(--app-grey);
}

.btn:disabled path {
  stroke: var(--app-grey);
}

.pagination{
  display: flex;
}

.pagination ul {
  display: flex;
  /* flex-wrap: wrap; */
  margin: 0;
  padding: 0;
}

.pagination ul li {
  list-style: none;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination ul li.numb {
  list-style: none;
  color: var(--app-grey);
  margin: 0 3px;
  line-height: 45px;
  border-radius: 50%;
  border-radius: 0.3125rem;
  background: #fff;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0rem 1rem;
  justify-content: center;
  align-items: center;
  font-family: var(--inter);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: -0.00088rem;
  transition: all 0.5s ease-in-out;
}

.pagination ul li.numb.first {
  margin: 0px 3px 0 -5px;
}

.pagination ul li.numb.last {
  margin: 0px -5px 0 3px;
}

.pagination ul li.dots {
  font-size: 22px;
  cursor: default;
  color: var(--app-grey);
}

.pagination li.active,
.pagination ul li.numb:hover,
.pagination ul li:first-child:hover,
.pagination ul li:last-child:hover {
  color: var(--app-black) !important;
  background: var(--app-primary-light) !important;
}