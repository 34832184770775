.input {
    width: 100%;
    background-color: var(--input-background-color);
    color: black;
    border: none;
    border-radius: 0.25rem;
    padding-left: 1rem;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-size: large;
}

.input::placeholder {
    color: #6d6c6c;
}

.input:focus {
    outline: none;
}

.OTPInput {
    width: 3rem;
    height: 3rem;
    background: var(--input-background-color);
    border-radius: 50%;
    border: none;
    text-align: center;
    outline: none;
}

.OTPInput[value]:not([value=""]),
.OTPInput:focus {
    width: 3rem;
    height: 3rem;
    background: var(--heading-dark-color);
    border-radius: 50%;
    border: none;
    text-align: center;
    outline: none;
    color: white;
}