/* .main{
  overflow: auto;
  height: 100%;
  width: 100%;
} */

.home {
    width: 100%;
    height: 100vh; /* Set to viewport height */
    overflow-y: auto; /* Enable vertical scrollbar when content overflows */
  }


.mainContent{
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.tabSection{
  height:fit-content;
  margin-inline: var(--container-margin);
  background: #F6F6F6;
  border-radius: 0.75rem;
  margin-top: 7rem;

}

.tabContent{
  /* padding: 7rem 5.4rem;
  margin-bottom: 80px; */
}

.RBExclusiveContainer{
  border-radius: 0.75rem;
  margin: 5rem var(--container-margin) 0;
  background-image: url("/public/Assets/Images/RBBackground.svg");
  padding: 1.75rem;
  background-repeat: no-repeat;
  background-size: cover;
  height:fit-content
}

.bookCustomizationContainer{
  border-radius: 0.75rem;
  margin:5rem var(--container-margin);
  margin-bottom: 0;
}

.featuredCollectionContainer{
  border-radius: 0.75rem;
  margin-inline: var(--container-margin);
}

.communityBanner{
  transform: translateY(4rem);
}


@media (max-width: 768px) {
  .tabSection {
    margin-left: 2rem;
    margin-right: 2rem;
    width: calc(100% - 4rem);
  }

  .RBExclusiveContainer{
    border-radius: 0.75rem;
    margin: 2.5rem var(--container-margin) 0;    
    padding: 1rem 1.25rem;
  }

  .bookCustomizationContainer{
    margin:2.5rem var(--container-margin) 0;
  }
  
}

@media (max-width: 480px) {
  .tabSection {
    margin: 2rem auto;
    width: 70%;
    height: auto; /* Allow height to adjust based on content */
  }

  .tabContent{
    width: 100%;
  }


  .bookCustomizationContainer{
    max-width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .featuredCollectionContainer{
    max-width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media (max-width: 420px) {
  .RBExclusiveContainer{
    margin: 2.5rem var(--container-margin) 0;    
  }
}