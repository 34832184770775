.headerSection{
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #D0D4D7;
    margin-bottom: 2rem;
}

.headerSection h2{
    font-family: Butler;
    font-size: 30px;
    font-weight: 700;
    line-height: 34px;
    text-align: left;
    color: var(--app-dark);
    margin-bottom: 4px;
}

.headerSection p{
    color: #666666;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.ColorOption, .outerBorder{
    border: 1px solid #C7C7C7;
    border-radius: 12px;
    background: var(--app-white);
    padding: 24px;
}
.colorHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.dropDownImage{
    width: 32px;
    height: 32px;
    background: #E9E9E9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.ColorOption p{
    color: #64615D;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

}

.selectColor {
    margin-top: 1rem;
}

.selectColor h2 {
    font-family: Butler;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    color: var(--app-dark);
    margin-bottom: 0.5rem;

}

.colors {
    margin-top: 12px;
    display: flex;
    gap: 0.625rem;
    flex-wrap: wrap;
}

.colorPicker {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    cursor: pointer;
}

.selected {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 2px solid var(--app-black);
    display: flex;
    align-items: center;
    justify-content: center;
}

.active {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid var(--app-white);
}

.colorPickerDropdown h3{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.001em;
    text-align: left;
    color: #5F6D7E;
}

.fontOptions{
    flex: 1;
    min-height: 1.75rem;
    border:1px solid #C7C7C7;
    outline: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    padding:0.875rem 0.5rem;
    background: url("/public/Assets/Icons/caret-down-solid.svg");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: calc(100% - 0.5rem) center !important;
    -webkit-appearance: none;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease; /* Smooth transition for height and other styles */
}

.fontOptions:focus {
    outline: none; /* Remove default focus outline */
}

.optionSpan{
    display: flex;
    justify-content: space-between;
    background: red;
}

.addTextBox{
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.addText{
    background: var(--app-light-grey);
    padding: 0.6rem 9rem;
    border: 1px solid var(--app-dark);
    border-radius: 8px;
}

.addTextBox p{
    color: var(--app-dark);
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
}

/* AddText.module.css */
.customDropdown {
    position: relative;
    width: 100%;
  }

  .dropdownBar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dropdownIcon{
    width: 1rem;
  }

  .dropdownText{
    display: flex;
    gap: 1.5rem;
  }
  
  .selectedOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: white;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .dropdownOptions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
  }
  
  .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  
  .option:hover {
    background: #f0f0f0;
  }
  
  .textStylingOptions {
    margin-top: 2rem; /* Add spacing above */
  }
  
  .textStylingOptions h3 {
    font-family: Butler;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    color: var(--app-dark);
    margin-bottom: 0.5rem;
  }
  
  .stylingButtons {
    display: flex;
    gap: 1.25rem; /* Space between buttons */
  }
  
  .stylingButton {
    background: #F6F7F9;
    border: 1px solid #D0D4D7;
    border-radius: 6px;
    padding: 0.5rem 0.75rem;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .stylingButton i,
  .stylingButton u {
    font-style: normal; /* Ensure buttons are not italicized or underlined */
    font-weight: normal;
  }
  
  .stylingButton:hover {
    background: #E0E3E8; /* Lighter background on hover */
  }
  
  .stylingButton:active {
    background: #D1D5DA; /* Darker background on active */
  }
  
  .activeStylingButton{
    background: #cdced1;
  }

  .fontStyleDiv {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  .fontSizeOptions {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.3rem 1rem;
    border: 1px solid #D0D4D7;
    border-radius: 0.5rem; 
  }
  
  .fontSizeOptions input[type="number"] {
    width: 3rem; /* Fixed width for the input */
    text-align: center; /* Center align the value */
    font-size: 1rem; /* Maintain consistency with icons */
    padding: 0.25rem;
    border: 1px solid var(--app-dark-grey); /* Subtle border */
    border-radius: 4px; /* Match overall design */
    outline: none;
    transition: border-color 0.3s; /* Smooth focus effect */
  }
  
  .fontSizeOptions input[type="number"]:focus {
    border-color: var(--app-primary-color); /* Highlight border on focus */
  }
  
  