.message {
    font-size: 1rem;
    width: 30vw;
    min-height: 5vh;
}

.actions {
   display: flex;
   justify-content: flex-end;
   gap: 1rem;
   width: 100%;
}

.btn {
    width: 5rem;
}

@media (max-width:1024px) {
    .message {
        width: 50vw;
    }
}


@media (max-width:578px) {
    .message {
        width: 80vw;
    }
}

@media (max-width:480px) {
   

    .actions {
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }
}

