.breadCrumbs {
    color: var(--app-dark);
}

.mainContainer {
    padding: 2rem 2.5rem;
}

.headerSection {
    margin-top: 2.5rem;
}

.headerSection h2 {
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
    color: var(--app-dark);
}

.mainBody {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
    /* flex-wrap: wrap; */
}

.sections {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.sideBar {
    flex: 0.5;
    border: 1px solid var(--app-grey);
    border-radius: 0.5rem;
    padding: 1.5rem;
    height: fit-content;
}

.sidebarHeader,
.shipping,
.subtotal,
.total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    border-bottom: 1px solid #EAEAEA;
    padding: 0.875rem 0;
}

.sidebarHeader h2 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: var(--app-dark);
}

.sidebarHeader p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: right;
    color: var(--app-dark);
}

.shipping h2,
.subtotal h2 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: var(--app-dark);
}

.shipping p,
.subtotal p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: right;
    color: var(--app-dark);
}

.total h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: var(--app-dark);
}

.total p {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: right;
    color: var(--app-dark);
}

.payBtn{
    width: 6rem;
    height: 1rem;
    margin-top: 2rem;
}

.errorMessage {
    color: var(--app-warning);
}

.stripePayment {
    width:60vw;
    height:70vh;
    padding:1rem;
}

@media (min-width:1024px){
    .sideBar{
        position: sticky;
        top: 100px;
    }
}

@media screen and (max-width: 1024px) {
    .mainContainer {
        padding: 1.5rem 1rem;
    }

    .sections {
        flex: 1.5;
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }

    .mainBody {
        flex-direction: column;
     }
}

@media screen and (max-width: 786px) {
    .sections {
        width: 100%;
        gap: 2rem;
    }

    .sideBar {
        flex: 1;
    }
}

@media screen and (max-width: 576px) {
    .stripePayment {
        width: 85vw;
    }

    .payBtn{
        width: 6rem;
        height: 2rem;
        margin-top: 2rem;
    }
}