.infoIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  border: 2px solid var(--app-claret);
  color: var(--app-claret);
  border-radius: 50%;
  padding: 8px;
  width: 18.5px;
  height: 18.5px;
}