.buttonContainer {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fb;
  border: 1px solid #d1d9e2;
  border-radius: 8px; /* Optional: for rounded corners */
  width: fit-content;
  cursor: pointer;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 10px;
}

.icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.label {
  font-size: 16px;
  color: #5a6677;
  font-weight: 500;
  padding-bottom: 0.625rem;
}

.active {
  font-weight: 600;
  color: var(--app-black);
  border-bottom: 2px solid var(--app-black);
  width: 100%;
}

@media screen and (max-width: 820px) {
  .active {
    width: 85%;
  }
}

@media screen and (max-width: 500px) {
    .active {
      width: 100%;
    }
  }
  
