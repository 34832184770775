.sidebar {
  width: 17.5rem;
  background: #f6f6f6;
  height: fit-content;
  border-radius: 0.25rem;
  padding: 1rem;

  -webkit-user-drag: none; /* Disable drag on Safari */
  pointer-events: auto; /* Ensure normal interaction is not affected */
  touch-action: none;
}

.profileSection {
  text-align: center;
  margin-bottom: 1rem;
  padding: 1.25rem;
}

.profileImageContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.profileImage {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: #e0e0e0;
}

.image {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 2px solid #e0e0e0;
}

.image img {
  object-fit: fill;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.userInfo {
  margin-bottom: 10px;
}

.userName {
  margin: 0;
  padding: 0.25rem;
  font-size: 18px;
  font-weight: bold;
}

.userEmail {
  margin: 0;
  font-size: 14px;
  text-align: center;
  color: #757575;
}

.editSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--app-primary-light);
}

.editButton {
  background: #e6e9ec;
  margin: 0;
  width: 15.9rem;
  height: 3rem;
}

.balance {
  height: fit-content;
  width: 15.9rem;
  background: #f9f1d6;
  font-size: 0.75rem;
  padding: 10px 20px 10px 20px;
  gap: 12px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.walletDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.walletIcon {
  width: 100%;
  height: 100%;
  max-width: 24px;
  max-height: 24px;
}

.walletIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.balance span {
  font-family: Fraunces;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  text-align: left;
}

.balance p {
  font-family: Fraunces;
  font-size: 10px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.001em;
  text-align: left;
}

.menuSection {
  width: 15.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  justify-content: space-between;
}

.menuButton {
  width: 100%;
  height: 3rem;
  border-radius: 0;
  border: none;
  background-color: transparent;
}

.logoutButton {
  width: 100%;
  height: 3rem;
  border-radius: 0;
  border: none;
  background-color: transparent;
  color: var(--app-primary);
  font-weight: 600;
}

.crossIcon {
  display: none;
}

@media screen and (max-width: 820px) {
  .sidebar {
    width: 16rem;
  }

  .balance {
    width: 14.9rem;
  }
  
}

@media screen and (max-width: 1024px) {
  .sidebar {
    z-index: 5;
  }

  .crossIcon {
    display: flex;
    justify-content: flex-end;
  }

  .crossIcon > img {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.125rem;
    border-radius: 50%;
    background-color: #fff;
  }

  .profileSection {
    padding: 0rem;
  }

  .menuButton {
    height: 2.5rem;
  }

  .sidebar {
    height: 88.5dvh;
    overflow: auto;
    padding: 0.25rem;
  }

  .menuSection {
    gap: 0rem;
  }
}
