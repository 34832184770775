.headerSection{
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #D0D4D7;
    margin-bottom: 2rem;
}

.headerText{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.headerSection h2{
    font-family: Butler;
    font-size: 30px;
    font-weight: 700;
    line-height: 34px;
    text-align: left;
    color: var(--app-dark);
    margin-bottom: 4px;
}

.headerSection p{
    color: #666666;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.ColorOption, .outerBorder{
    border: 1px solid #C7C7C7;
    border-radius: 12px;
    background: var(--app-white);
    padding: 24px;
}
.colorHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.dropDownImage{
    width: 32px;
    height: 32px;
    background: #E9E9E9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

