.container {
    width: 100%;
    /* height: 80vh;
    overflow-y: scroll; */
    overscroll-behavior: none;
}

.editorContainer {
    height: 80vh;
    overflow-y: scroll;
    flex:1;
    background: #565656;
    padding: 1rem;
    border-radius: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

@media (min-width:1024px) and (max-height:800px) {
    .editorContainer {
      height: 75dvh;
      overflow: auto;
    }
  }

.mainContainer {
    display: flex;
    gap: 2.5rem;
}

.editorOption {
    width: 100%;
    max-width: 560px;
}

.Component{
    width: 100%;
    max-width: 576px;
}

.editorSection {
    flex:1;
    background: #565656;
    padding: 1rem;
    border-radius: 12px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    /* height: 80vh;
    overflow-y: scroll; */
}

.bookSection {
    border-radius: 12px;
    width: 100%;
    height: 33.5rem;
    display: flex;
    position: relative;
}

.bookDetails {
    margin-top: 2.5rem;
}

.spineSection {}

.spineSections {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.sections {
    background: var(--app-white);
    padding: 8px 30px;
    border-radius: 12px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.001em;
    text-align: left;
    text-wrap: nowrap;
}

.sections:hover {
    background: var(--app-light-grey);
}

.activeSection {
    background: var(--app-grey);
    color: var(--app-white);
    padding: 8px 30px;
    border-radius: 12px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.001em;
    text-align: left;
    text-wrap: nowrap;
}

.disabledCanvas {
    pointer-events: none;
}

.alertText {
    height: fit-content;
    position: absolute;
    left: 37%;
    z-index: 200000;
}

.alertText {
    background: rgba(255, 255, 255, 0.98);
    color: #1a1a1a;
    padding: 12px 24px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    animation: slideInAndFade 2s ease-in-out forwards;
    max-width: 90vw;
    margin: 0 auto;
    backdrop-filter: blur(8px);
  }

@media (max-width: 1024px) {
    .mainContainer {
        flex-direction: column-reverse;
        gap: 1rem;
        height: 100vh;
    }
    
    .editorSection {
        padding: 6px 16px; /* Reduce padding for smaller screens */
    }
}

/* Styles for drawer functionality (if needed) */
.drawerContainer {
    position: relative;
    height: 100%;
}

.drawer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 545px; /* Adjust as necessary */
    max-width: 100%;
    background: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 12px 12px 0 0;
    transition: height 0.3s ease;
    z-index: 1000; /* Ensure it sits above other content */
    transform: translateY(100%);
}

.drawerOpen {
    transform: translateY(0);
}

.drawerClose {
    transform: translateY(100%);
}

.drawerBorder {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 10px; /* Height of the draggable border */
    background: #007bff; /* Color of the border */
    cursor: ns-resize;
    z-index: 1001; /* Ensure it sits above other content */
}

.inVisible {
    position: fixed;
    top: 0;
    opacity: 0;
    pointer-events: none;
}


@media(max-width:1024px){
    .bookSection{
        height: calc(100vh - 28.5rem);
        padding: 0;
    }

    .fullCanvas {
        height: 75vh;
        transition: height 0.1s ease-in-out;
    }

    .editorOption{
        width: 100%;
    }
}

