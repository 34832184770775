/* Main container styling */
.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.5rem;
  padding: 1rem; /* Added padding to prevent content from touching the edges on small screens */
  height: 90dvh;
  overflow: auto;
}

@media (min-width:1024px) and (max-height:874px) {
  .mainContainer {
    height: 65dvh;
    overflow: auto;
  }
}

.details {
  background: var(--app-light-grey);
  padding: 1.5rem; /* Adjusted padding for responsiveness */
  padding-top: 0;
  flex-grow: 1;
}

/* Header section styling */
.headerSection {
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #D0D4D7;
  margin-bottom: 2rem;
}

/* Header title styling */
.headerSection h2,.headerSectionBack h2 {
  font-family: Butler;
  font-size: 2rem; /* Changed from 30px for better scaling */
  font-weight: 700;
  line-height: 1.2; /* Adjusted line height for better readability */
  color: var(--app-dark);
  margin-bottom: 0.5rem; /* Adjusted margin for spacing */
}
.sectionController {
    position: fixed;
    bottom: 0;
    width: 500px;
    background: var(--app-white);
    border-radius: 8px;
    padding: 45px 25px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }

  .inVisible {
    position: fixed;
    top: 0;
    opacity: 0;
    pointer-events: none;
  }

  
  @media(max-width:1024px){
    .mainContainer{
      padding: 0;
      height: fit-content;
    }
    .sectionController{
      position: static;
      margin-top: 1.5rem;
      width: calc(100% + 2 * 24px);
      margin-left: -24px; /* Shift left to cover padding */
      margin-right: -24px; /* Shift right to cover padding */
      padding:12px;
      box-sizing: border-box; /* Include padding and border in width */
      max-width: 100dvw;
    }
  
    .sectionController button{
      font-size: 12px;
      padding: 0.5rem;
    }

    .details{
      background: var(--app-white);
      padding: 0
    }

    .headerSection{
      border: none;
      margin-bottom: 0;
    }
  }

  @media(max-width:768px){

    .headerSection h2{
      font-size: 20px;
    }

  }