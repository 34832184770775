.card {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 90%; /* Adjusted width for responsiveness */
  max-width: 14.75rem;
  margin: 1rem 0;
  padding: 1.25rem 0;
  padding-bottom: 0;
  background: var(--app-light-grey);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  overflow: hidden;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.imageContainer {
  position: relative;
  display: flex;
  justify-content: center; /* Centering the image */
}

.image {
  width: 12.25rem;
  height: 16.875rem;
  object-fit: cover;
}

.heartButton,.filledHeart {
  display: flex; /* Hidden by default on larger screens */
  position: absolute;
  color: var(--app-claret);
  background: #fff;
  border-radius: 50%;
  top: 5%;
  right: 5%;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 101;
  visibility: hidden;
}

.filledHeart {
  color: var(--app-primary);
  padding: 0.325rem;
  height: 1.25rem;
  width: 1.25rem;
}

.content {
  padding: 0 1rem;
}

.topRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.25rem auto;
  min-height: 4rem;
}

.titleContainer {
  max-width: 80%;
}

.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  margin: 0;
  max-height: 3rem;
  width: 100%;
}

.author {
  font-size: 0.875rem;
  color: #757575;
  margin: 0.25rem 0;
  padding: 0;
  text-align: left;
}

.bottomRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 1rem 0; */
}

.price {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.addToCartButton {
  display: flex; /* Hidden by default on larger screens */
  justify-content: center;
  /* margin: 0 auto; */
  width: 7.37rem;
  /* position: absolute;
  bottom: 2%;
  right: 10%; */
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  z-index: 102;
  visibility: hidden;
}

.card:hover .addToCartButton,
.card:hover .heartButton,
.card:hover .filledHeart {
  visibility: visible;
  opacity: 1;
}

.cloudLayer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card:hover .cloudLayer {
  opacity: 1;
}

.actions {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  justify-content: space-between;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

@media (max-width: 1200px) {
  .card {
    width: 100%; /* Make the card take full width on smaller screens */
    max-width: none;
    margin: 1rem auto;
  }

  .heartButton {
    visibility: visible;
    z-index: 1;
  }

  .addToCartButton {
    display: flex; /* Always visible on smaller screens */
    opacity: 1;
    position: static; /* Position adjustments for smaller screens */
    transform: none;
    margin-top: 0.5rem;
    z-index: 1;
    visibility: visible;
  }

  .card:hover .cloudLayer {
    opacity: 0;
  }
  
}

@media (max-width: 1024px) {
  .card:hover {
    transform: scale(1);
    box-shadow: none;
  }

  .addToCartButton {
    visibility: hidden;
  }

  .card:hover .addToCartButton {
    visibility: hidden;
  }
}

@media (max-width: 480px) {
  .card {
    width: 100%; /* Make the card take full width on smaller screens */
    max-width: none;
    margin: 1rem auto;
  }


  .addToCartButton {
    display: flex; /* Always visible on smaller screens */
    opacity: 1;
    position: static; /* Position adjustments for smaller screens */
    transform: none;
    margin-top: 0.5rem;
    z-index: 1;
  }

  .cloudLayer {
    opacity: 0; /* Hide the cloudy layer on smaller screens */
  }
}


@media (max-width : 480px) {

  .image {
    width: 8.25rem;
    height: 12.875rem;
    object-fit: cover;
  }
  
.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  margin: 0;
  max-height: 2rem;
  width: 100%;
}

.author {
  font-size: 0.75rem;
  color: #757575;
  margin: 0.25rem 0;
  padding: 0;
  text-align: left;
}

.bottomRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 1rem 0; */
}

.price {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.addToCartButton {
  display: flex; /* Hidden by default on larger screens */
  justify-content: center;
  /* margin: 0 auto; */
  width: 3.37rem;
  /* position: absolute; */
  /* bottom: 2%;
  right: 10%; */
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  z-index: 2;
  visibility: hidden;
}

.cartBtn {
  font-size: 0.675rem;
  line-height: 0.75rem;
  padding: 0.65rem 0.875rem;
}

}

