.headerSection{
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #D0D4D7;
    margin-bottom: 2rem;
}

.headerText{
    display:flex;
    align-items: center;
    gap: 0.5rem;
}

.headerSection h2{
    font-family: Butler;
    font-size: 30px;
    font-weight: 700;
    line-height: 34px;
    text-align: left;
    color: var(--app-dark);
    margin-bottom: 4px;
}

.headerSection p{
    color: #666666;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.innerBorderOption, .innerBorder{
    border: 1px solid #C7C7C7;
    border-radius: 12px;
    background: var(--app-white);
    padding: 24px;
}
.innerBorderHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.dropDownImage{
    width: 32px;
    height: 32px;
    background: #E9E9E9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.innerBorderOption p{
    color: #64615D;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

}

.selectinnerBorder {
    margin-top: 2.25rem;
}

.selectinnerBorder h2 {
    font-family: Butler;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    color: var(--app-black);
}

.innerBorders {
    margin-top: 12px;
    display: flex;
    gap: 0.625rem;
    flex-wrap: wrap;
}

.innerBorderPicker {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    cursor: pointer;
}

.selected {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 2px solid var(--app-black);
    display: flex;
    align-items: center;
    justify-content: center;
}

.active {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid var(--app-white);
}

.innerBorderPickerDropdown h3{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.001em;
    text-align: left;
    color: #5F6D7E;
}


.innerBorderOptions p, .innerBorderTypes p{
    color: var(--app-dark);
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.26px;
    text-align: left;

}

.innerBorderTypes{
    margin-top: 2rem;
}

.innerBorderType{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.radioOptions label{
    color: #666666;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.26px;
    text-align: left;
}

.radioOptions{
    margin-top: 0.875rem;
    display: flex;
    gap:5.65rem
}

.radioOptions input[type="radio"]{
    accent-color: var(--app-claret);
    transform: scale(1.5);
    margin-right: 0.5rem;
}

.innerBorderTypePreview{
    margin-top: 1rem;
}

.innerBorderTypePreview h2{
    font-family: Butler;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
    color: var(--app-dark);
}

.innerBorderTypePreview p{
    margin-top: 0.875rem;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.26px;
    text-align: left;
    color: var(--app-dark);
}

.previewContainer{
    margin-top: 0.875rem;
}

.previewCard,.activePreviewCard{
    width: 150px !important;
    height: 60px;
    border-radius: 12px;
    border: 1px solid var(--app-grey);
    padding: 0.125rem;
}

.activePreviewCard{
    border: 2px solid var(--app-primary);
}

.previewCard img,.activePreviewCard img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.foilingColorSection h2{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 17.26px;
    text-align: left;
    margin-top: 1rem;
}

.uploadBtn{
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    background: #F9F1D6;
    padding: 12px;
    border-radius: 8px;
  }

  .addLogo{
    display: flex;
    gap: 0.875rem;
    align-items: center;
  }

  .addLogo p{
    font-family: Butler;
    color: var(--app-dark);
    font-size: 18px;
  }

  .addLogoIcon{
    font-size: 24px;
  }

  .logoPrice{
    font-size: 18px;
    font-family: Butler;
    font-weight: 900;
    color: var(--app-dark);
  }
  .logoColorOptions{
    display: flex;
    gap: 2rem;
  }

  .logoColor{
    margin-top: 1.5rem;
  }

  .logoColor p{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 500;
    line-height: 17.26px;
    text-align: left;
    color: var(--app-dark);
    margin-bottom: 1rem;
  }

  .imagePreview{
    width: 100px;
    height: 100px;
    border-radius: 12px;
    margin-top: 0.5rem;
    position: relative;
  }

  .imagePreview img{
    width: 95%;
    height: 95%;
    object-fit: contain;
    border-radius: 12px;
  }

  .deleteImage{
    position: absolute;
    top: 5%;
    right: 5%;
    cursor: pointer;
  }


.disclaimer{
    font-family: Fraunces !important;
    font-size: 11px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    text-align: left !important;
    color: #666666 !important;
    margin-top: 0.875rem !important;
}

@media(max-width:1024px){
    .innerBorderTypes{
        margin-top: 0rem;
    }

    .innerBorderTypes h2{
        font-family: Fraunces;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.26px;
        text-align: left;
        color: var(--app-dark);
    }
}
@media(max-width:480px){
    .disclaimer{
        font-size: 11px !important;
        font-weight: 400 !important;
        line-height: 18px !important;
    }
}