.couponCard {
  display: flex;
  flex-direction: row;
  border-radius: 16px;
  padding: 20px;
  background-color: var(--app-light-grey);
  max-width: 24.75rem;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin: auto;
}

.leftSection {
  flex: 1;
}

.leftSection h2 {
  font-family: Butler, serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
}

.leftSection p {
  margin: 4px 0;
  font-family: Fraunces, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000;
}

.codeSection {
  display: flex;
  align-items: center;
  margin: 8px 0;
  flex-wrap: wrap;
}

.codeLabel {
  color: #555;
  font-family: Fraunces, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.code {
  font-weight: bold;
  color: #5d6972;
  font-family: Fraunces, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-left: 4px;
}

.copyIcon {
  color: #777;
  cursor: pointer;
  font-size: 16px;
  margin-left: 8px;
}

.endDate {
  margin-top: 8px;
  font-size: 14px;
  font-family: Fraunces, serif;
  font-weight: 400;
  line-height: 14.8px;
  text-align: left;
  color: #000;
}

.rightSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 2px dashed #C7C7C7;
  padding-left: 20px;
  margin-left: 10px;
  margin-top: 20px;
}

.iconWrapper {
  background-color: #fce8e6;
  border-radius: 50%;
  padding: 10px;
}

.icon {
  width: 40px;
  height: 40px;
}

.discountText {
  font-family: Fraunces;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  margin-top: 8px;
  text-align: center;
}

.circle1,
.circle2 {
  background: white;
  border: 1px solid var(--app-light-grey);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  box-shadow: 0px 0px 0px 10px var(--app-light-grey);
}

.circle1 {
  top: 0;
  left: 68%;
  transform: translateY(-60%);
}

.circle2 {
  bottom: 0;
  left: 68%;
  transform: translateY(60%);
}

.copied {
  color: #5d6972;
  font-family: Fraunces, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 1rem;
}

@media screen and (min-width: 768px) {
  .couponCard {
    flex-direction: row;
    padding: 20px;
  }

  .rightSection {
    border-left: 2px dashed #C7C7C7;
    padding-left: 20px;
    margin-left: 10px;
    margin-top: 0;
    margin-left: 20px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 768px) {
  .couponCard {
    padding: 15px;
    max-width: 100%;
  }

  .leftSection h2 {
    font-size: 18px;
    line-height: 30px;
  }

  .rightSection {
    border-left: none;
    padding-left: 0;
    margin-left: 0;
  }

  .circle1,
  .circle2 {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .leftSection h2 {
    font-size: 16px;
    line-height: 28px;
  }

  .leftSection p,
  .codeLabel,
  .code,
  .endDate,
  .discountText {
    font-size: 12px;
    line-height: 20px;
  }

  .copyIcon {
    font-size: 14px;
  }

  .icon {
    width: 30px;
    height: 30px;
  }

  .iconWrapper {
    padding: 8px;
  }

  .couponCard {
    padding: 10px;
  }
}
