.mainContainer{
    border: 1px solid #E4E7E9;
    padding: 1.5rem 1rem;
}

.mainContainer h1{
    font-family: Butler;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: var(--app-dark);
    padding: 1.25rem 0;
}

.overview{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EAEAEA;
    padding-bottom: 0.875rem;
}

.overview h2{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #141718;
    margin: 0;
}

.overview p{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: right;
    color: #141718;
}

.addOn{
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
    padding-bottom: 1rem;
}

.addOnHeader{
    margin: 0;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    color: #141718;
}

.priceSection{
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #E4E7E9;
}

.Tax,.Discount,.shipping,.subTotal{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Tax h2,.Discount h2,.shipping h2,.subTotal h2{
    color: #5F6C72;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin: 0;
}

.Tax p,.Discount p,.shipping p,.subTotal p{
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #191C1F;
}

.total{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.discount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
}

.total h2{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin: 0;
    color: #191C1F;
}

.total p{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #191C1F;
}

.couponSection{
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.couponSection h2{
    font-family: Fraunces;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: var(--app-dark);
    margin-bottom: 7px;
}

.couponSection p{
    color: #6C7275;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
}

.browseCoupons{
    color: var(--app-dark) !important;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 600 !important;
    line-height: 26px;
    text-align: left;
    border-bottom:3px solid var(--app-primary);
    width: fit-content;
    cursor: pointer;
}

.couponButton{
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    border: 1px solid #6C727580;
    padding: 12px 16px;
    gap: 0.5rem;
}

.couponButton div{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.couponButton img{
    width: 20px;
    height: 20px;
}

.couponButton input{
    border: none;
    width: 100%;
    max-width: 240px;
    color:#6C7275;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;

}

.couponButton input:focus{
    outline: none;
}

.couponButton button{
    border: 1px solid var(--app-claret);
    border-radius: 15%;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.012em;
    text-align: left;
    color: var(--app-claret);
}

.couponModal{
    width: 70dvw;
    height: 70dvh;
}

.notApplicable {
    font-size: 0.75rem;
    color: var(--app-warning);
}
.appliedCoupon {
    padding: 0 0 1rem;
    border: 1px solid var(--app-border-light);
    border-radius: 10px;
}

.couponHeader h4 {
    font-family: 'Fraunces', serif;
    font-size: 1rem;
    font-weight: 500;
    color: var(--app-dark);
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
}

.couponBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.couponBody h3 {
    font-family: 'Fraunces', serif;
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--app-dark);
    margin: 0;
}

.couponBody p {
    color: var(--app-primary);
    text-decoration: none;
    cursor: pointer;
    font-family: 'Fraunces', serif;
    font-size: 0.95rem;
    font-weight: 500;
    margin: 0;
    transition: color 0.2s ease, text-decoration 0.2s ease;
}

.walletSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding: 10px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .walletInfo {
    display: flex;
    flex-direction: column;
  }
  
  .walletInfo h2 {
    font-size: 16px;
    margin: 0;
    color: #333;
  }
  
  .walletAmount,.usedWalletAmount {
    font-size: 18px;
    font-weight: bold;
    color: #4caf50;
    margin-top: 5px;
  }

  .usedWalletAmount {
    color: var(--app-primary);
  }
  
  .walletButton {
    padding: 8px 16px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    max-width: 3rem;
    transition: background-color 0.3s ease;
  }

  .walletDisabled {
    margin-bottom: 0.675rem;
    padding: 10px 15px;
    border: 1px solid #ffc107;
    border-radius: 8px;
    background-color: #fff8e1;
    font-size: 14px;
    color: #ff9800;
  }
  
  @media (max-width:1024px) {
    .totalAndCheckout {
        position: sticky;
        background: var(--app-white);
        padding: 1rem 0.5rem;
        border: 1px solid var(--app-light-grey);
        bottom: 0;
        z-index: 2;
    }
  }
  @media (max-width:768px) {
    .couponSection h2{
        font-family: Fraunces;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
    }
  }

  @media (max-width:460px) {
    .couponModal{
        width: 85dvw;
        height: 70dvh;
    }
  }
  