.featuredContainer{
  background: white;
  margin-top: 4rem;
}

.headerSection{
  width: 661px;
  gap: 8px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 1rem;
}

.headerh2 h2{
  font-family: Butler;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0.5rem;
}

.carousel{
  :global(.slick-slide){
    display: flex !important;
    justify-content: center !important;
  }

  margin: 1rem 0;
}

.slick-slide {
  display: flex;
  justify-content: center;
}

.slick-prev,
.slick-next {
  z-index: 10;
  font-size: 1.5rem;
}

.slick-prev:before,
.slick-next:before {
  color: #6d8a91; /* Matches your tab active color */
}


.description{
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  font-family: Fraunces;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  
  color:#5D6568;
  width: 611px;
  height: 40px;
}


.tabs {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    gap: 0.5rem;
    white-space: nowrap;
  }
  
  .tab {
    padding: 1rem 2rem;
    cursor: pointer;
    margin-right: 0.5rem;
    background-color: #ffffff;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;
    border: 1px solid #6D8A91;
    transition: background-color 0.3s ease;
    border-radius: 6.25rem;
    width: 12rem;
  }
  
  .tab:hover {
    background-color: #ccc;
  }
  
  .tab.active {
    background-color: #6D8A91;
    color: #fff;
  }

  .tabs::-webkit-scrollbar{
    display: none;
  }
  
  .cardGrid {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 1rem;
  }

  .card {
    margin: 2rem 1rem;
  }


.buttonContainer{
  width: fit-content;
  padding: 0rem 1.5rem 1rem;
  gap: 0.625rem;
  border-radius: 0.5rem;
  margin:1.25rem auto 0;

}

.footerImage{
  display: flex;
  justify-content: center;
  padding: 96px 0 30px 0;
}


.leftArrow,.RightArrow{
  border-radius: 50%;
  background: var(--app-white);
  color: var(--app-dark);
  border: 1px solid var(--app-dark);
  position: absolute;
  top: 50%;
  z-index: 2;
}

.RightArrow{
  right: -5%;
}

.leftArrow {
  left: -3%;
}

@media (max-width:1440px) {
  .RightArrow{
    right: -7%;
  }
}

@media (max-width:1200px){
    
  .leftArrow{
    left: -4%;
  }

  .RightArrow{
    right: -5%;
  }
}

@media (max-width:1024px){
  .cardGrid{
    grid-template-columns: 1fr 1fr 1fr;
  }

  .leftArrow{
    left: -3%;
  }

  .RightArrow{
    right: -5%;
  }
}
  
@media (max-width: 768px) {

  .featuredContainer{
    background: white;
    margin-top: 2.5rem;
  }

  .headerh2 h2{
    font-size: 32px;
  }
  .description p{
    font-size: 1rem;
  }
    .tab {
      padding: 10px 20px;
      font-size: 14px; /* Adjust font size for smaller screens */
      width: 8rem;
    }

    .cardGrid{
      grid-template-columns: 1fr 1fr;
    }
  
    .headerSection {

      width: 100%;
    }
  
    .description {
      padding: 0 1rem;
      width: 100%;
      font-size: 12px;
      margin-bottom: 1rem;
    }
  
    .buttonContainer {
      margin-top: 2.75rem;
    }

    .RightArrow{
      right: -7%;
    }

    .leftArrow {
      left: -6%;
    }

    .footerImage{
      padding: 0.75rem 0 2.5rem 0;
    }
  }



  @media(max-width:500px){
    .headerSection{
      margin-bottom: 0rem;
    }

    .headerSection p{
      margin-bottom: 1.5rem;
    }

    .tabs{
      position: relative;
      justify-content: flex-start;
    }

    .tab{
        padding: 0.75rem 1rem;
        margin: 0;
    }

    .tabs{
      overflow: auto;
    }

    .cardGrid{
      grid-template-columns: 1fr 1fr;
      column-gap: 0.75rem;
    }

    .card {
      margin: 2.25rem 0rem !important;
      height: 20rem;
    }

    .RightArrow{
      left: 98%;
    }

    .leftArrow {
      left: -4%;
    }
  }
  

  @media(max-width:480px){
    .carousel {
      margin: 1rem 0.25rem;
    }
  }

  @media(max-width:440px){
    .leftArrow,.RightArrow {
      top: 100%;
      left: 40%;
    }

    .leftArrow {
      left:41%
    }

    .RightArrow {
      left: 54%;
    }

    .tab{
      padding: 8px;
      width: 7.5rem;
    }
  }

  @media(max-width:410px){
    .tabs {
      padding: 1.5rem 1rem 0.5rem;
    }

    .tab {
      font-size: 12px;
    }
  }

  @media(max-width:390px){
    .card {
      width:18rem !important;
    }
  }

  @media(max-width:345px){
    .tabs {
      padding: 1.5rem 1rem 0.5rem;
    }

    .tab {
      padding: 8px 1px;
      font-size: 10px;
    }

    .card {
      width:15rem !important;
    }

    .RightArrow {
      left: 54%;
    }

    .leftArrow {
      left: 37%;
    }
    
  }
  