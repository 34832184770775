.addressCard{
    background: var(--app-light-grey);
    padding: 1.5rem 1.25rem;
    border-radius: 0.75rem;
}

.addressCard h2{
    font-family: Butler;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;

}

.addressCard P{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.73px;
    text-align: left;
    color: #807D7E;
    margin-top: 1.25rem;
}

.options{
    display: flex;
    align-items: center;
    gap: 0.625rem;
}

.remove{
    color: var(--app-claret) !important;
    padding-right: 0.625rem;
    border-right:1px solid #D9D9D9;
}

.edit:hover,.remove:hover{
    cursor: pointer;
}