.reviewContainer {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  
  .profilePicture {
    height: 4.5rem;
    width: 4.5rem;
    border-radius: 50%;
    object-fit: contain;
  }
  
  .reviewText {
    margin-top: 0.5rem;
  }

  @media (max-width: 768px) {
    .reviewContainer {
        flex-direction: column; /* Stack items vertically on smaller screens */
        align-items: flex-start; /* Align items to start */
    }

    .profilePicture {
        height: 56px;
        width: 56px; /* Adjust size of profile picture for smaller screens */
    }

    .reviewText {
        font-size: 14px; /* Reduce font size of review text */
    }
}