.container {
    /* You can add container-specific styles here if needed */
    width: 100%;
}

.container h2{
    font-family: Butler;
    font-size: 2rem;
    font-weight: 700;
    margin: 2rem 0;
}

.tabs {
    margin: 24px 0;
    display: flex;
    padding: 12px 16px;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    white-space: nowrap;
    overflow-x: auto;
}

.tab {
    padding: 1rem 2rem;
    cursor: pointer;
    margin-right: 0.5rem;
    background-color: #ffffff;
    transition: background-color 0.3s ease;
    border-radius: 6.25rem;
    white-space: nowrap;
}

.tab:hover {
    background-color: #ccc;
}

.tab.active {
    background-color: #333;
    color: #fff;
}

.tabs::-webkit-scrollbar {
    display: none;
}

.books {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

@media (max-width: 1200px) {
    .books {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 1024px) {
    .books {
        width: 90%;
    }
}

@media (max-width: 768px) {
    .tab {
        padding: 10px 20px;
        font-size: 14px;
    }

    .books {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .tabs {
        justify-content: flex-start;
        overflow-x: auto;
        margin: 12px 0;
    }

    .tab {
        padding: 8px 16px;
        font-size: 16px;
    }

    .container h2{
        margin: 2rem 0 0;
    }

}

@media (max-width: 400px) {
    .books {
        width: 100%;
    }
}
