.previewCard, .activePreviewCard {
    width: 150px !important;
    height: 60px;
    border-radius: 12px;
    border: 1px solid var(--app-grey);
    margin-right: 0.75rem;
    padding: 0.125rem;
}

.activePreviewCard {
    border: 2px solid var(--app-primary);
}

.previewCard img, .activePreviewCard img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (max-width: 420px) {
    .previewCard, .activePreviewCard {
        width: 70px !important;  /* Make the preview card smaller */
        height: 40px;  /* Adjust the height */
        border-radius: 8px;  /* Slightly reduce the border-radius */
    }
    .endpapercard{
        width: 150px !important;
        height: 60px;
        border-radius: 12px;
        border: 1px solid var(--app-grey);
        margin-right: 0.75rem;
        padding: 0.125rem;
    }

}

