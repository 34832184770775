/* Lato Thin (100) */
@font-face {
  font-family: "Lato";
  src: url("/public/Assets/Fonts/Lato/Lato-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

/* Lato Thin Italic (100) */
@font-face {
  font-family: "Lato";
  src: url("/public/Assets/Fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

/* Lato Light (300) */
@font-face {
  font-family: "Lato";
  src: url("/public/Assets/Fonts/Lato/Lato-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

/* Lato Light Italic (300) */
@font-face {
  font-family: "Lato";
  src: url("/public/Assets/Fonts/Lato/Lato-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

/* Lato Regular (400) */
@font-face {
  font-family: "Lato";
  src: url("/public/Assets/Fonts/Lato/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Lato Italic (400) */
@font-face {
  font-family: "Lato";
  src: url("/public/Assets/Fonts/Lato/Lato-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

/* Lato Bold (700) */
@font-face {
  font-family: "Lato";
  src: url("/public/Assets/Fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Lato Bold Italic (700) */
@font-face {
  font-family: "Lato";
  src: url("/public/Assets/Fonts/Lato/Lato-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

/* Lato Black (900) */
@font-face {
  font-family: "Lato";
  src: url("/public/Assets/Fonts/Lato/Lato-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* Lato Black Italic (900) */
@font-face {
  font-family: "Lato";
  src: url("/public/Assets/Fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Inter";
  src: url("/public/Assets/Fonts/Inter/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("/public/Assets/Fonts/Inter/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("/public/Assets/Fonts/Inter/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("/public/Assets/Fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("/public/Assets/Fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("/public/Assets/Fonts/Inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("/public/Assets/Fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("/public/Assets/Fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("/public/Assets/Fonts/Inter/Inter-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/public/Assets/Fonts/SourceSansPro/SourceSansProBlack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/public/Assets/Fonts/SourceSansPro/SourceSansProBlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/public/Assets/Fonts/SourceSansPro/SourceSansProBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/public/Assets/Fonts/SourceSansPro/SourceSansProBoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/public/Assets/Fonts/SourceSansPro/SourceSansProBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/public/Assets/Fonts/SourceSansPro/SourceSansProSemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/public/Assets/Fonts/SourceSansPro/SourceSansProLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/public/Assets/Fonts/SourceSansPro/SourceSansProLightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/public/Assets/Fonts/SourceSansPro/SourceSansPro.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/public/Assets/Fonts/SourceSansPro/SourceSansProItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  src: url("/public/Assets/Fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
}

@font-face {
  src: url("/public/Assets/Fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;
}

@font-face {
  src: url("/public/Assets/Fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
}

/* Thin (100) */
@font-face {
  src: url("/public/Assets/Fonts/Roboto/Roboto-Thin.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 100;
  font-style: normal;
}

/* Light (300) */
@font-face {
  src: url("/public/Assets/Fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 300;
  font-style: normal;
}

/* Regular (400) */
@font-face {
  src: url("/public/Assets/Fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
}

/* Medium (500) */
@font-face {
  src: url("/public/Assets/Fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
}

/* Bold (700) */
@font-face {
  src: url("/public/Assets/Fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 700;
  font-style: normal;
}

/* Black (900) */
@font-face {
  src: url("/public/Assets/Fonts/Roboto/Roboto-Black.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 900;
  font-style: normal;
}

/* Thin Italic (100 Italic) */
@font-face {
  src: url("/public/Assets/Fonts/Roboto/Roboto-ThinItalic.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 100;
  font-style: italic;
}

/* Light Italic (300 Italic) */
@font-face {
  src: url("/public/Assets/Fonts/Roboto/Roboto-LightItalic.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 300;
  font-style: italic;
}

/* Regular Italic (400 Italic) */
@font-face {
  src: url("/public/Assets/Fonts/Roboto/Roboto-Italic.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 400;
  font-style: italic;
}

/* Medium Italic (500 Italic) */
@font-face {
  src: url("/public/Assets/Fonts/Roboto/Roboto-MediumItalic.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 500;
  font-style: italic;
}

/* Bold Italic (700 Italic) */
@font-face {
  src: url("/public/Assets/Fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 700;
  font-style: italic;
}

/* Black Italic (900 Italic) */
@font-face {
  src: url("/public/Assets/Fonts/Roboto/Roboto-BlackItalic.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 900;
  font-style: italic;
}
/* BrickTown*/
@font-face {
  src: url("/public/Assets/Fonts/BrickTown/Bricktown.ttf") format("truetype");
  font-family: "Bricktown";
  font-style: normal;
}
/* Fraunces*/
@font-face {
  src: url("/public/Assets/Fonts/Fraunces/Fraunces-Regular.ttf") format("truetype");
  font-family: "Fraunces";
  font-weight: 400;
  font-style: normal;
}
@font-face {
  src: url("/public/Assets/Fonts/Fraunces/Fraunces-Bold.ttf") format("truetype");
  font-family: "Fraunces";
  font-weight: 700;
  font-style: normal;
}
@font-face {
  src: url("/public/Assets/Fonts/Fraunces/Fraunces-Regular.ttf") format("truetype");
  font-family: "Fraunces";
  font-style: normal;
}
/* Butler*/
@font-face {
  src: url("/public/Assets/Fonts/Butler/Butler_Regular.otf") format("truetype");
  font-family: "Butler";
  font-style: normal;
}



.App {
  display: flex;
  width: 100dvw;
  height: 100dvh;
  flex-direction: column;
  background-color:white;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

input,
select {
  user-select: auto;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "Lato", sans-serif;
  scroll-behavior: smooth;
  -moz-osx-font-smoothing: grayscale;
  color: #101010;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

button,
a {
  cursor: pointer;
}

:root {
  /* --app-primary: #233868; */
  --app-primary: #971E42;
  --app-primary-dark: #2e3646;
  --app-primary-light: #F9F1D6;
  --app-light-grey:#5D6568;
  --app-warning: #ff3632;
  --app-danger: #ce0c0c;
  --app-red:#F04141;
  --app-orange: #ff9852;
  --app-success: #389b9b;
  --app-white: #fff;
  --app-black: #000;
  --app-grey: #5f6d7e;
  --app-dark:#110D06;
  --app-green: linear-gradient(102.6deg, #2AA24C 40.87%, #2B5D39 174.9%);
  --app-green-text:#4CE778;
  --app-dark-green:#1BBD52;
  --app-semi-light-grey: #f7f9fc;
  --app-light-grey: #f6f6f6;
  --app-grey-text:#666666;
  --app-light-blue: #eef9ff;
  --app-coral-pink: linear-gradient(102.6deg, #A22A4E 40.87%, #FF5286 174.9%);
  --app-claret: #971E42;
  --app-light-pink: #FFF1EF;
  --app-dark-gold:#97851E;
  --app-silver:#C7C7C7;
  --app-navbar: #e1f2ff;
  --border-light-grey: #ccc;
  --app-bianca:#FAF7EB;
  --app-black-background:#25292C;
  --app-light-blue:#DEF7E5D1;
  --app-blue:#4CA5F1;


  /* padding */
  --panel-padding: min(7rem, 8vw);

  /* margin */
  --container-margin: min(8.25rem, 8vw);

  /* fonts */
}

/* calender override stylings */
.fc-toolbar-title {
  color: var(--app-black);
  font-family: var(--inter);
  font-size: 1.5rem !important;
  font-weight: 600;
  line-height: 2.375rem;
  letter-spacing: -0.015rem;
}

.fc-button-primary {
  color: var(--app-white);
  font-family: var(--inter);
  font-size: 1rem;
  font-weight: 600;
}

/* calender override stylings - ends */

/* Hiding arrows of input number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-calendar-picker-indicator {
  font-size: 1.4rem;
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 0.625rem;
  height: 0.625rem;
}

::-webkit-scrollbar-track {
  background: var(--app-primary-light);
  /* border-radius: 0.625rem; */
}

::-webkit-scrollbar-thumb {
  background: var(--app-primary);
  border-radius: 0.625rem;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--app-primary-dark);
}

::-webkit-scrollbar-thumb:active {
  background: var(--app-primary-dark);
}

@media (max-width: 567px) {
  html {
    font-size: 75%;
  }
}

.slick-track {
  display: flex !important;
  gap: 1rem;
}