.main {
    padding: .5rem var(--panel-padding);
    /* background: var(--app-black); */
    background: #F6F6F6;

}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    /* color: var(--app-white); */
    color: #0F0B05;

}

.container p{
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.26px;
    text-align: left;
    color: #0F0B05;
}

.exploreButton {
    padding: .25rem 1rem !important;
    background: linear-gradient(102.6deg, #A22A4E 40.87%, #FF5286 174.9%) !important;

}

.rightSection{
    display: flex;
    gap: 1rem;
    justify-self: center;
    align-items: center;
}


@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }
}

@media (max-width: 700px) {
    .main {
        padding: .5rem 1.25rem;
    
    }

    .container {
        gap: 0.5rem;
    }
}

@media (max-width: 576px) {
    .container {
        gap: 0rem;
    }
    .container p{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
    }

    .exploreButton {
        padding: 0.125rem 1rem !important;
        background: linear-gradient(102.6deg, #A22A4E 40.87%, #FF5286 174.9%) !important;
        font-size: 0.75rem !important;
        line-height: 0.875rem;
    }
}

@media (max-width: 375px) {
    .container {
        gap: 0.25rem;
    }
}