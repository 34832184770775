.container {
    width: 95%;
    padding-bottom: 0px;
    /* margin-bottom: 22rem; */
    /* position: relative; */
    background: linear-gradient(.78deg, #971E42 -2.69%, #310A16 62.69%);
}

.carousel {
    width: 100%;
    /* height: 299px; */
    border-radius: var(--radi-mlg, 8px) var(--radi-mlg, 8px) 0px 0px;
    /* background: linear-gradient(.78deg, #971E42 -2.69%, #310A16 62.69%); */
    padding: 20px;
    color: white;
    text-align: center;
    /* position: relative; */
    z-index: 2;
}

.mainCarousel {
    /* position: absolute; */
    z-index: 1;
    width: 100%;
    padding: 0 2rem 1rem;
    /* transform: translateY(-50%); */
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    border-radius: 0px 0px var(--radi-mlg, 8px) var(--radi-mlg, 8px);    
    /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, #FBF6E8 50%); */
    /* background: linear-gradient(.78deg, #971E42 -2.69%, #310A16 62.69%); */

}

.header {
    display: flex;
    justify-content: space-between;
}

.header h2 {
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    z-index: 2;
}

.carouselInner {
    display: flex;
    transition: transform 0.5s ease;
}

.carouselItem {
    /* margin-top: 4rem; */
    flex: 0 0 auto;
    transition: transform 0.5s ease, opacity 0.5s ease;
    width: 50%;
    position: relative;
    overflow: visible; /* Allow button to extend outside */
}

.carouselItem:hover {
    transform: scale(1.05);
    z-index: 10; /* Bring the hovered item to the front */
}

.imageContainer {
    /* position: relative; */
    width:216px;
    height: 300px;
}

.carouselImage {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 10px;
   
}

.cloudyOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.25); /* Cloudy effect */
    border-radius: 10px; /* Match the image's border-radius */
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none; /* Allow clicks through the overlay */
}

.carouselItem:hover .cloudyOverlay {
    opacity: 1;
}

.addToCartBtn {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--app-claret);
    font-family: var(--inter);
    background: var(--app-white);
    border: 1px solid var(--app-claret);
    font-size: 0.75rem;
    font-weight: 600;
    padding: 8px 14px;
    border-radius: 20px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 10; /* Ensure button is above all other elements */
}

.heartButton,.filledHeart {
    position: absolute;
    color: var(--app-primary);
    background: #fff;
    border-radius: 50%;
    top: 5%;
    right: 5%;
    font-size: 1.5rem;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 10;
    width: 1rem;
    height: 1rem;
    padding: 0.5rem;
  }

  .bookDetail{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    top: 40%;
    left: 10%;
    width: 80%;
    opacity: 0;
    padding: 0.5rem;
    background: rgba(0,0,0, 0.5);
    transition: opacity 0.3s ease;
  }

  .bookDetail h2{
    font-size: 16px;
    font-family: Butler;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: var(--app-white);
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bookDetail h3{
    font-size: 14px;
    font-family: Fraunces;
    overflow: ellipsis;
    text-align: center;
    color: var(--app-white);
  }

  .bookDetail p{
    font-size: 14px;
    text-align: center;
    font-family: Fraunces;
    color: var(--app-white);
  }

.carouselItem:hover .addToCartBtn, .carouselItem:hover .heartButton,.carouselItem:hover .bookDetail, .carouselItem:hover .filledHeart {
    opacity: 1;
}

.carouselBtn {
    display: flex;
    gap: 1.25rem;
}

.carouselBtn .next, .carouselBtn .prev {
    border-radius: 50%;
    background: var(--app-white);
    color: var(--app-dark);
    border: 1px solid var(--app-dark);
    z-index: 3; /* Ensure buttons are above everything else */
}

.next, .prev {
    top: 8%;
    right: 5%;
}

.prev {
    left: 90%;
}

@media (max-width:1440px) {
    .next {
        right: 5%;
    }
    
    .prev {
        left: 87%;
    }
}

/* Responsive styles */
@media (min-width: 1200px) {
    .carouselItem {
        width: 30%; /* Show 3.5 books */
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .carouselItem {
        width: 50%; /* Show 2 books */
    }

    .bookDetail {
        opacity: 1;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .next {
        right: 8%;
    }
    
    .prev {
        left: 82%;
    }

    .carouselBtn {
        margin-right: 2rem;
    }
}

@media (max-width: 767px) {
    .container {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }

    .carousel {
        padding: 20px 20px 0;
    }

    .carouselItem {
        width: 100%; /* Show 1 book */
        display: flex;
        justify-content: center;
    }

    .mainCarousel {
        padding: 1rem;
        position: relative;
    }
    
    .bookDetail {
        opacity: 1;
    }

    .prev,.next {
        position: absolute;
        top: 50%;
    }

    .prev {
        left: 9%;
    }

    .next {
        right: 9%;
    }

    .mainCarousel{
        padding-top: 0;
    }

    .header {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
} 


@media (max-width: 548px) {
    .carouselItem {
        margin-top: 0rem;
    }

    /* .mainCarousel {
        transform: translateY(-52%);
    } */

}
@media (max-width: 460px) {
    .container {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0rem;
    }

    .header {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0rem;
    }

    .carouselItem {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 1rem;
    }

    .mainCarousel {
        padding-bottom: 2.5rem;
    }

    .carouselImage {
        width: 100%; /* Full width image */
        border-radius: 0; /* Remove border radius */
        object-fit: fill;
    }

    .carouselBtn {
        gap: 1.5rem; /* Reduce gap */
    }

    .prev, .next {
        position: absolute;
        top: 92%;
    }

    .prev {
        left: 40%;
    }

    .next {
        right: 40%;
    }

}

@media (max-width :400px) {
    .container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .carouselItem {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .mainCarousel {
        padding: 1rem;
        padding-bottom: 2.5rem;
    }

    .newlyHeader img {
        width: 90%;
    }

    .header h2 {
        font-size: 28px;
    }

    .carouselItem {
        margin-top: 0rem;
    }


    .prev, .next {
        z-index: 3;
    }

    .prev {
        left: 40%;
    }

    .next {
        right: 40%;
    }
}

