/* src/Components/AddressForm/AddressForm.module.css */

.form {
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
  padding-right: 0.5rem;
}

.row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
}

.formGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.selectCountry{
  width: 50%;
}

.addressLine2 {
  margin-top: 1rem;
}

.formGroupCountryCode {
  display: flex;
}

.formGroup label {
  margin-top: 2px;
  margin-bottom: 2px;
  font-weight: bold;
}

.formGroup input,
.formGroup select {
  background: var(--app-white);
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

/* .contactNumber {
    display: flex;
    font-family: Fraunces;
    border: 1px solid var(--app-grey);
    border-radius: 10px;
    background: var(--app-white);
  }
  
  .contactNumber select {
    border: none;
    border-radius: 0;
    flex: 1;
    font-family: Fraunces;
    border-right: 1px solid var(--app-grey);
  }
  
  .contactNumber input {
    border: none;
    flex: 2;
    font-family: Fraunces;
  } */

.contactNumber {
  display: flex;
  gap: 0.5rem;
  font-family: Fraunces;
  border: 1px solid #ccc;
  padding: 0 0.5rem;
  background: var(--app-white);
  margin: 1rem 0;
  border-radius: 0.25rem;
}

.contactNumber select {
  border: none;
  border-radius: 0;
  flex: 1;
  font-family: Fraunces;
  padding: 8px;
  cursor: pointer;
}


.contactNumber input {
  border: none;
  flex: 2;
  font-family: Fraunces;
  border-left: 1px solid #ccc;
  border-radius: 0;
}

.contactNumber select:focus,
.contactNumber input:focus {
  outline: none;
}

@media screen and (max-width: 820px) {
  .form {
     min-width: 52vw;
     font-size: 0.875rem;
  }

  .contactNumber select {
    width: 9rem;
    font-size: 0.75rem;
  }
}


@media screen and (max-width: 820px) {
  .form {
     min-width: 70vw;
     font-size: 0.875rem;
  }

  .contactNumber select {
    width: 4rem;
    font-size: 0.75rem;
  }
}

