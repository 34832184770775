.main {
  display: flex;
  flex-direction: column;
  width: 25dvw;
}

.viewerContainer {
  height: 20rem;
  width: 100%;
  display: flex;
  gap: 1rem;
}

.viewerContainer > div {
  width: 10rem;
}

.viewerContainer > div > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.detailsContainer {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

.dataField {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.name{
  font-size: 0.875rem;
  /* font-weight: 600; */
  color: #5A5A5A;
}

.data {
  font-size: 0.875rem;
}

.title {
  font-size: 1rem;
  font-weight: bold;
  margin: 0.625rem 0;
}

.subTitle {
  font-size: 0.95rem;
  font-weight: 500;
  margin-top: 0.5rem; 
  margin-bottom: 0.25rem; 
}

.bothDataField {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.detailsContainer > div {
  padding: 1rem !important
}

.detailsContainer > div > div:first-child {
  font-size: 1.125rem;
}

.box {
  margin-top: 0.75rem;
}

.spine {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.section {
  display: flex;
  gap: 0.5rem;
}

.sectionContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 0.25rem;
}

.sectionContainer > div {
  min-width: 5rem;
}


@media (max-width:1024px) {
  .main {
    width: 40vw;
  }
}

@media (max-width:768px) {
  .main {
    width: 50vw;
  }
}

@media (max-width:540px) {
  .main {
    width: 60vw;
  }
}

@media (max-width:414px) {
  .main {
    width: 80vw;
  }
}