.ColorOption, .outerBorder{
    border: 1px solid #C7C7C7;
    border-radius: 12px;
    background: var(--app-white);
    padding: 24px;
}
.colorHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.headerText{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.spineOptions{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1.25rem;
}

.dropDownImage{
    width: 32px;
    height: 32px;
    background: #E9E9E9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.ColorOption p{
    color: #64615D;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

}

.selectColor {
    margin-top: 2.25rem;
}

.selectColor h2 {
    font-family: Butler;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    color: var(--app-black);
}

.colors {
    margin-top: 12px;
    display: flex;
    gap: 0.625rem;
    flex-wrap: wrap;
}

.colorPicker {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    cursor: pointer;
}

.selected {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 2px solid var(--app-black);
    display: flex;
    align-items: center;
    justify-content: center;
}

.active {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid var(--app-white);
}

.colorPickerDropdown h3{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.001em;
    text-align: left;
    color: #5F6D7E;
}


.outerBorderOptions p, .outerBorderTypes p{
    color: var(--app-dark);
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.26px;
    text-align: left;

}

.outerBorderTypes{
    margin-top: 1rem;
}

.radioOptions{
    display: flex;
}

.radioOptions label{
    color: #666666;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.26px;
    text-align: left;
}

.radioOptions{
    margin-top: 0.875rem;
    display: flex;
    gap:5.65rem
}

.radioOptions input[type="radio"]{
    accent-color: var(--app-claret);
    transform: scale(1.5);
    margin-right: 0.5rem;
}

.outerBorderTypePreview{
}

.outerBorderTypePreview h2{
    font-family: Butler;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
    color: var(--app-dark);
}

.outerBorderTypePreview p{
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.26px;
    text-align: left;
    color: var(--app-dark);
}

.previewContainer{
    margin-top: 0.875rem;
    display: flex;
    gap: 0.875rem;
}

.previewCard{
    width: 167px;
    height: 60px;
    border-radius: 12px;
    border: 1px solid #971E42;
    background: var(--app-light-grey);
}

.disclaimer{
    font-family: Fraunces;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    text-align: left;
    color: #666666;
    margin-top: 0.875rem;
}