.search {
  margin: 0 auto;
  padding: 24px 24px 16px;
  width: 90%;
  border: 10px solid black;
  border-image-source: url("/public/Assets/Icons/border.svg");
  border-image-slice: 10 fill;
  border-image-outset: 0;
  border-image-repeat: stretch;
  box-sizing: border-box;
}

.container{
  position: relative;
}

.searchContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 774px;
  padding: 0.75rem;
  background-color: #1e1e1e;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
}

.dropdownContainer {
  width: 142px;
  padding: 8px;
  display: flex;
  justify-content: center;
  border-radius: 37px;
  border: 1px solid #EFF1D9;
  background: #25292C;
}

.dropdownContainer select {
  background: #25292C;
  color: #E1E9ED;
  border: none;
  width: 100%;
}

.dropdownContainer select:focus{
  outline: none;
}

.dropdown {
  top: 4.5rem;
  margin: 0 auto;
  width: 100%;
  max-width: 45.625rem;
  background-color: #1e1e1e;
  border-radius:0 0 1rem 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  max-height: 16rem; /* Limit height and make it scrollable if too long */
  overflow-y: auto;
  text-align: left;
}

.dropdownItem {
  padding: 0.75rem 1rem;
  cursor: pointer;
  color: white;
  font-family: Fraunces;
  font-size: 1rem;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.dropdownItem:hover {
  background: grey;
}


.searchInput {
  flex: 1;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  color: white;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: Fraunces;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.73px;
  text-align: left;

}

.searchInput::placeholder{
  color:#E1E9ED;
}

.searchButton {
  border-radius: 30px !important;
  width: 100%;
  max-width: 130px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-left: 12px;
  font-family: Fraunces !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.73px;
  text-align: center;

}

.keywords {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  justify-content: center;
}

.keywords span {
  padding: 8px 16px;
  margin: 5px;
  color: #25292C;
  border: 1px solid #C5CED2;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
}


@media (max-width: 1200px) {
  .dropdown {
    width: 90%;
  }
}

@media (max-width: 1024px) {
  .searchContainer {

  }

  .searchButton {
    margin-left: 0;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .search {
    width: 100%;
  }

  .searchInput {
    font-size: 0.9rem;
  }

  .searchButton {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .search {
    width: 100%;
  }

  .searchInput {
    font-size: 0.8rem;
  }

  .searchButton {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }

  .keywords span {
    font-size: 12px;
    padding: 6px 12px;
  }
}
