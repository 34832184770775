.header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
}

.headerDiv {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    flex-wrap: wrap;
}

.headerDiv h1 {
    color: #FCC418;
    font-family: Butler;
    font-size: 3rem;
    font-weight: 900;
}

.reviewBtn {
    width: 200px;
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.iconFilled {
    color: var(--app-dark-green);
}

.iconHover {
    color: darkgreen;
}

.reviewModal {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 727px;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow: auto;
}

.modalContent {
    width: 90%;
    max-width: 727px;
    position: relative;
    z-index: 1001;
}

/* Media Queries for Responsive Design */

@media (max-width: 768px) {
    .headerDiv h1 {
        font-size: 2.5rem;
    }

    .headerDiv {
        gap: 1rem;
        flex-direction: column;
        align-items: flex-start;
    }

    .details {
        align-items: flex-start;
    }

    .reviewBtn {
        width: 100%;
        max-width: 200px;
        align-self: center;
    }
}

@media (max-width: 480px) {
    .headerDiv h1 {
        font-size: 2rem;
    }

    .headerDiv {
        gap: 0.5rem;
    }

    .reviewBtn {
        width: 100%;
        max-width: 150px;
    }
}
