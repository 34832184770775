.title {
    height: 2.625rem;
    border-bottom: 1px solid var(--app-grey);
    font-family: "Butler";
    font-size: 1.25rem;
    font-weight: 700;
}

.container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.tabs {
    display: flex;
    gap: 1rem;
}

.tab{
    display: flex;
    justify-content: center;
    border: 1px solid #D0D4D7;
    color: #25292C;
    padding: 0.375rem 1rem;
    border-radius: 0.75rem;
    cursor: pointer;
}

.activeTab{
    background: #6D8A91;
    color: #fff;
}

.couponSection {
    display: flex;
    grid-template-columns: 1fr 1fr;
    margin-top: 2.375rem;
    justify-content: flex-start;
    gap: 2.5rem;
    flex-wrap: wrap;

}
.couponSection > div{
    width: 100%;
    margin: 0;
}