.intro {
    height: fit-content;
    margin: 4rem var(--container-margin);
    background: linear-gradient(180deg, rgba(159, 120, 0, 0) -5.09%, rgba(249, 241, 214, 0.288213) 4.75%, rgba(255, 253, 246, 0.3) 15.41%, rgba(254, 251, 241, 0.295622) 43.77%, rgba(254, 251, 241, 0.295492) 43.78%, rgba(251, 246, 232, 0.287324) 63.75%, rgba(247, 240, 218, 0.274648) 81.34%, rgba(239, 226, 189, 0.249296) 99.99%, rgba(159, 120, 0, 0) 100%);
    border-radius: 0.75rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s ease-out forwards;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.intro h2 {
    color: #0F0B05;
    font-family: "Butler";
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    position: relative;
    padding-bottom: 0.5rem;
}

.intro h2::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: var(--app-claret);
    border-radius: 2px;
    animation: growLine 0.6s ease-in-out forwards;
}

@keyframes growLine {
    0% {
        width: 0;
    }
    100% {
        width: 50px;
    }
}

.intro p {
    width: 100%;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #333;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1.2s ease-out forwards;
    animation-delay: 0.3s;
}

.intro p::first-letter {
    font-size: 26px;
    font-weight: 700;
    color: var(--app-claret);
    margin-right: 0px;
}

.intro p + p {
    animation-delay: 0.6s;
}

.intro p + p + p {
    animation-delay: 0.9s;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .intro {
        padding: 1rem;
        gap: 1rem;
    }

    .intro h2 {
        font-size: 24px;
        line-height: 32px;
        padding-bottom: 0.4rem;
    }

    .intro h2::after {
        width: 40px;
    }

    .intro p {
        font-size: 14px;
        line-height: 22px;
    }

    .intro p::first-letter {
        font-size: 22px;
    }
}

@media (max-width: 480px) {
    .intro {
        padding: 0.75rem;
        gap: 0.75rem;
    }

    .intro h2 {
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 0.3rem;
    }

    .intro h2::after {
        width: 35px;
    }

    .intro p {
        font-size: 16px;
        line-height: 20px;
    }

    .intro p::first-letter {
        font-size: 20px;
    }
}
