.whyUs{
    margin-top: 4rem;
}

.bookCustomization,.contactUs {
    margin: var(--container-margin);
    margin-top: 4rem;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    border-radius: 0.75rem;
}

.communityBanner{
    transform: translateY(50%);
  }

  .contactUs{
    margin-top: 1rem;
  }

  @media(max-width:768px){
    .bookCustomization{
        margin-top: 1rem;
    }
  }
