.mainContainer{
  display: flex;
  justify-content: space-between;
  background:#25292C;
  padding: 8px 20px 8px 10px;
  border-radius: 100px;
}

.searchIcon{
  display: flex;
  gap: 0.5rem;
}

.searchIcon input{
  border: none;
  background: transparent;
  color: #E1E9ED;
  width: 100%;
}

.searchIcon input:focus{
  outline: none;
}

.filter{
  color: var(--app-claret);
}


.mainContainer {
  display: flex;
  align-items: center;
}

.searchIcon {
  display: flex;
  align-items: center;
}

.searchIcon input {
  margin-left: 0.5rem;
  padding: 0.5rem;
}

.filter {
  position: relative;
  margin-left: 1rem;
  cursor: pointer;
}

.dropdownli {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 150px;
  z-index: 10;
}

.dropdownli ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdownli li {
  padding: 0.5rem;
  cursor: pointer;
  color: var(--app-dark);
}

.dropdownli li:hover {
  background-color: #f0f0f0;
}

.activeCriteria {
  background: #b1b3a0;  /* Soothing green */
  color: white;         /* White text for contrast */
  padding: 8px 12px;    /* Comfortable padding */
  font-weight: bold;     /* Bold text for emphasis */
}


.dropdown {
  top: 4.5rem;
  margin: 0 auto;
  width: 100%;
  max-width: 765px;
  background-color: #1e1e1e;
  border-radius:1.8rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  max-height: 16rem; /* Limit height and make it scrollable if too long */
  overflow-y: auto;
  text-align: left;
}

.dropdownItem {
  padding: 0.75rem 1rem;
  cursor: pointer;
  color: white;
  font-family: Fraunces;
  font-size: 0.75rem;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.dropdownItem:hover {
  background: grey;
}

.searchFilter{
  display: flex;
  align-items: center;
  gap: 0.35rem;
  color: #ccc;
  font-family: Fraunces;
  font-size: 0.875rem;
}