.mainContainer {
    display: flex;
    gap: 2.5rem;
    flex-direction: column;
    justify-content: space-between;
  }

  .details {
    background: var(--app-light-grey);
    padding: 1.5rem; /* Adjusted padding for responsiveness */
  }

  .headerSection {
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #D0D4D7;
    margin-bottom: 2rem;
  }
  
  .headerSection h2,.headerSectionBack h2{
    font-family: Butler;
    font-size: 30px;
    font-weight: 700;
    line-height: 34px;
    text-align: left;
    color: var(--app-dark);
    margin-bottom: 4px;
  }

  .headerSection p{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 17.26px;
    text-align: left;
    color: var(--app-dark);
  }

/* AddText.module.css */
.customDropdown {
    margin-top: 1.5rem;
    position: relative;
    width: 100%;
  }

  .dropdownBar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dropdownIcon{
    width: 1rem;
  }

  .dropdownText{
    display: flex;
    gap: 1.5rem;
  }
  
  .selectedOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: white;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .dropdownOptions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
  }
  
  .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  
  .option:hover {
    background: #f0f0f0;
  }
  
  .textStylingOptions {
    margin-top: 2rem; /* Add spacing above */
  }
  
  .textStylingOptions h3 {
    font-family: Butler;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    color: var(--app-dark);
    margin-bottom: 0.5rem;
  }
  
  .stylingButtons {
    display: flex;
    gap: 1.25rem; /* Space between buttons */
  }
  
  .stylingButton {
    background: #F6F7F9;
    border: 1px solid #D0D4D7;
    border-radius: 6px;
    padding: 0.5rem 0.75rem;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .stylingButton i,
  .stylingButton u {
    font-style: normal; /* Ensure buttons are not italicized or underlined */
    font-weight: normal;
  }
  
  .stylingButton:hover {
    background: #E0E3E8; /* Lighter background on hover */
  }
  
  .stylingButton:active {
    background: #D1D5DA; /* Darker background on active */
  }

  .inputSection{
    margin-top: 1rem;
    border: 1px solid var(--app-grey);
  }

  .inputSection input{
    background: #fff;
  }




  .uploadBtn{
        margin-top: 1rem;
        background: #F9F1D6;
        padding: 10px 12px;
        display: flex;
        gap: 0.75rem;
        align-items: center;
  }

  .uploadBtn h2{
    font-family: Butler;
    font-size: 18px;
    font-weight: 500;
    line-height: 16.8px;
    text-align: left;

  }


  
.uploadBtn{
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  background: #F9F1D6;
  padding: 12px;
  border-radius: 8px;
}

.addLogo{
  display: flex;
  gap: 0.875rem;
  align-items: center;
}

.addLogo p{
  font-family: Butler;
  color: var(--app-dark);
  font-size: 18px;
}

.addLogoIcon{
  font-size: 24px;
}

.logoPrice{
  font-size: 18px;
  font-family: Butler;
  font-weight: 900;
  color: var(--app-dark);
}
.logoColorOptions{
  display: flex;
  gap: 2rem;
}

.logoColor{
  margin-top: 1.5rem;
}

.logoColor p{
  font-family: Fraunces;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.26px;
  text-align: left;
  color: var(--app-dark);
  margin-bottom: 1rem;
}

.imagePreview{
  width: 100px;
  height: 100px;
  border-radius: 12px;
  margin-top: 0.5rem;
  position: relative;
}

.imagePreview img{
  width: 95%;
  height: 95%;
  object-fit: contain;
  border-radius: 12px;
}

.deleteImage{
  position: absolute;
  top: 5%;
  right: 5%;
  cursor: pointer;
}


  .disclaimer p{
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #666666;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }

  .doneBtn {
    width: fit-content;
    padding: 0.675rem 1rem;
  }

  @media(max-width:1024px){
    .details {
      background: var(--app-white);
      padding: 0rem 0rem 3rem; /* Adjusted padding for responsiveness */
    }

    .headerSection p{
      font-size: 14px;
    }
  }
  @media(max-width:768px){
    .headerSection h2{
        font-size: 20px;
    }

    .headerSection {
      margin-bottom: 0.5rem;
      padding-bottom: 0.75rem;
    }

    .bookSection h2{
        font-size: 16px;
    }

    .disclaimer p{
        font-size: 11px;
    }
  }