.main {
    min-width: max-content;
    flex: 1;
    margin: 1rem 1.5rem;
}

.breadCrumb {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    list-style: none; /* Remove default list styles */
}

.crumb {
    color: var(--app-white);
    font-family: var(--inter);
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    letter-spacing: -0.00088rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.separator {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 600px){
    .main{
        min-width: 280px;
    }
    .breadCrumb{
        flex-wrap: wrap;
        text-align: left;
    }
}