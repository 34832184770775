.nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 1.25rem 2rem;
    background: var(--app-bianca);
    flex-wrap: wrap;
    column-gap: 5rem;
}

.tags {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.tag {
    border: 2px solid var(--app-red);
    border-radius: 100px;
    padding: 0.5rem 1.3rem;
}

.tag p {
    font-family: Fraunces;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #000000;
    text-align: center;
}

.tagsContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    /* flex-direction: column; */
    width: 39rem; /* Limiting the overall width to 800px */
    margin: 0;
    font-family: Butler;
}
  
  .tagScrollWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem;
    width: max-content; /* Takes full container width */
    position: relative;
}
  
.tagListWrapper {
    width: calc(100% - 80px); /* Reserving space for the arrows */
    overflow: hidden;
}
  
.tagList {
    display: flex;
    justify-content: space-evenly;
    transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94); /* Cubic-bezier for smoother easing */
}

.tag,.activeTag {
    background:var(--app-white);
    border: 1px solid #6D8A91;
    border-radius: 50px;
    margin: 0 5px;
    padding: 0.5rem 0.75rem;
    white-space: nowrap;
    text-align: center;
    /* min-width: 6rem; */
    display: flex;
    justify-content: center;
}

.activeTag{
    background: var(--app-grey);
}


.tag p,.activeTag p{
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
}

.activeTag p{
    color: var(--app-white);
}

.scrollButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:#686a6c;
    border: none;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    padding: 12px 12px;
    cursor: pointer;
}

.controlBtn{
    color: var(--app-white);
    font-size: 1rem;
}

.leftArrow,.RightArrow{
    border: 1px solid var(--app-white);
    border-radius: 50%;
    padding: 12px;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    /* bottom: ; */
    /* top: 50%; */
    /* left: 4%; */
    background: var(--app-white);

}

.RightArrow{
    bottom: -10%;
    left:45%
    
}

.leftArrow{
    bottom: -10%;
    right:45%
}

/* @media (max-width:768px){
    .leftArrow{
        
    }
    .RightArrow{
        left: 2%;
    }
} */
  
/* 
.search {
    width: 100%;
    max-width: 560px;
} */

.mainSection {
    padding: 5rem 8.25rem;
}

.blogSection {
    display: grid;
    grid-template-columns: 1.25fr 0.75fr;
    gap: 3.75rem;
}

.bigCard {
    max-height: 785px;
}

.smallCard {
    max-height: 885px;
}
.smallCards{
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* border-top: 1px solid #D0D4D7; */
    border-bottom: 1px solid #D0D4D7;
}

/* .smallCards:nth-child(2) {
    margin: 2rem 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-top: 1px solid #D0D4D7;
    border-bottom: 1px solid #D0D4D7;
} */

.banner {
    display: flex;
    justify-content: space-between;
    background: var(--app-light-pink);
    padding: 48px 54px 48px 41px;
    border-radius: 0.75rem;
    margin-top: 100px;
    margin-bottom: 100px;
}

.bannerBtn {
    width: 13.25rem;
}

.trending {
    background: var(--app-black-background);
    margin: 0 -8.25rem;
    width: calc(100% + 16.5rem);
    padding: 3rem 1.5rem 5rem 1.5rem;
    text-align: center;
    height: fit-content;
}

.trendingHeader {
    color: var(--app-white);
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    margin-bottom: 48px;
}

.otherBlogs{
    margin-top: 3.125rem;
}

.otherBlogs h2{
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
    color: var(--app-dark);
}

.headerSection{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sliderContainer {
    /* :global .slick-slider {
        height: 37.5rem;
    } */
    /* :global .slick-slide {
        padding: 0 15px; /* Add margin to create gap between slides */
    /* } */
    /* :global .slick-current>div{
        width: fit-content;
    } */
    
    
}
/* 
@media (max-width:768px){
    :global .slick-track{
        gap: 5rem;
    }
}

@media (max-width:500px){
    :global .slick-track{
        gap: 1rem;
    }
} */

.blogs {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 26px;
    row-gap: 40px;
}

.card {
    max-width: 28rem;
}

.paginationControl{
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.paginationControl p{
    font-family: Fraunces;
    font-size: 13px;
    font-weight: 600;
    line-height: 16.03px;
    text-align: left;
    color: #333333;
}

.searchHeader{
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.searchHeader h2{
    font-family: Butler;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    color: var(--app-dark);
    cursor: pointer;
}

.backBtn{
    font-size: 28px;
    cursor: pointer;
}

.trendingBlogCard{
    max-width: 23rem;
}

.slider {
    :global .slick-slide {
        display: flex;
        justify-content: center;
    }
}


@media screen and (max-width: 1120px) {
    .tagsContainer {
        width: 80%;
        flex-direction: column;
    }
     .nav {
        flex-direction: column;
        gap: 1rem;
    }
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .mainSection {
        padding: 5rem 4rem;
    }
    .blogSection {
        grid-template-columns: 1fr;
    }

    .trendingBlogCard{
        max-width: 21rem;
    }

}

@media (max-width: 1024px) {
    .nav {
        flex-direction: column;
        /* align-items: flex-start; */
    }
    .search {
        margin-top: 1rem;
        width: 100%;
    }
    .banner {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 24px;
        gap: 1rem;
        margin-top: 3.5rem;
        margin-bottom: 2.875rem;
    }


    .bannerBtn {
        width: 100%;
    }

    .trending {
        margin: 0;
        width: 100%;
        /* height: fit-content; */
        padding: 80px 24px;
    }

    .tagsContainer {
        width: 80% !important;
        flex-direction: column;
    }

    .trendingBlogCard{
        max-width: 19rem;
    }
}

@media (max-width: 768px) {
    .tags {
        flex-wrap: wrap;
        gap: 0.5rem;
    }
    .mainSection {
        padding: 3rem 1.5rem;
    }
    .blogSection {
        gap: 2rem;
    }
    .tagsContainer {
        width: 90% !important;
        flex-direction: column;
    }

    .trendingBlogCard {
        min-width: 30vw;
        margin: 0 auto;
    }

    .RightArrow{
        left:45%
    }
    
    .leftArrow{
        right:37%
    }
}

@media(max-width:660px){
    .tagScrollWrapper{
        width: 100%;
    }
}


@media (max-width: 576px) {
    .tag {
        padding: 0.25rem 0.65rem;
    }
    .blogSection {
        grid-template-columns: 1fr;
    }
    /* .sliderContainer :global .slick-slide {
        margin: 0 10px;
    } */
    .blogs {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .tagsContainer {
        width: 100% !important;
        flex-direction: column;
     }
     .trendingBlogCard{
        height: 24rem;
        width: 20rem;
    }
}

@media (max-width:500px){
    .trendingBlogCard{
        height: 24rem;
        width: 18rem;
    }

    .search {
        width: 23rem;
    }
}

@media (max-width:460px) {
    .banner {
        margin-top: 4rem;
        margin-bottom: 2.875rem;
    }

}

@media (max-width:400px){
    .trendingBlogCard{
        height: 24rem;
        width: 16rem;
    }

    .nav {
        padding: 1.25rem 1rem;
    }

    .banner {
        margin-top: 5.25rem;
    }

    .RightArrow{
        left:39%
    }
    
    .leftArrow{
        right:37%
    }
}

@media (max-width:380px){
    .search {
        width: 19rem;
    }
}

@media (max-width:354px){
    .trendingBlogCard{
        height: 24rem;
        width: 14rem;
    }

    .RightArrow{
        left:37%
    }
    
    .leftArrow{
        right:34%
    }
}

@media screen and (max-width: 1048px) {
    .tagsContainer {
       width: 60%;
       flex-direction: column;
    }

    .nav {
        flex-direction: column;
        gap: 1rem;
    }
    
}