.mainContainer {
    width: 90%;
    text-align: center;
    margin-top: 5rem;
  }
  
  .mainContainer h2{
    font-family: Butler;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
    color: var(--app-dark);
  }

  .carouselContainer {
    position: relative;
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  
  .carousel {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); */
    grid-template-columns: repeat(4,1fr);
    gap: 10px;
    width: 100%;
    transition: transform 0.5s ease; /* Smooth transition for the carousel */
  }
  
  .arrow {
   
  }
  
  /* .arrow.prev {
    left: -3.75rem;
  } */
  
  /* .arrow.next {
    right: -1rem;
  } */
  
  .disable-hover {
    pointer-events: none; /* Disables all pointer events for the cards */
  }
  .arrow:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Prevent flickering near arrows by stopping pointer events from passing through */
  .arrow.prev, .arrow.next {
    pointer-events: auto;
    position: absolute;
  }

  .arrow.prev {
    left: -4%;
  }

  .arrow.next {
    right: 3%;
  }
  
  @media (max-width: 1420px) {
    .arrow.next {
      right: 1%;
    }
  }

  @media (max-width: 1280px) {
    .arrow.next {
      right: -1%;
    }
  }

  @media (max-width: 1200px) {
    .carousel {
      grid-template-columns: repeat(3, 1fr);
    }

    .carouselContainer {
      width: 95%;
    }
    
  }
  
  @media (max-width: 1024px) {
    .carouselContainer {
      width: 100%;
      left: -3.5rem;
    }
  }

  @media (max-width: 1024px) {
    .carousel {
      grid-template-columns: repeat(2, 1fr);
    }

    .arrow.prev {
      left: 0%;
    }
  }
  
  @media (max-width: 768px) {
    .mainContainer {
      margin-top: 2.5rem;
    }
    .carousel {
      grid-template-columns: repeat(2, 1fr);
    }

    .carouselContainer {
      left: 0;
    }
  }

  @media (max-width: 668px) {
    .carousel {
      width: 95%;
    }
  }
  @media (max-width:576px){
    .carouselContainer{
      width: calc(100%);
    }

    .arrow {
      position: absolute;
      top: 100%;
    }

    .arrow.prev {
      left: 42%;
    }

    .arrow.next {
      right: 42%;
    }


  }

  @media (max-width:400px){
    .arrow.prev {
      left: 40%;
    }

    .arrow.next {
      right: 40%;
    }


  }