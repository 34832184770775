.tabs{
    display: flex;
    align-items: center;
    gap: 1.5rem;
    height: 4rem;
}

.tab,.tabActive{
    padding: 0.75rem 1rem;
    border: 1px solid #D0D4D7;
    border-radius: 100px;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;
    height: inherit;
    overflow: hidden;
    display: flex;
    align-items: center;
    color: #25292C;
}

.tabActive{
    background: #6D8A91;
    color: var(--app-white);
}

.detailSection{
    display: flex;
    align-items: flex-start;
    gap: 3rem;
    margin-top:2rem;
}

.image{
    width: 14.675rem;
    height:17.5rem;
    border-radius: 12px;
}

.image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 12px;
}

.details{
    width:100%;
    max-width: 30.25rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.details h2{
    font-family: Butler;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: left;
    color: var(--app-dark);
}

.details p{
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.26px;
    text-align: left;
    color: #64615D;
}

.featureSection{
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.features{
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.features .image{
    width: 2.25rem;
    height: 2.25rem;
    background: var(--app-light-grey);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.features .image img{
    width: 80%;
    height: 80%;
    object-fit: contain;
}

.description h2{
    font-family: Butler;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;
    color: var(--app-dark);
}

.description p{
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.001em;
    text-align: left;
    color: #6C7275;
}


@media (max-width:576px){
    .detailSection{
        flex-direction: column;
    }

    .container{
        width: 65vw !important;
    }
}
