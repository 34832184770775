.mainContainer {}

.nav {
    display: flex;
    padding: 1.5rem 2rem;
    justify-content: space-around;
    gap: 3rem;
    background: var(--app-bianca);
}

.headerText {
    display: flex;
}

.headerText h2,
.trending h2,
.searchResult h2 {
    font-family: Butler;
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
}



.searchResult h2>span {
    margin-left: 0.75rem;
}

.withinContainer{
    position: relative;
}

.searchCount {
    color: #5D6568;
    font-family: Fraunces;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
    margin-top: 1rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #D0D4D7;
}

.headerText h2 {
    color: black;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0.5rem 1.5rem 0 0;
}

.headerText p {
    font-family: Fraunces;
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0;
    padding: 10px 0 10px 20px;
    color: var(--app-white);
}

.SearchComponent {
    display: flex;
    justify-content: space-evenly;
    gap: 1.25rem;
    flex: 1;
}

.sortBtn {
    background: var(--app-white);
    padding: 0 1.125rem;
    border: 1px solid var(--app-grey);
    border-radius: 6.25rem;
    display: flex;
    align-items: center;
}

.sortBtn select {
    color: var(--app-claret);
    font-family: Fraunces;
    font-size: 0.875rem;
    font-weight: 400;
    border: none;
    padding: 0.625rem 1.875rem 0.625rem 1rem;
    background-color: var(--app-white);
}

.sortBtn select:focus {
    outline: none;
}

.searchWithin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--radi-mlg, 8px);
    border: 1px solid #D0D4D7;
    background: #FFF;
    padding: 0.75rem 1.5rem;
    margin: 1rem 0 2.5rem;
}

.searchWithin input {
    border: none;
}

.searchIconWithin {
    color: var(--app-primary);
}

.mainSection {
    width: 100vw;
    display: flex;
}

.content {
    width: 100%;
    margin: 2.5rem;
}

.carousel {
    width: 92%;
}

.cardGrid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.trending {
    margin-top: -5rem;
    width: 90%;
}

.search {
    margin: 3rem 0;
    width: 90%;
}

.bestSeller {
    width: 90%;
    margin: 4rem 0;
}

.bestAuthor {
    background: var(--app-light-grey);
    width: 85%;
    border-radius: 0.5rem;
}

.paginationControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-top: 3rem;
    font-family: Arial, sans-serif;
    width: 95%;
}

.paginationControls button {
    color: #110D06;
    font-family: Fraunces;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.014px;
}

.paginatonBtn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.paginationControls button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.paginationControls span {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.paginationControls span a,
.paginationControls span .pageNumber {
    text-decoration: none;
    color: #333;
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.paginationControls span .pageNumber.active {
    background-color: #971E42; /* Highlighted color */
    color: #fff;
    font-weight: bold;
}

.paginationControls span a:hover {
    background-color: #ddd;
}

.paginationControls .dots {
    display: inline-block;
    padding: 0.5rem 0.75rem;
    color: #333;
    font-size: 1rem;
}

.searchHeading {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: fit-content;
    cursor: pointer;
}

.backBtn {
    font-size: 1.875rem;
}

.dropdown {
    position: absolute;
    top: 3rem;
    width: 100%;
    max-width: 100%;
    background-color: var(--app-white);
    border: 1px solid var(--app-light-grey);
    border-radius:1.8rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    max-height: 16rem; /* Limit height and make it scrollable if too long */
    overflow-y: auto;
}
  
.dropdownItem {
    padding: 0.75rem 1rem;
    cursor: pointer;
    color: var(--app-grey);
    font-family: Fraunces;
    font-size: 1rem;
    transition: background-color 0.2s ease;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.dropdownItem:hover {
    background: var(--app-light-grey);
}
  

/* Responsive styles */
@media (max-width: 1200px) {
    .searchHeading {
        margin-top: 1rem;
    }

    .cardGrid {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.25rem;
    }

    .searchResult .cardGrid {
        grid-template-columns: repeat(3, 1fr);
    }

    .trending,
    .bestSeller,
    .bestAuthor {
        margin: 0 1rem;
        padding: 1.25rem;
    }

    .bestAuthor {
        width: 90%;
    }
}

@media (max-width:1024px) {
    .nav {
        flex-direction: column;
        padding: 10px 0;
        text-align: center;
        gap: 1rem;
    }

    .headerText {
        flex-direction: column;
        align-items: center;
    }

    .headerText h2 {
        border: none;
        padding: 0;
        margin-bottom: 0.625rem;
    }

    .headerText p {
        padding: 0;
        margin-top: 5px;
    }

    .SearchComponent {
        flex-direction: column;
        gap: 1rem;
        align-items: center;
    }

    .sortBtn {
        justify-content: center;
    }

    .bestSeller{
        margin: 0rem;
        padding: 1.25rem 0rem;
        width: 90%;
    }

}

@media (max-width: 768px) {
    .nav {
        flex-direction: column;
        padding: 10px 0;
        text-align: center;
        gap: 1rem;
    }

    .headerText {
        flex-direction: column;
        align-items: center;
    }

    .headerText h2 {
        border: none;
        padding: 0;
        margin-bottom: 0.625rem;
    }

    .headerText p {
        padding: 0;
        margin-top: 5px;
    }

    .SearchComponent {
        flex-direction: column;
        gap: 1rem;
        align-items: center;
    }

    .sortBtn {
        justify-content: center;
        width: 8rem !important;
    }

    .mainSection {
        flex-direction: column;
    }

    .searchWithin{
        width: 85%;
    }

    .trending,
    .bestSeller,
    .bestAuthor {
        margin: 0 0.5rem;
        padding: 1.25rem;
    }

    .bestAuthor {
        margin-top: 50px;
    }

    .searchResult .cardGrid {
        grid-template-columns: repeat(2, 1fr);
        width: 85%;
        gap: 1.5rem;
    }

    .searchResult h2 {
        font-size: 1.5rem;
    }

    .dropdown{
        width: 85%;
    }

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
    .paginationControls {
        width: 90%;
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items horizontally */
        gap: 1rem; /* Add some space between lines */
    }

    .paginationControls span {
        display: flex; /* Keep numbers in a row */
        justify-content: center; /* Center the page numbers */
        gap: 0.5rem; /* Space between numbers */
        margin: 0; /* Remove margin to prevent overflow */
        flex-wrap: nowrap; /* Prevent wrapping of selected option */
    }

    .paginationControls .paginatonBtn {
        justify-content: center; /* Center the buttons */
    }

    .paginatonBtn{
        display: none;
    }

    .paginationControls button {
        width: auto; /* Ensure buttons fit their content */
        margin: 0; /* Remove margin for better alignment */
    }

    .paginationControls span a,
    .paginationControls span .pageNumber {
        padding: 0.25rem 0.5rem; /* Adjust padding for smaller screens */
    }

    .paginationControls .dots {
        padding: 0.25rem 0.5rem; /* Adjust padding for dots */
    }
}

}

@media (max-width: 576px) {
    .nav {
        padding: 5px 0;
        gap: 1rem;
    }

    .headerText h2 {
        font-size: 1.5rem;
    }

    .headerText p {
        font-size: 0.75rem;
    }

    .SearchComponent {
        gap: 0.5rem;
    }

    .sortBtn {
        padding: 0 0.5rem;
    }

    .sortBtn select {
        padding: 0;
    }

    .trending h2 {
        font-size: 1.5rem;
    }

    .cardGrid {
        gap: 10px;
    }

    .trending,
    .bestSeller,
    .bestAuthor {
        margin: 0;
        padding: 0.625rem;
    }

    .bestAuthor {
        width: 90%;
        margin: 1.25rem 0 0;
    }

    .searchResult .cardGrid {
        grid-template-columns: 1fr 1fr;
    }

    .searchResult h2 {
        font-size: 1.25rem;
    }

    .searchHeading {
        margin-top: 1.5rem;
    }
}

/* Additional adjustments for smaller screens */
@media (max-width: 768px) {
    .nav {
        flex-direction: column;
        padding: 1rem 1.25rem;
        gap: 0rem;
        align-items: center;
    }

    .headerText {
        flex-direction: column;
        text-align: center;
    }

    .SearchComponent {
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        align-items: center;
    }

    .sortBtn {
        width: 100%;
        margin-top: 0.5rem;
        border-radius: 1.5rem;
    }

    .sortBtn select {
        width: 100%;
        padding: 0.5rem 1rem;
        font-size: 0.675rem;
    }
}

@media (max-width: 576px) {
    .nav {
        padding: 0.75rem 1rem;
        gap: 0rem;
    }

    .headerText h2 {
        font-size: 1.25rem;
    }

    .SearchComponent {
        width: 100%;
        gap: 0.75rem;
        align-items: flex-end;
    }

    .sortBtn {
        padding: 0 0.75rem;
        border-radius: 1.2rem;
        margin-right: 20px;
    }

    .sortBtn select {
        font-size: 12px;
        padding: 7px 12px;
    }

    .searchResult .cardGrid {
        grid-template-columns: repeat(2, 1fr);
        width: 90%;
        gap: 1rem;
    }

    .content {
        width: 100%;
        margin: 1.5rem;
    }

    .bestSeller {
        margin-top: 1.5rem;
    }
    
}


@media (max-width:368px) {
    .content {
        width: 100%;
        margin: 2.5rem 0.875rem;
    }

    .trending,
    .bestSeller,
    .bestAuthor {
        margin: 0;
        padding: 0;
    }

    .bestSeller {
        margin-top: 3rem;
    }

    .searchResult .cardGrid {
        grid-template-columns: repeat(2, 1fr);
        width: 90%;
        gap: 0.375rem;
    }
    
    .searchWithin{
        width: 85%;
    }
    .dropdown{
        width: 75%;
    }
}
