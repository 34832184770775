.breadCrumbs {
    color: var(--app-dark);
}

.mainContainer {
    padding: 2rem 2.5rem;
}

.header {
    margin-top: 2.5rem;
}

.header h2 {
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
    color: var(--app-dark);
}

.header p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--app-dark);
}

.cartSection {
    margin-top: 2rem;
    display: flex;
    gap: 2rem;
    flex-direction: row; /* Default direction */
}

.cart {
    flex: 1;
}

.summary {
    flex: 0.3;
}

.recommendations {
    margin:3rem auto 0 ;
    width: 100%;
}

/* Media Queries */
@media (max-width: 1200px) {
    .header h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .header p {
        font-size: 15px;
        line-height: 18px;
    }
}

@media (max-width: 1024px) {
    .mainContainer {
        padding: 1.5rem 1.5rem;
    }

    .cartSection {
        flex-direction: column;
        gap: 0;
    }

    .cart {
        flex: none;
        margin-bottom: 2rem;
    }

    .summary {
        flex: none;
    }
}

@media (max-width: 768px) {
    .header h2 {
        font-size: 24px;
        line-height: 32px;
    }

    .header p {
        font-size: 14px;
        line-height: 14px;
    }

    .recommendations {
        margin-top: 3rem;
    }
}

@media (max-width: 480px) {
    .mainContainer {
        padding: 1rem;
    }

    .header h2 {
        font-size: 20px;
        line-height: 28px;
    }

    .header p {
        font-size: 14px;
        line-height: 16px;
    }
}
