.mainContainer {
    display: flex;
    gap: 2.5rem;
    flex-direction: column;
    justify-content: space-between;
    height: 70dvh;
    overflow: auto;
  }
  
  .headerSection {
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #D0D4D7;
    margin-bottom: 2rem;
  }
  
  .headerSection h2,.headerSectionBack h2{
    font-family: Butler;
    font-size: 30px;
    font-weight: 700;
    line-height: 34px;
    text-align: left;
    color: var(--app-dark);
    margin-bottom: 4px;
  }

  .headerSectionBack {
    display: flex;
    align-items: center; /* Align items vertically in the center */
    position: relative; /* For absolute positioning of FontAwesomeIcon */
    margin-bottom: 0.75rem;
  }
  
  .headerSectionBack h2 {
    flex: 1; /* Takes up available space */
    text-align: center; /* Centers the text horizontally */
    margin-bottom: 0;
  }
  
  .headerSectionBack .iconContainer {
    position: absolute;
    left: 0; /* Position icon at the left */
  }
  
  .headerSection p {
    color: #666666;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  
  .bookContainer {
    height: 100%;
    height: 814px;
    width: 100%;
    padding: 24px;
    background-color: #25292C;
  }
  
  .details,.detailsSmall {
    background: var(--app-light-grey);
    padding: 2rem 1.5rem;
    flex-grow: 1;
  }

  .detailsSmall{
    background: var(--app-white);
  }
  
  .customizationBody {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    flex-grow: 1;
    
  }

  .customizationBodySmall{
    background:var(--app-white);
  }
  
  .sectionController {
    position: fixed;
    bottom: 0;
    width: 90%;
    max-width: 520px; /* Maximum width to ensure it doesn’t stretch too much on large screens */
    background: var(--app-white);
    border-radius: 8px;
    padding: 2rem; /* Adjusted padding for better spacing */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .OptionCard{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;
  }

  .spineOptions{
    background: var(--app-white);
  }

  .spineSections{
    display: flex;
    gap: 0.5rem;
    margin-bottom: 12px;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .spineSections::-webkit-scrollbar{
    display: none;
  }

  .activeSection,.sections{
    border: 1px solid #D0D4D7;
    padding: 8px 16px;
    border-radius: 8px;
    background: var(--app-white);
    text-wrap: nowrap;
  }

  .sectionContainer p{
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.26px;
    text-align: left;
    color: var(--app-dark);
    margin-bottom: 1rem;
  }


  .activeSection{
    background:#5F6D7E;
    color: var(--app-white);
  }

  .tabsSection{
    display: flex;
    gap: 0.5rem;
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    margin-bottom: 1.5rem;
  }

  .tabsSection::-webkit-scrollbar{
    display: none;
  }

  .tab,.tabActive{
    padding: 8px 18px;
    background: var(--app-white);
    border: 1px solid #D0D4D7;
    text-wrap: nowrap;
    border-radius: 10px;
  }

  .tab p,.tabActive p{
    font-family: Fraunces;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.001em;
    text-align: left;
    color: var(--app-dark);
  }

  .tabActive p{
    color:var(--app-white)
  }

  .tabActive{
    background: var(--app-grey);
  }

  .inVisible {
    position: fixed;
    top: 0;
    opacity: 0;
    pointer-events: none;
  }

  @media (min-width:1024px) and (max-height:800px) {
    .mainContainer {
      height: 60dvh;
      overflow: auto;
    }
  }
  

  @media(max-width:1024px){

    .mainContainer {
      height: fit-content;
    }
    .sectionController{
      position: static;
      margin-top: 1.5rem;
      width: calc(100% + 2 * 24px);
      margin-left: -24px; /* Shift left to cover padding */
      margin-right: -24px; /* Shift right to cover padding */
      padding:12px;
      box-sizing: border-box; /* Include padding and border in width */
      max-width: 100dvw;
    }
  
    .sectionController button{
      font-size: 12px;
      padding: 0.5rem;
    }

    .details{
      background: var(--app-white);
      padding:  0;
    }
  }

  @media (max-width: 768px) {
    .headerSection{
      margin-bottom: 0.75rem;
    }
    .headerSection h2,.headerSectionBack h2 {
      font-size: 1.5rem; /* Adjusted for smaller screens */
    }
  }

  @media (max-width: 480px) {
    .headerSection h2,.headerSectionBack h2 {
      font-size: 1.25rem; /* Further adjustment for very small screens */
    }
  }