.primaryTextArea {
    background-color: #F7F9FC;
    width: 100%;
    resize: none;
    /* background-image: repeating-linear-gradient(0deg, #9ebce7, #9ebce7 18px, transparent 18px, transparent 29px, #9ebce7 29px), repeating-linear-gradient(90deg, #9ebce7, #9ebce7 18px, transparent 18px, transparent 29px, #9ebce7 29px), repeating-linear-gradient(180deg, #9ebce7, #9ebce7 18px, transparent 18px, transparent 29px, #9ebce7 29px), repeating-linear-gradient(270deg, #9ebce7, #9ebce7 18px, transparent 18px, transparent 29px, #9ebce7 29px); */
    background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    border: none;
    padding: 0.875rem;
    max-width: 100%;
    font-family: inherit;
}

.primaryTextArea:focus-visible {
    outline: none;
}