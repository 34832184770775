.container {
    border-radius: 0.75rem;
    margin:3rem var(--container-margin) 0;
    background: #EFF1D9;
    padding: 3.375rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerSection {
    width: 100%;
    max-width: 562px;
    text-align: center;
    gap: 12px;
}

.header h2 {
    color: #110D06;
    text-align: center;
    font-family: Butler;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 55px; /* 152.778% */
}

.description p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}

.corouselSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    margin-top: 2.25rem;
}

.carousel {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    position: relative;
    margin-bottom: 1rem;
}

.navButton {
    background: #25292C;
    border: none;
    color: #92E3A9;
    font-size: 0.75rem;
    cursor: pointer;
    margin: 0 1rem;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    color: #fff;
}

.reviewCards {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    overflow: auto;
    transition: transform 0.5s ease-in-out;
    gap: 0.5rem;
}

.card {
    transition: transform 0.5s ease, opacity 0.5s ease;
    transform: translateY(0);
    flex: 1;
}

.middleCard {
    transform: translateY(-20px); /* Lift the middle card up */
    opacity: 1;
    flex: 1;
    padding-top: 0.5rem;
}

.navButtons {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

}

@media (max-width: 768px) {
    .carousel {
        flex-direction: column;
    }

    .card, .middleCard {
        margin-bottom: 1rem;
    }

    .container {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        padding: 1rem;
        margin-top: 1rem;
    }

    .header h2 {
        font-size: 2rem;
    }

    .navButton {
        font-size: 0.5rem;
        width: 32px;
        height: 32px;
        transform: translateY(-2.2rem);
    }

    .reviewCards {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 372px){
    .header h2{
        font-size: 1.5rem;
    }
}