.headerSection {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #d0d4d7;
}

.headerSection h2 {
  color: var(--app-dark);
  font-family: Butler;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 250% */
  letter-spacing: -0.24px;
  margin: 0;
}

.filters {
  display: flex;
  gap: 1rem;
  height: 3rem;
}

.filters select {
  border-radius: 12px;
  border: 1px solid #d1d9e2;
  padding: 1rem 12px;
  cursor: pointer;
}

.filters select:focus {
  outline: none;
}

.searchBar {
  display: flex;
  border-radius: 10px;
  border: 1px solid #d1d9e2;
  align-items: center;
  padding: 12px 16px;
}

.tabs {
  display: flex;
  margin-top: 1.5rem;
  gap: 1rem;
}

.tab {
  padding: 0.625rem 1rem;
  border: 1px;
  border-radius: var(--radi-lg, 12px);
  border: 1px solid #d0d4d7;
  background: #fff;

  color: #25292c;
  text-align: center;
  font-family: Fraunces;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  cursor: pointer;
}

.tabs .active {
  background: #6d8a91;
  color: #fff;
  text-align: center;
  font-family: Fraunces;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.orders {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1.5rem;
}

.text {
  font-family: Fraunces;
  font-size: 1.25rem; /* Larger font size for heading */
  margin:1rem auto;
  text-align: center;
  color: var(--app-grey); /* Darker color for the heading */
}


@media screen and (max-width: 1024px) {
  .tab {
    min-width: 10rem;
  }

  .tabs .active,
  .tab {
    font-size: 0.875rem;
    font-weight: 400;
  }
  
  .tabs {
    width: 55dvw;
    gap: 0.25rem;
    overflow: auto;
    padding-bottom: 0.25rem;
  }

  ::-webkit-scrollbar {
    width: 0.125rem;
    height: 0.125rem;
  }

}

@media screen and (max-width: 820px) {
  .headerSection {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .headerSection h2 {
    font-size: 1.375rem;
  }

  .searchBar {
    width: 11rem;
    font-size: 0.875rem;
  }

  .tabs .active,
  .tab {
    font-size: 0.75rem;
    font-weight: 400;
  }

  .tab {
    min-width: 10rem;
  }

  .tabs {
    width: 50dvw;
    gap: 0.25rem;
    overflow: auto;
    padding-bottom: 0.25rem;
  }

  /* Scrollbar styling */
  ::-webkit-scrollbar {
    width: 0.125rem;
    height: 0.125rem;
  }
}

@media screen and (max-width: 600px) {
  .headerSection {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .headerSection h2 {
    font-size: 1.375rem;
  }

  .searchBar {
    width: 11rem;
    font-size: 0.875rem;
  }

  .tabs .active,
  .tab {
    font-size: 0.75rem;
    font-weight: 400;
  }

  .tab {
    min-width: 10rem;
  }

  .tabs {
    width: 90dvw;
    gap: 0.25rem;
    overflow: auto;
    padding-bottom: 0.25rem;
  }

  /* Scrollbar styling */
  ::-webkit-scrollbar {
    width: 0.125rem;
    height: 0.125rem;
  }
}
