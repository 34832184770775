.leftArrow,.RightArrow{
    border: 1px solid var(--app-dark);
    border-radius: 50%;
    padding: 12px;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
