.closedContainer {
    width: 100%;
    background: #ffffff;
    border: 1px solid var(--app-silver);
    border-radius: 14px;
    padding: 1rem 2rem;
    margin-bottom: 10px; /* Add margin to separate cards */
    transition: max-height 0.6s ease, padding 0.6s ease;
}

.openContainer {
    width: 100%;
    background: #ffffff;
    border: 1px solid var(--app-silver);
    border-radius: 14px;
    padding: 1rem 2rem;
    margin-bottom: 10px; /* Add margin to separate cards */
    transition: max-height 0.6s ease, padding 0.6s ease;
    overflow: hidden; /* Hide overflowing content */
}

.details {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center; /* Center vertically */
    font-family: Butler, sans-serif;
    font-size: 1.25rem; /* Corrected font size */
}

.details h2{
    font-family: Butler;
    font-size: 24px;
    font-weight: 600;
    text-align: left;

}

.toggleImage {
    cursor: pointer; /* Change cursor to pointer on hover */
}

.toggleDown, .toggleRight {
    height: 30px;
    width: 30px;
    object-fit: fill;
}

.toggleImage{
    padding: 10px;
}
.toggleDown {
    background-color: #ffffff;
    padding: 5px;
    border-radius: 50%;
    border: 2px solid #000000;
    transform: rotate(90deg);
}

.toggleRight {
    background-color: #ffffff;
    padding: 5px;
    border-radius: 50%;
    border: 2px solid #000000;
}

.text {
    margin-top: 10px; /* Add margin to separate from header */
    transition: opacity 0.6s ease;
}

.text p {
    margin: 0; /* Reset margin for paragraphs */
    padding: 0;
    font-family: Fraunces;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #64615D;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .details {
        align-items: center;
    }

    .toggleDown, .toggleRight {
        height: 24px;
        width: 24px;
    }

    .text {
        font-size: 12px;
    }

    .details h2 {
        font-size: 1.25rem;
    }
}

@media (max-width:576px) {
    .details h2 {
        font-size: 1rem;
    }

    .text p {
        font-size: 0.75rem;
    }
}

@media (max-width: 480px) {
    .closedContainer, .openContainer {
        padding: 10px;
    }
    .toggleDown, .toggleRight {
        height: 20px;
        width: 20px;
    }

    .text {
        font-size: 10px;
    }
}
