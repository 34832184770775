.timeline {
    display: flex;
    align-items: center;
    gap: 0;
    width: 100%;
  }
  
  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .timelineItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    min-width: 8.5rem;
  }

  .itemDetail {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
  }
  
  .icon {
    font-size: 24px;
    color: #D9D9D9;
  }
  
  .circle {
    width: 1.375rem;
    height: 1.375rem;
    border: 2px solid #E3B231;
    border-radius: 50%;
    background-color: white;
    margin: 8px 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .circle.completed {
    background-color: var(--primary-color, #f5a623);
    border-color: var(--primary-color, #f5a623);
  }

  .circle.circleOrderComplete {
    background-color: #3CD139;
    border-color: #3CD139;
  }

  .tickIcon {
    color: var(--app-white);
    font-size: 0.75rem;
  }
  
  .label {
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color:#D9D9D9;
  }
  
  .step.active .icon,
  .step.active .label {
    color: var(--app-dark);
  }
  
  .line {
    height: 0.5rem;
    width: 100%;
    background-color: #F2F4F8;
    position: absolute;
    top: 14%;
    left: 50%;
    z-index: -1;
  }
  
  .lineCompleted {
    background-color: #f5a623;
  }

  .orderLineDelivered {
    background-color: #3CD139;
  }

  
  @media (max-width : 1200px) {
    .timelineItem {
        min-width: 7rem
    }

    .icon {
        font-size: 1.125rem;
    }

    .label {
        font-size: 0.75rem;
    }
  }

  @media (max-width : 680px) {
    .timelineItem {
        min-width: 5.5rem
    }

    .icon {
        font-size: 1rem;
    }

    .label {
        font-size: 0.675rem;
    }
  }

  @media (max-width : 530px) {
    .timeline {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    .timelineItem {
        width:14rem;
        flex-direction: row;
    }

    .line {
        flex-direction: column;
        width: 0.25rem;
        height: 2.5rem;
        left: 4%;
        top: 30%;
    }

    .itemDetail {
        flex-direction: row;
    }

    .icon {
        font-size: 1.125rem;
    }

    .label {
        font-size: 0.675rem;
    }
  }
  