.mainContainer{
    border: 1px solid #E4E7E9;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: 13px 16px;
}

.details{
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.details label{
    margin: 0;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #141718;
    cursor: pointer;
}

.price p{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: right;
    color: #141718;
    padding-left: 0.5rem;
}

@media (max-width:360px) {
    .mainContainer{
        border: 1px solid #E4E7E9;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        padding: 13px 8px;
    }

    .details label{
        margin: 0;
        font-family: Fraunces;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
        color: #141718;
        cursor: pointer;
    }

    .price p{
        font-family: Fraunces;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        text-align: right;
        color: #141718;
        padding-left: 0.5rem;
    }
}