.join{
    margin: 4rem var(--container-margin);

}

.join h2 {
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    margin-bottom: 0.5rem;
}

.join p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.joinDesc {
   display: flex;
   justify-content: flex-end;
}

.contactUs{
    margin: 2rem var(--container-margin);
}
