.blogsSection {
    margin: 5rem auto 0;
    padding: 2rem;
    max-width: 90%;
    text-align: center;
  }
  
  .headerSection {
    width: 100%;
    max-width: 41.75rem;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  
  .header {
    font-family: Butler;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    
    margin-bottom: 1rem;
  }
  
  .description {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    
    color: #666;
    margin-bottom: 2rem;
  }
  
  .blogCards {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 3.5rem;
  }

  .blogCards .blogCard{
    cursor: pointer;
  }

  .blogCard{
    width: 20rem;
    height: 26rem;
  }
  
  .exploreButton {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 0.75rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .exploreButton:hover {
    background-color: #218838;
  }

  .btnContainer{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .moreBtn{
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    .blogsSection {
      margin: 2.5rem auto 0;
      padding: 0rem 2rem;
    }

    .blogCards {
      flex-direction: column;
      align-items: center;    
      margin-bottom: 2.5rem;  
    }

    .blogCard{
      height: 22rem;
    }
    
  }
  