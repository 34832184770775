/* PreviewModal.module.css */

.mainContainer {
    position: fixed; /* Fixes the modal in place relative to the viewport */
    top: 0;
    left: 0;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    background: rgba(0, 0, 0, 0.5); /* Dark semi-transparent background overlay */
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .modalContent {
    position: relative; /* Relative positioning for internal elements */
    width: 70vw; /* 60% of viewport width */
    height: 90vh; /* 60% of viewport height */
    background: var(--app-light-grey); /* Background color of the modal */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    overflow: hidden; /* Ensure content does not overflow */
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: var(--app-white);
    margin: 1rem;
  }
  
  .header h2 {
    margin: 0; /* Remove default margin */
  }
  
  .header .closeButton {
    cursor: pointer; /* Indicate clickable */
  }
  
  .viewer {
    height: calc(100% - 56px); /* Full height minus the header height */
    overflow: hidden; /* Prevent scrollbars */
    background: var(--app-light-grey);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .viewer canvas{
    height: 90% !important;
    margin-top: 1rem;
  }

  .cancelBtn{
    padding: 8px;
    background:var(--app-light-grey);
  }
  
  .info{
    background: #FFF1EF;
    margin: 1rem;
    padding: 16px;
  }
  @media(max-width:768px){
    .modalContent{
      width: 90vw;
      height: 70vh;
    }
  }